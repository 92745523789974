import React, {Component} from "react";
import {getTagContent} from "../XMLTools";
import {ST} from "../../global/SystemText";
export default class ItemsHead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentDOM: props.dom,
            columns: props.columns,
            document: props.document
        };
    }


    render() {
        let {documentDOM, document, columns} = this.state;

        let header;

        switch (document) {
            case "quotation":
                header = {
                    item_number: "titleNumber",
                    item_name_without_model: "titleName",
                    item_amount: "titleAmount",
                    item_country_of_origin: "country_of_origin",
                    item_delivery_date: "delivery_date",
                    item_description: "descriptionTitle",
                    item_discount: "titleDiscount",
                    item_model: "titleModel",
                    item_name_with_model: "titleName",
                    item_order_our: "titleOrderOur",
                    item_picture: "titleNumber",//---
                    item_price: "titlePrice", // qi_price
                    item_priceafterdiscount: "quotation_item_sell_product_discount_text",// qi_price_one
                    item_pricediscount: "titleDiscount",//---!!! qi_final_price asi zle
                    item_price_final: "quotation_report_item_buy_text",// qi_final_price
                    item_price_incl_vat: "titlePriceInclVat",// qi_final_price_vat
                    item_price_total: "titlePriceTotalNotVat",// qi_total_price
                    item_producer: "even",//---
                    item_vat: "title_vat",
                    item_warranty: "warranty",
                    item_weight: "titleWeight"
                };
                break;

            case "orderin":
                header = {
                    item_number: "titleNumber",
                    item_name_without_model: "titleName",
                    item_amount: "titleAmount",
                    item_country_of_origin: "country_of_origin", //---
                    item_delivery_date: "delivery_date",
                    item_description: "description",
                    item_discount: "titleDiscount",
                    item_model: "titleModel",
                    item_name_with_model: "titleName",
                    item_order_our: "titleOrderOur",//---
                    item_picture: "titleNumber",//---
                    item_price: "titlePrice", // qi_price
                    item_priceafterdiscount: "orderin_item_sell_product_discount_text",// qi_price_one
                    item_pricediscount: "titleDiscount",//---!!! qi_final_price asi zle
                    item_price_final: "orderin_report_item_buy_text",// qi_final_price
                    item_price_incl_vat: "titlePriceInclVat",// qi_final_price_vat
                    item_price_total: "titlePriceTotalNotVat",// qi_total_price
                    item_producer: "producer",
                    item_vat: "titleVat",
                    item_warranty: "warranty",//---
                    item_weight: "titleWeight",
                    item_rest_deliver: "titleRestDeliver",
                };
                break;

            case "invoice":
                header = {
                    item_number: "titleNumber",
                    item_name_without_model: "titleName",
                    item_amount: "titleAmount",
                    item_country_of_origin: "country_of_origin", //---
                    item_delivery_date: "",
                    item_description: "description",
                    item_discount: "titleDiscount",
                    item_model: "titleModel",
                    item_name_with_model: "titleName",
                    item_order_our: "title_product_order_our",//---
                    item_picture: "",//---
                    item_price: "titlePrice", // qi_price
                    item_priceafterdiscount: "invoice_item_sell_product_discount_text",// qi_price_one
                    item_pricediscount: "titleDiscount",//---!!! qi_final_price asi zle
                    item_price_final: "invoice_report_item_buy_text",// qi_final_price
                    item_price_incl_vat: "titlePriceInclVat",// qi_final_price_vat
                    item_price_total: "titlePriceTotalNotVat",// qi_total_price
                    item_producer: "",//---
                    item_vat: "titleVat",
                    item_warranty: "warranty",
                    item_weight: "titleWeight"
                };
                break;

            case "orderout":
                header = {
                    item_number: "titleNumber",
                    item_name_without_model: "titleName",
                    item_amount: "titleAmount",
                    item_country_of_origin: "country_of_origin", //---
                    item_delivery_date: "delivery_date",
                    item_description: "description",
                    item_discount: "titleDiscount",
                    item_model: "titleModel",
                    item_name_with_model: "titleName",
                    item_order_our: "titleOrderOur",//---
                    item_picture: "titleNumber",//---
                    item_price: "titlePrice", // qi_price
                    item_priceafterdiscount: "orderout_item_sell_product_discount_text",// qi_price_one
                    item_pricediscount: "titleDiscount",//---!!! qi_final_price asi zle
                    item_price_final: "orderout_report_item_buy_text",// qi_final_price
                    item_price_incl_vat: "titlePriceInclVat",// qi_final_price_vat
                    item_price_total: "titlePriceTotalNotVat",// qi_total_price
                    item_producer: "producer",
                    item_vat: "titleVat",
                    item_warranty: "warranty",//---
                    item_weight: "titleWeight"
                };
                break;

            case "warehouse":
                header = {
                    item_number: "titleNumber",
                    item_name_without_model: "titleName",
                    item_amount: "titleAmount",
                    item_country_of_origin: "country_of_origin", //---
                    item_delivery_date: "delivery_date",
                    item_description: "description",
                    item_discount: "titleDiscount",
                    item_model: "titleModel",
                    item_name_with_model: "titleName",
                    item_order_our: "titleOrderOur",//---
                    item_picture: "titleNumber",//---
                    item_price: "titlePrice", // qi_price
                    item_priceafterdiscount: "warehouse_item_sell_product_discount_text",// qi_price_one
                    item_pricediscount: "titleDiscount",//---!!! qi_final_price asi zle
                    item_price_final: "warehouse_report_item_buy_text",// qi_final_price
                    item_price_incl_vat: "titlePriceInclVat",// qi_final_price_vat
                    item_price_total: "titlePriceTotalNotVat",// qi_total_price
                    item_producer: "producer",//---
                    item_vat: "titleVat",
                    item_warranty: "warranty",
                    item_weight: "titleWeight"
                };
                break;

            default:
                header = {};
                break;
        }

        columns = columns.filter((ele) => header[ele] !== '');

        return (
            <thead>
            <tr>
                {columns.map((col,index) => {
                    if (col === "item_name_without_model" || col === "item_name_with_model") {
                        return (<th key={"head" + col} style={{minWidth: "200px"}}>{getTagContent(documentDOM,header[col])}</th>);
                    }
                    return (<th className={index>0?"text-center":""} key={"head" + col} style={{maxWidth: "180px", minWidth: "66px"}}>{getTagContent(documentDOM,header[col])}</th>);
                })}
                {document == "invoice" && window.portalSettings.allow_claim?
                    <th key={"head-claim"} style={{minWidth: "50px"}}>{ST.get("claims")}</th>
                :null}
            </tr>
            </thead>
        );
    }
}