import React, {Component} from "react";
import {ST} from "../../global/SystemText";

export default class CustomerHead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.type
        };
    }


    render() {
        let {type} = this.state;
        return (
            <thead>
            <tr>
                <th className={"text-left"} style={{minWidth: "100px"}}>{type === "company" ? ST.get("company_name", "company") : type === "contact" ? ST.get("name", "person") : ""}</th>
                <th className={"text-left"} style={{minWidth: "100px"}}>{type === "company" ? ST.get("ico", "company") : type === "contact" ? ST.get("company_one", "company") : ""}</th>
                <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("street", "person")}</th>
                <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("city_and_zip", "global")}</th>
                <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("country", "global")}</th>
                <th className={"text-left"}></th>
            </tr>
            </thead>
        );
    }
}