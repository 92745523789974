import axios from "axios";

const Color = require('color');

export function getColorBrightness( /* String */ scolor) {
    let color = Color(scolor);
    return Math.sqrt(color.red() * color.red() * 0.241 + color.green() * color.green() * 0.691 + color.blue() * color.blue() * 0.068);
}

export function isTouchDevice() {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
}

export async function initBasket(update=true) {
    try {
        let storedBasket = Session.getItem("basket");
        //get basket from backend
        let contactId= Session.getItem("idContact")
        if(contactId && window.portalSettings.persist_cart){
            let response = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    idContact: contactId,
                    idLanguage: Session.getItem("idLanguage"),
                    action: "GET_PORTAL_CART"
                }
            })
            if(response.data && response.data!==""){
                storedBasket=response.data.replaceAll("&quot;","\"")
            }
        }

        if (storedBasket === null || storedBasket === undefined) {
            window.basket = {
                items: [],
                itemsCount: 0,
                totalWeight: 0,
                totalPrice: 0,
                totalPriceVat: 0,
                vatKoef: 0,
                codeCurrency: Session.getItem("codeCurrency")
            }
        } else {
            try{
                window.basket = JSON.parse(storedBasket);
            }catch (e){
                window.basket = {
                    items: [],
                    itemsCount: 0,
                    totalWeight: 0,
                    totalPrice: 0,
                    totalPriceVat: 0,
                    vatKoef: 0,
                    codeCurrency: Session.getItem("codeCurrency")
                }
            }
        }
        if(update) {
            await updateBasket();
        }
    } catch (e) {
        resetBasket();
    }
}

async function reinitBasket() {
    try {
        let storedBasket = Session.getItem("basket");
        if (storedBasket === null || storedBasket === undefined) {
            window.basket = {items: [], itemsCount: 0, totalWeight: 0, totalPrice: 0, totalPriceVat: 0, vatKoef: 0, codeCurrency: Session.getItem("codeCurrency")}
        } else {
            window.basket = JSON.parse(storedBasket);
        }
    } catch (e) {
        Session.removeItem("basket");
        await updateBasket();
        await initBasket();
    }
    var evt = new CustomEvent('updateBasket');
    window.dispatchEvent(evt);
}

export async function resetBasket() {
    Session.removeItem("basket");

    let contactId= Session.getItem("idContact")
    if(contactId && window.portalSettings.persist_cart){
        await axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: contactId,
            cart: "",
            idLanguage: Session.getItem("idLanguage"),
            action: "UPDATE_PORTAL_CART"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }}).then(async ()=>{
            await initBasket();
        })
    }else{
        await initBasket();
    }

}

export function addToBasketButtonSet(button){
    if(button.tagName!="BUTTON"){
        button=button.parentNode;
    }
    button.style.opacity=0.5
}

export function addToBasketButtonReset(button){
    if(button.tagName!="BUTTON"){
        button=button.parentNode;
    }
    setTimeout(()=>{
        button.style.opacity=1;
    },600)
}

function makeClosestMultiple(number, multiple) {
    var remainder = number % multiple;
    if(remainder>0) {
        return number + (multiple - remainder);
    }else{
        return number
    }

}

export async function addToBasket(amount, id, idType, product, type, popup, e,update= true) {
    if (e !== undefined) {
        addToBasketButtonSet(e.target);
        e.persist()
    }
    await initBasket(false)
    let exist = false;
    if (popup === undefined) {
        popup = true;
    }
    if (popup) {
        popup = window.portalSettings.basket_popup;
    }

    let forcedAmount=false;


    for (let i = 0; i < window.basket.items.length; i++) {
        if (window.basket.items[i].id === id.toString() && window.basket.items[i].idType === idType.toString()) {
            let newAmount = Number(amount);
            newAmount += window.basket.items[i].amount;
            if(product.forcedPackageAmount && product.forcedPackageAmount>0 && ((idType===0 && product.available<=0) || (idType>0 && type.available<=0))){
                newAmount = makeClosestMultiple(newAmount, product.forcedPackageAmount)
                forcedAmount=true
            }
            await changeAmountInBasket(i, newAmount);
            exist = true;
        }
    }

    if (!exist) {
        if(product.forcedPackageAmount && product.forcedPackageAmount>0 && ((idType===0 && product.available<=0) || (idType>0 && type.available<=0))){
            amount = makeClosestMultiple(Number(amount), product.forcedPackageAmount)
            forcedAmount=true
        }
        let item = {};
        if (idType > 0) {
            item = {
                amount: Number(amount),
                id: id.toString(),
                idType: idType.toString(),
                name: product.name + " " + product.model + " - " + type.name + " " + type.model,
                orderOur: type.orderOur === "" ? product.orderOur : type.orderOur,
                basePrice: type.prices.mainPrices.price,
                basePriceFormated: type.prices.mainPrices.formatedPrice,
                basePriceVat: type.prices.mainPrices.priceVat,
                price: type.prices.customerPrices.price,
                priceFormated: type.prices.customerPrices.formatedPrice,
                priceVat: type.prices.customerPrices.priceVat,
                discount: type.prices.customerPrices.discount,
                vatKoef: product.prices.vatKoef,
                weight: Number(type.weight === 0) ? product.weight : type.weight,
                lockAmount:forcedAmount
            };
        } else {
            item = {
                amount: Number(amount),
                id: id.toString(),
                idType: idType.toString(),
                name: product.name + " " + product.model,
                orderOur: product.orderOur,
                basePrice: product.prices.mainPrices.price,
                basePriceFormated: product.prices.mainPrices.formatedPrice,
                basePriceVat: product.prices.mainPrices.priceVat,
                price: product.prices.customerPrices.price,
                priceFormated: product.prices.customerPrices.formatedPrice,
                priceVat: product.prices.customerPrices.priceVat,
                discount: product.prices.customerPrices.discount,
                vatKoef: product.prices.vatKoef,
                weight: product.weight,
                lockAmount:forcedAmount
            };
        }

        window.basket.items.push(item);
        if(update) {
            await updateBasket();
        }


    }
    if (popup) {
        let evt;
        if (idType > 0) {
            evt = new CustomEvent('addToBasket', {detail: {"label": product.name + " - " + type.name, "amountAlert":forcedAmount}});
        } else {
            evt = new CustomEvent('addToBasket', {detail: {"label": product.name, "amountAlert":forcedAmount}});
        }
        window.dispatchEvent(evt);
    }

    if (e !== undefined) {
        addToBasketButtonReset(e.target);
    }
}

export async function removeFromBasket(index) {
    await initBasket(false)
    window.basket.items.splice(index, 1);
    await updateBasket();
}

export async function changeAmountInBasket(index, newAmount) {
    await initBasket(false)
    window.basket.items[index].amount = Number(newAmount);
    await updateBasket();
}

export async function updateBasket() {
    let price = 0;
    let priceVat = 0;
    let count = 0;
    let weight = 0;
    let showDiscountColumns=false;
    for (let i = 0; i < window.basket.items.length; i++) {
        if(Number( window.basket.items[i].discount)>0){
            showDiscountColumns=true;
        }
        count += window.basket.items[i].amount;
        let priceUnit = Number((window.basket.items[i].price * window.basket.items[i].amount).toFixed(2));
        price += priceUnit;
        let priceVatUnit = Number((priceUnit * (window.basket.items[i].vatKoef===0?1:window.basket.items[i].vatKoef)).toFixed(2));
        priceVat += priceVatUnit;
        weight += (Number(window.basket.items[i].weight) * window.basket.items[i].amount);
    }
    window.basket.totalWeight = weight;
    window.basket.totalPrice = price;
    window.basket.totalPriceVat = priceVat;
    window.basket.itemsCount = count;
    window.basket.codeCurrency = Session.getItem("codeCurrency");
    window.basket.showDiscountColumns = showDiscountColumns;
    Session.setItem("basket", JSON.stringify(window.basket));

    //send basket to backend
    let contactId= Session.getItem("idContact")
    if(contactId && window.portalSettings.persist_cart){
        await axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: contactId,
            cart: JSON.stringify(window.basket),
            idLanguage: Session.getItem("idLanguage"),
            action: "UPDATE_PORTAL_CART"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }})
    }

    let evt = new CustomEvent('updateBasket');
    window.dispatchEvent(evt);
}

export function purgeLogin() {
    Session.setItem("logged", "false");
    Session.setItem("idContact", "");
    Session.setItem("idCompany", "");
    Session.setItem("idLanguage", "");
    Session.setItem("idDealer", "");
    Session.setItem("idCurrency", "");
    Session.setItem("priceLevel", "");
    Session.setItem("contactName", "");
    Session.setItem("companyName", "");
    persistStorage();
}

async function getStorage() {
    let time = 1000;
    let waitForData = async () => {
        let value = window.localStorage.getItem('get' + Session.instance + Session.idMandant);
        if ((time -= 100) < 0 || !value) {
            return window.localStorage.getItem(Session.instance + Session.idMandant);
        }
        await timeout(100);
        return await waitForData();
    };
    await timeout(100);
    return await waitForData();
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function persistStorage() {
    let storage = JSON.stringify(window.sessionStorage);
    window.localStorage.setItem("" + Session.instance + Session.idMandant, storage);
}

export function handleError(error, history) {
    // TODO - poslat najeko chybu do firmy
    //console.trace();
    if (error.response) {
        let code = error.response.status;
        switch (code) {
            case 401:
                history.replace(Session.getItem("basename"));
                break;
            case 404:
                history.replace("/404");
                break;
            default:
                //console.log(error);
                break;
        }
    } else {
        //console.log(error);
    }
}

export function canShowToDealer() {
    return !(Session.getItem("dealerLogin") === "true" && Session.getItem("idContact") === "");
}

export async function isAvailable(id, idType) {
    let response = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
        params: {
            idMandant: Session.idMandant,
            id: id,
            idType: idType,
            idLanguage: Session.getItem("idLanguage"),
            action: "GET_PORTAL_PRODUCTWAREHOUSE"
        }
    })

    return response.data.available;
}

export class Session {
    static instance;
    static idMandant;

    static async initStorage(instance, idMandant) {
        this.instance = instance;
        this.idMandant = idMandant;

        window.addEventListener('storage', async (event) => {
            if (event.newValue === null) {
                return;
            }
            if (event.key === 'get' + instance + idMandant) {
                // Some tab asked for the sessionStorage -> send it
                window.localStorage.setItem(instance + idMandant, JSON.stringify(sessionStorage));
            }

            if (event.key === "sessionSet" + instance + idMandant) {
                let {key, value} = JSON.parse(event.newValue);
                sessionStorage.setItem(key, value);
            }

            if (event.key === "sessionRemove" + instance + idMandant) {
                let {key} = JSON.parse(event.newValue);
                sessionStorage.removeItem(key);
            }

            if (event.key === "sessionClear" + instance + idMandant) {
                sessionStorage.clear();
            }
            await reinitBasket();
        });

        window.addEventListener("beforeunload", (event) => {
            persistStorage();
        });

        window.sessionStorage.clear();
        this.notifyOthers("get", instance + idMandant);

        let storage = await getStorage();
        if (storage) {
            let data = JSON.parse(storage);

            for (let key in data) {
                window.sessionStorage.setItem(key, data[key]);
            }

        }
    }

    static setItem(key, value) {
        window.sessionStorage.setItem(key, value);
        let payload = {key, value};
        this.notifyOthers("sessionSet", JSON.stringify(payload));
        persistStorage();
    }

    static removeItem(key) {
        window.sessionStorage.removeItem(key);
        let payload = {key};
        this.notifyOthers("sessionRemove", JSON.stringify(payload));
        persistStorage();
    }

    static clear() {
        window.sessionStorage.clear();

        this.notifyOthers("sessionClear", "clear");
        persistStorage();
    }

    static getItem(key) {
        return window.sessionStorage.getItem(key);
    }

    static notifyOthers(key, value) {
        window.localStorage.setItem("" + key + this.instance + this.idMandant, value);
        window.localStorage.removeItem("" + key + this.instance + this.idMandant);
    }
}
