import React, {Component} from 'react';
import {Button, Row, Col} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Link} from "react-router-dom";
import {ST} from "../global/SystemText";
import ProductWarehouse from "../B2B/ProductWarehouse";

export default class ProductTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 1,
            selectedType: 0
        }
    };

    changeInput = (event) => {
        this.setState({amount: event.target.value});
    };

    selectType(event) {
        this.setState({selectedType: event.target.value});

    }


    render() {
        try {
            let {
                name, id, idParent, orderOur,
                model, price, formatedPrice, priceVat,
                formatedPriceVat, vatKoef, weight,
                catalogue, pictures, isLocked, backorderLock,
                types, available, unit, recPrice,
                formatedRecPrice, showRecPrice, replacements,stock
            } = this.props.data;


            //Vyberac typov
            if(types.length > 0 && types[this.state.selectedType].prices.customerPrices.price==0){
                isLocked=true;
            }

            let typeSelector =
                <select className="type-select" value={this.state.selectedType} onChange={this.selectType.bind(this)}>
                    {types.map((element, index) => {
                        return (<option key={"option" + index} value={index}>{element.name} | {element.orderOur}</option>);
                    })
                    }
                </select>;


            //Nahrady
            let productReplacemnts =
                <div className="panel-body panel-body-accent text-center" style={{background: window.portalSettings.portal_pcolor + "0d", height: "230px"}}>
                    <div style={{height: "110px"}}>
                        <Link to={Session.getItem("basename") + "/product/" + id}>
                            <h6 className="text-semibold no-margin product-name">{name} {model}</h6>
                        </Link>
                        <span>{ST.get("replacements", "product")} :&nbsp;
                            <strong> {replacements.map((element, index) => (
                                <Link key={index} to={Session.getItem("basename") + "/product/" + element.id}>
                                    {index > 0 ? ", " : ""}{element.name} {element.model}
                                </Link>))}
                        </strong>
                    </span>
                    </div>
                    <h3 className="no-margin text-semibold" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("not_selling", "portal")}</h3>
                </div>;

            let priceOnDemand =
                <span>
                <h3 className="no-margin text-semibold" style={{color: window.portalSettings.portal_pcolor}}>{ST.get("on_demand", "global")}</h3>
                <div className="text-size-mini text-muted">&nbsp;</div>
            </span>;

            let prices = types !== undefined && types.length > 0 ?
                <Row>
                    <Col xs={12} sm={12}>
                        <Row>
                            <Col xs={7} sm={7}>
                                {Boolean(window.portalSettings.show_prices) && types[this.state.selectedType].prices.showRecPrice ?
                                    Boolean(window.portalSettings.main_price_excl_vat) ?
                                    <div className="text-size-mini text-muted"><s>{types[this.state.selectedType].prices.recsellPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                        :
                                        <div className="text-size-mini text-muted"><s>{types[this.state.selectedType].prices.recsellPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                    : null}
                                {Boolean(window.portalSettings.show_prices) && types[this.state.selectedType].prices.showMainPrice ?
                                    Boolean(window.portalSettings.main_price_excl_vat) ?
                                    <div className="text-size-mini text-muted"><s>{types[this.state.selectedType].prices.mainPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                        :
                                        <div className="text-size-mini text-muted"><s>{types[this.state.selectedType].prices.mainPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                    : null}
                            </Col>
                        </Row>
                        <Row>
                            {Boolean(window.portalSettings.show_prices) ? (
                                <Col xs={7} sm={7}>
                                    {Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <h3 className="no-margin text-semibold"
                                            style={{color: window.portalSettings.portal_pcolor}}>{types[this.state.selectedType].prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</h3>
                                        :
                                        <h3 className="no-margin text-semibold"
                                            style={{color: window.portalSettings.portal_pcolor}}>{types[this.state.selectedType].prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</h3>
                                    }
                                </Col>
                            ) : <Col xs={7} sm={7}/>}
                            <Col xs={5} sm={5}>
                                {!types[this.state.selectedType].backorderLock &&
                                    <div className="form-group form-group-material">
                                    <div className="input-group">
                                        <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                                        <div className="input-group-btn no-padding-left">
                                            <Button onClick={async (e) => await PT.addToBasket(this.state.amount, idParent, types[this.state.selectedType].id, this.props.data, types[this.state.selectedType],true,e)} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                                        </div>
                                    </div>
                                </div>}
                            </Col>
                        </Row>
                        <Row>
                            {Boolean(window.portalSettings.show_prices) ? (
                                <Col xs={7} sm={7}>
                                    {Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <div
                                            className="text-size-mini text-muted">{ST.get("global_incl_vat", "global")}: {types[this.state.selectedType].prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</div>
                                        :
                                        <div
                                            className="text-size-mini text-muted">{ST.get("global_excl_vat", "global")}: {types[this.state.selectedType].prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</div>
                                    }
                                </Col>
                            ) : <Col xs={7} sm={7}/>}

                        </Row>
                    </Col>
                </Row>
                :
                <Row>
                    <Col xs={12} sm={12}>
                        {Boolean(window.portalSettings.show_prices) ? (
                            <Row>
                                <Col xs={7} sm={7}>
                                    {this.props.data.prices.showRecPrice ?
                                        Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <div className="text-size-mini text-muted"><s>{this.props.data.prices.recsellPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                            :
                                            <div className="text-size-mini text-muted"><s>{this.props.data.prices.recsellPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                        :
                                        null
                                    }
                                    {this.props.data.prices.showMainPrice ?
                                        Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <div className="text-size-mini text-muted"><s>{this.props.data.prices.mainPrices.formatedPrice.replace(/eur/ig, "€")}</s></div>
                                            :
                                            <div className="text-size-mini text-muted"><s>{this.props.data.prices.mainPrices.formatedPriceVat.replace(/eur/ig, "€")}</s></div>
                                        :
                                        null
                                    }
                                </Col>
                            </Row>
                        ) : null }
                        <Row>
                            {Boolean(window.portalSettings.show_prices) ? (
                                <Col xs={7} sm={7}>
                                    {Boolean(window.portalSettings.main_price_excl_vat) ?
                                            <div>
                                                <h3 className="no-margin text-semibold"
                                                    style={{color: window.portalSettings.portal_pcolor}}>{this.props.data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</h3>
                                                <div
                                                    className="text-size-mini text-muted">{ST.get("global_incl_vat", "global")}: {this.props.data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</div>
                                            </div>
                                         :
                                        <div>
                                            <h3 className="no-margin text-semibold"
                                                style={{color: window.portalSettings.portal_pcolor}}>{this.props.data.prices.customerPrices.formatedPriceVat.replace(/eur/ig, "€")}</h3>
                                            <div
                                                className="text-size-mini text-muted">{ST.get("global_excl_vat", "global")}: {this.props.data.prices.customerPrices.formatedPrice.replace(/eur/ig, "€")}</div>
                                        </div>
                                    }
                                </Col>
                            ) : <Col xs={7} sm={7}/> }
                            <Col xs={5} sm={5}>
                                {(!backorderLock) &&
                                <div className="form-group form-group-material">
                                    <div className="input-group">
                                        <input type="number" value={this.state.amount} min={1} onChange={this.changeInput.bind(this)} className="form-control material-input amount-input"/>
                                        <div className="input-group-btn no-padding-left">
                                            <Button onClick={async (e) => await PT.addToBasket(this.state.amount, idParent, 0, this.props.data,null,true,e)} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/></Button>
                                        </div>
                                    </div>
                                </div>}

                            </Col>
                        </Row>
                    </Col>
                </Row>;

            return (
                <Col md={4} xl={3} sm={6}>
                    <div className="panel" style={{"height": "550px"}}>

                        <Link to={Session.getItem("basename") + "/product/" + id}>
                            <div className="panel-body">
                                {Boolean(window.portalSettings.show_prices) && this.props.data.prices.customerPrices.discount > 0 ?
                                    <div className="discount"><span style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>{this.props.data.prices.customerPrices.discount}%</span></div>
                                    : null}
                                <div className="thumb thumb-fixed product-thumb">
                                    <img src={Session.getItem("endpoint") + "/PictureServlet?action=GET_PICTURE_FOR_PRODUCT&id=" + idParent + "&idType=" + (types[this.state.selectedType] !== undefined ? types[this.state.selectedType].id : 0)} className="img-responsive" alt={name}/>
                                </div>
                            </div>
                        </Link>
                        {replacements.length > 0 ?
                            <span>{productReplacemnts}</span>
                            :
                            <div className="panel-body panel-body-accent text-center" style={{background: window.portalSettings.portal_pcolor + "0d", height: "230px"}}>
                                <div style={{height: "110px"}}>
                                    <Link to={Session.getItem("basename") + "/product/" + id}>
                                        <h6 className="text-semibold no-margin product-name">{name} {model}</h6>
                                    </Link>
                                    <ProductWarehouse data={types[this.state.selectedType] !== undefined?types[this.state.selectedType].stock:stock} id={idParent} idType={(types[this.state.selectedType] !== undefined ? types[this.state.selectedType].id : 0)}/>
                                    <ul className="list-inline list-inline-separate mb-10 product-info">
                                        <li>
                                            {types !== undefined && types.length > 0 ? <span>{typeSelector}</span> : <span>{orderOur}</span>} | <Link to={Session.getItem("basename") + "/catalogue/" + catalogue.id}>{catalogue.name}</Link>
                                        </li>
                                    </ul>
                                </div>
                                {isLocked || (types[this.state.selectedType] !== undefined && types[this.state.selectedType].isLocked) ?
                                    <span>{priceOnDemand}</span> : <span>{prices}</span>
                                }
                            </div>
                        }
                    </div>
                </Col>
            )

        } catch (e) {
            console.log(e);
            return null;
        }
    }
}