import React, { Component } from 'react';

export default class QRCodeGuide extends Component {


    render() {
        return (
            <div>
                <div className="mobile-homepage-card" style={{padding: "5px", textAlign: "left"}}>
                    Prosím naskenujte QR kód, ktorý nájdete v záložke môj účet. <br/>
                    Do záložky môj účet sa dostanete z nastavenia alebo v pravom hornom rohu kliknete na svoje meno a po zobrazení menu kliknete na môj účet.
                </div>
            </div>
        );

    }
}