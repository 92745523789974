import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import * as axios from "axios/index";
import Header from "./Header";
import Items from "./Items";
import Sidebar from "./Sidebar";
import {Col, Row} from "react-bootstrap";
import {BubbleLoader} from 'react-css-loaders';
import {getTagContent} from "./XMLTools";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";

export default class Document extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portalType: Number(Session.getItem("portal_type")),
            documentXml: "",
            id: this.props.match.params.id,
            loaded: false,
            document: props.match.params.document,
            hash: props.match.params.hash,
            response: props.match.params.response,
            settings: props.settings === undefined ? window.portalSettings : props.settings,
            paymentResult:"none"
        }

    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.id === newProps.match.params.id) {
            return null;
        } else {
            newState.id = newProps.match.params.id;
            newState.document= newProps.match.params.document;
            newState.response= newProps.match.params.response;
            newState.loaded = false;
            return newState;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loaded) {
            this.getDocument();
        }
    }


    componentDidMount() {
       this.getDocument();
    }

    getDocument(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany") ? Session.getItem("idCompany") : "0",
                idContact: Session.getItem("idContact") ? Session.getItem("idContact") : "0",
                id: this.state.id,
                document: this.props.match.params.document,
                action: "GET_PORTAL_DOCUMENT"
            }
        })
            .then((response) => {
                this.setState({
                    documentXml: response.data,
                    document: this.props.match.params.document,
                    loaded: true
                },()=>this.runAutoFnc());
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    runAutoFnc(){
        if(this.state.document==="invoice" && this.state.hash==="PAYMENT_FAILED"){
            this.setState({
                paymentResult:"failed"
            })
        }
        if(this.state.document==="invoice" && this.state.hash==="PAYMENT_SUCCESS"){
            this.setState({
                paymentResult:"success"
            })
        }
        if(this.state.document==="invoice" && this.state.response==="PAYMENT_FAILED"){
            this.setState({
                paymentResult:"failed"
            })
        }
        if(this.state.document==="invoice" && this.state.response==="PAYMENT_SUCCESS"){
            this.setState({
                paymentResult:"success"
            })
        }
    }

    render() {
        let {portalType, loaded, documentXml, document, settings} = this.state;
        let documentDOM = new DOMParser().parseFromString(documentXml, "text/xml");

            if (loaded) {
                return (
                        <Row>
                            <Col md={9}>

                                    <Header documentXml={documentXml} document={document} settings={settings} isB2C={this.props.isB2C?this.props.isB2C:false}/>

                                    <Items documentXml={documentXml} document={document} columns={settings.columns} settings={settings}/>

                                    <div className="panel border-top-xlg" style={{borderColor: settings.portal_pcolor}}>
                                        <div className="panel-body ">
                                            <Row>
                                                <Col md={9}>
                                                    <div className="mb-10" dangerouslySetInnerHTML={{__html: getTagContent(documentDOM, document + "_aftertext")}}/>

                                                </Col>

                                                <Col md={3} style={{display:"none"}} className="p-20">
                                                    <img src={getTagContent(documentDOM, "stamp_url").replace("http://localhost:8080", "https://"+Session.instance + ".offeris.com")}
                                                         className="img-responsive"/>
                                                </Col>
                                                <Col md={8}>
                                                    {getTagContent(documentDOM, "created_by")} : <b>{getTagContent(documentDOM, document + "_intrauser_name")}</b>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                            </Col>
                            <Col md={3}>

                                <Sidebar id={this.props.match.params.id} history={this.props.history} paymentResult={this.state.paymentResult} document={document} settings={settings} documentXML={documentXml}/>
                            </Col>
                        </Row>
                );
            } else {
                return (<div style={{height: "400px", paddingTop: "80px"}}><BubbleLoader color={settings.portal_pcolor}/></div>)
            }

    }
}
