import React, {Component} from 'react';
import axios from "axios";
import {Button, Col, Row} from "react-bootstrap";
import {BubbleLoader} from "react-css-loaders";
import ProductTile from "../components/ProductTile";
import ProductRow from "../components/ProductRow";
import {Link} from "react-router-dom";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {ST} from "../global/SystemText";

export default class Producer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:props.match.params.id,
            idCatalogue:props.match.params.idCatalogue,
            loaded: false,
            products: [],
            data: {},
            subs: [],
            limit: "",
            sort: "",
            page: 1,
            paginationLimit: 5,
            gridType: ""
        };

    }

    static getDerivedStateFromProps(newProps, newState) {

        if (newState.id === newProps.match.params.id && newState.idCatalogue === newProps.match.params.idCatalogue) {
            return null;
        } else {
            newState.id = newProps.match.params.id;
            newState.idCatalogue = newProps.match.params.idCatalogue;
            newState.loaded = false;
            newState.page = 1;
            return newState;
        }
    }
    componentDidMount() {
        if(!window.portalSettings.producers_allow){
            this.props.history.replace(Session.getItem("basename"));
        }


        let defaultSort = "catalogue_order";
        let defaultPage = 1;
        let defaultLimit = 24;
        let defaultGridType = "grid";
        if (Session.getItem("gridType") !== undefined) {
            defaultGridType = Session.getItem("gridType")
        }
        this.setState({
            limit: defaultLimit,
            sort: defaultSort,
            page: defaultPage,
            gridType: defaultGridType
        });


    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getCatalogue();
        }
    }

    getCatalogue() {

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany:Session.getItem("idCompany"),
                idContact:Session.getItem("idContact"),
                idLanguage:Session.getItem("idLanguage"),
                idCurrency:Session.getItem("idCurrency"),
                idCatalogue:this.state.idCatalogue,
                idProducer:this.state.id,
                priceLevel:Session.getItem("priceLevel"),
                limit: this.state.limit,
                sort: this.state.sort,
                page: this.state.page,
                action: "GET_PORTAL_PRODUCER_CATALOGUE"
            }
        })
            .then((response) => {
                let result= response.data;
                this.setState({
                    products: result.products,
                    subs: result.subs,
                    data: result.data,
                    countProducts:result.countProducts,
                    loaded: true
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    changeLimit(event) {
        this.setState({limit: event.target.value, loaded: false})
    }

    changeSort(event) {
        this.setState({sort: event.target.value, loaded: false})
    }

    render() {

        let pages=Math.ceil(this.state.countProducts/this.state.limit);

        let paginations=[];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }
        if (this.state.loaded) {
            let tiles = this.state.products;
            let subs = this.state.subs;
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <div className="panel-heading no-padding-bottom">
                        <h3 className="panel-title">{this.state.data.name}</h3>
                    </div>
                    <div className="panel-body">
                        <div className="sub-catalogues">
                            <div className="cat-description" dangerouslySetInnerHTML={{__html: this.state.data.description}}>
                            </div>
                            <Row>
                                {subs.map((element, index) => {
                                    return(
                                <Col key={index} md={4} lg={4}>
                                    <Link title={element.name} to={Session.getItem("basename")+"/producer/"+this.state.id+"/"+element.id}>
                                    <div className="panel catalogue">
                                        <div className="panel-body p-15">
                                            <Row>
                                                <Col xs={4}>
                                            <div className="thumb thumb-fixed cat-thumb">
                                                <img src={Session.getItem("endpoint")+element.picture} alt={element.name} />
                                            </div>
                                                </Col>
                                                <Col xs={8}>
                                            <div className="cat-title">
                                                {element.name}
                                            </div>
                                                </Col>
                                            </Row>
                                            <div className="clearfix"/>
                                        </div>
                                    </div>
                                    </Link>
                                </Col>)
                                })}
                            </Row>
                        </div>
                        <div className="product-toolbox">
                            <div className="row">
                                <div className="col-lg-4 ">
                                    <div className="btn-group">
                                        <Button onClick={() => {
                                            this.setState({gridType: "list"});
                                            Session.setItem("gridType", "list")
                                        }} size={"sm"}  variant={"light"} style={this.state.gridType==="list"?{background:window.portalSettings.portal_pcolor,color:PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}:{}}><i className="icon icon-list"/></Button>
                                        <Button onClick={() => {
                                            this.setState({gridType: "grid"});
                                            Session.setItem("gridType", "grid")
                                        }} size={"sm"} variant={"light"} style={this.state.gridType==="grid"?{background:window.portalSettings.portal_pcolor,color:PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}:{}}><i className="icon icon-grid"/></Button>
                                    </div>
                                </div>
                                <div className="col-lg-8 text-right">
                                    <div className="input-group inline">
                                        <label className="pt-15">{ST.get("show","global")}:</label>
                                        <select value={Number(this.state.limit)} className="form-control" onChange={this.changeLimit.bind(this)}>
                                            <option  value={24}>24</option>
                                            <option  value={48}>48</option>
                                        </select>
                                    </div>
                                    <div className="input-group inline">
                                        <label className="pt-15">{ST.get("order_by","global")}:</label>
                                        <select className="form-control" value={this.state.sort} onChange={this.changeSort.bind(this)}>
                                            <option value={"catalogue_order"}>{ST.get("lib_quotation_text_default","global")}</option>
                                            <option value={"product_name ASC"}>A-Z</option>
                                            <option value={"product_name DESC"}>Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.gridType === "grid" ?
                            <Row>
                                {tiles.map((element, index) => {
                                return (<ProductTile data={element} key={"productTile" + index}/>);
                            })}
                            </Row>
                            :
                            <div className="table-responsive">
                                <table className="table table-lg">
                                    <thead>
                                    <tr>
                                        <th className="text-left">{ST.get("product_name","product")}</th>
                                        <th className="text-left">{ST.get("product_order_our","product")}</th>
                                        <th className="text-left">{ST.get("catalogue","global")}</th>
                                        <th className="text-left">{ST.get("product_availability","product")}</th>
                                        <th className="text-right">{ST.get("price_excl_vat","global")}</th>
                                        <th className="text-right">{ST.get("price_incl_vat","global")}</th>
                                        <th className="text-right">&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tiles.map((element, index) => {
                                        return (<ProductRow data={element} key={"productTile" + index}/>);
                                    })}

                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className="text-center content-group-lg pt-20">
                            <ul className="pagination">
                                <li className={this.state.page===1?"disabled":""}><Button disabled={this.state.page===1} onClick={() => {
                                    this.setState({page: (this.state.page-1),loaded:false})}} size={"sm"} variant={"light"}><i className="icon-arrow-small-left"/></Button></li>
                                {paginations.map((element, index) => {
                                    return (element);
                                })}
                                <li className={this.state.page===pages?"disabled":""}>
                                    <Button disabled={this.state.page===pages} onClick={() => {
                                        this.setState({page: (this.state.page+1),loaded:false})}} size={"sm"} variant={"light"}><i className="icon-arrow-small-right"/></Button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            )

        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}