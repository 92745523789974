import React, {Component} from 'react';
import {Button, Col, FormControl, Row} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import axios from "axios";
import {ST} from "../global/SystemText";
import qotext from "../images/qotext.jpg";
import ProductQORow from "../components/ProductQORow";
import ProductAutocomplete from "./ProductAutocomplete";
import {BubbleLoader} from "react-css-loaders";

export default class QuickOrderText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            data: [],
            sended: false,
            showLoading: false
        }

    }

    changeInput = (event) => {
        this.setState({value: event.target.value});
    };

    selectItem(index, selected, selectedType) {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idProduct: selected,
                    idCatalogue: "0",
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    action: "GET_PORTAL_PRODUCT"
                }
            })
            .then((response) => {

                let data = this.state.data;

                data[index].product = response.data;
                data[index].idType = selectedType;
                data[index].result = 1;
                this.setState({data: data});

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    async findProduct(orderOur, amount) {
        let result = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idCurrency: Session.getItem("idCurrency"),
                idLanguage: Session.getItem("idLanguage"),
                priceLevel: Session.getItem("priceLevel"),
                orderOur: orderOur,
                amount: amount,
                action: "PORTAL_QO_FIND_PRODUCT"
            }
        });
        //console.log(result);
        let {data} = await result;
        return data;
    }

    async asyncForEach(array, callback, data) {
        this.setState({showLoading: true})
        for (let index = 0; index < array.length; index++) {
            if (array[index].trim() !== "") {
                let row = (array[index].trim()).split(" ");
                if (row.length > 2) {
                    let orderOur = "";
                    for (let i = 0; i < (row.length - 1); i++) {
                        orderOur += row[i] + " ";
                    }
                    let amount = row[row.length - 1];
                    row = [];
                    row[0] = orderOur.trim();
                    row[1] = amount;
                }
                let orderOur = row[0];
                let amount = Number(row[1]);
                let product = await callback(orderOur, amount);
                data.push(product);
            }
        }
        return data;
    }


    async getData() {
        let value = this.state.value;
        let rows = value.split('\n');
        let data = await this.asyncForEach(rows, this.findProduct, []);
        value = "";

        this.setState({
            data: data,
            sended: true,
            value: value,
            showLoading: false

        });


    }

    changeOrderOur(index, event) {
        let data = this.state.data;
        data[index].product.orderOur = event.target.value;
        this.setState({data: data});
    }

    removeFromList(index) {
        let data2 = this.state.data;
        data2.splice(index, 1);
        this.setState({data: data2});
    }

    async addToBasket() {
        this.setState({showLoading:true})
        for (const element of this.state.data){
            if (Number(element.idType) > 0) {
                let typeIndex = 0;
                element.product.types.forEach((type, index) => {
                    if (type.id === Number(element.idType)) {
                        typeIndex = index;
                    }
                });
                await PT.addToBasket(element.amount, element.product.idParent, element.product.types[typeIndex].id, element.product, element.product.types[typeIndex],false);
            } else {
                await PT.addToBasket(element.amount, element.product.idParent, 0, element.product,null,false);

            }
        }
        this.setState({showLoading:false})

        await this.props.hide();
    }

    changeType(index, idType){
        let data = this.state.data;
        data[index].idType=idType;

        this.setState({data})
    }
    changeAmount(index, amount){
        let data = this.state.data;
        data[index].amount=amount;

        this.setState({data})
    }


    render() {
        if (this.state.showLoading) {
            return (<div>
                    <Row>
                        <Col md={12}>
                            <BubbleLoader color={window.portalSettings.portal_pcolor}/>
                        </Col>
                    </Row>
                </div>
            );
        }
        if (this.state.sended) {
            let isOk = true;


            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <table className="table table-lg">
                                <thead>
                                <tr>
                                    <th className="text-left">{ST.get("product_name", "product")}</th>
                                    <th className="text-left">{ST.get("product_order_our", "product")}</th>
                                    <th className="text-left">{ST.get("product_availability", "product")}</th>
                                    {Boolean(window.portalSettings.show_prices) ?
                                        Boolean(window.portalSettings.main_price_excl_vat) ?
                                            <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                            :
                                            <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                        : null }
                                    {Boolean(window.portalSettings.show_prices) ?
                                        Boolean(window.portalSettings.main_price_excl_vat) ?
                                            <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                            :
                                            <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                        : null }
                                    <th className="text-right">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map((element, index) => {
                                    if (element.result === 1) {
                                        if(element.product.replacements?.length>0){
                                            isOk=false
                                        }
                                        return (
                                            <ProductQORow selectedType={element.idType} data={element.product} amount={element.amount} key={"productTile" + index}
                                                          onChangeType={(idType)=>this.changeType(index,idType)}
                                                          onChangeAmount={(amount)=>this.changeAmount(index,amount)}
                                            />
                                        );
                                    } else {
                                        isOk = false;
                                        return (
                                            <tr key={index}>
                                                <td><b className={"text-danger"}>{ST.get("item_not_found", "portal")}</b></td>
                                                <td>
                                                    <ProductAutocomplete onSelect={(selected, selectedType) => this.selectItem(index, selected, selectedType)} value={element.product.orderOur}/>
                                                </td>
                                                <td></td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <Button className={"pull-right btn btn-sm"} onClick={() => this.removeFromList(index)}><i className={"icon icon-bin"}/> </Button>
                                                </td>
                                            </tr>

                                        );
                                    }
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>

                        </Col>

                    </Row>
                    <div className="btn-group pull-right mt-10">
                        <Button className="btn-primary" disabled={!isOk} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => {
                            this.addToBasket()
                        }}>{ST.get("add_to_basket", "portal")}</Button>
                        <Button className="btn-default" onClick={() => this.props.hide()}>{ST.get("close", "global")}</Button>
                    </div>
                </div>)

        } else {

            return (
                <div>
                    <Row>
                        <Col md={7}>

                            <FormControl
                                className={"form-control-bordered"}
                                componentClass="textarea"
                                value={this.state.value}
                                placeholder={ST.get("product_order_our", "product").toUpperCase() + " " + ST.get("space", "global").toUpperCase() + " " + ST.get("amount", "global").toUpperCase()}
                                onChange={this.changeInput}
                                rows={6}
                            />

                        </Col>
                        <Col md={5}>
                            <div>{ST.get("qo_text_help", "portal")}</div>
                            <img className="img-responsive" src={qotext}/>
                        </Col>

                    </Row>
                    <div className="btn-group pull-right mt-10">
                        <Button className="btn-primary" style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={async () => {
                            await this.getData()
                        }}>{ST.get("mail_send", "global")}</Button>
                        <Button className="btn-default" onClick={() => this.props.hide()}>{ST.get("close", "global")}</Button>
                    </div>
                </div>)
        }
    }
}
