import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";

export default class WarningDialog extends Component {
    _getDefaultButton = () => {
        return (
            <Button onClick={this.props.onHide} className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
        )
    }

    render() {
        return (
            <Modal size="lg" dialogClassName={"modal-lg"}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={this.props.show}
                   onHide={this.props.onHide}>
                <Modal.Header  style={{background:window.portalSettings.portal_pcolor,color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>
                    <Modal.Title>{ST.get("global_warning", "global")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-15">
                    <Row>
                        <Col md={12}>
                            <p className="center" style={{fontSize: "30px"}}>
                                <b style={{color: window.portalSettings.portal_pcolor}}>
                                    {this.props.warningText}
                                </b>
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.buttons ? this.props.buttons : this._getDefaultButton()}
                </Modal.Footer>
            </Modal>
        )
    }
}