import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {Session} from "../global/PortalTools";
import * as PT from "../global/PortalTools";


export default class Notice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: [{title:"haha",text:"fungujem"}]

        };
    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    action: "GET_NOTICE"
                }
            })
            .then((response) => {
                let {data} = response;
                this.setState({data, loaded: true});
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });

    }

    render() {

        if (this.state.loaded && this.state.data.length > 0) {
            return (

                <Row>
                    <Col md={12}>
                        {this.state.data.map((element, index) =>
                            <div className="alert alert-info  alert-styled-right" >
                                <span className="text-semibold">{element.title} </span>
                                <div dangerouslySetInnerHTML={{__html:element.text}}/>
                            </div>
                        )}

                    </Col>
                </Row>
            );
        } else {
            return(<div></div>)
        }
    }
}