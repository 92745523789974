import React, {Component} from 'react';
import axios from "axios";
import * as PT from "./global/PortalTools";
import {Session} from "./global/PortalTools";
import {ST} from "./global/SystemText";
import packageJson from '../package.json';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {}
        }
    }

    componentDidMount() {
        this.getFooterData();
    }

    getFooterData() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
                idMandant: Session.idMandant,
                idLanguage: this.props.settings.idLanguage===undefined || this.props.settings.idLanguage===0 ?Session.getItem("idLanguage"):this.props.settings.idLanguage,
                action: "GET_PORTAL_FOOTER"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if (this.state.loaded) {
            let {mandant, about} = this.state.data;
            return (
                <div>
                    <div className={(PT.getColorBrightness(this.props.settings.portal_scolor) < 135 ? "portal-footer" : "portal-footer-black")+ " no-margin"} style={{background: this.props.settings.portal_scolor}}>
                        <div className="p-10">
                            <div className="row">
                                {about.name && String(about.name).length > 0 ?(<div className="col-md-5 col-sm-6 pre-footer-col pr-20">
                                    <h2>{about.name}</h2>
                                    <div className="text-justify" dangerouslySetInnerHTML={{__html: about.text}}/>
                                </div>) : null}
                                <div className="col-md-3 col-sm-6 pre-footer-col">
                                    <h2>{ST.get("contacts","global")}</h2>
                                    <address className="margin-bottom-40">
                                        <p>
                                            {mandant.mandant_name}<br/>
                                            {mandant.mandant_street1}<br/>
                                            {mandant.mandant_zip1} {mandant.mandant_city1}<br/>
                                            {mandant.oCountry1.country_name}
                                        </p>
                                        <p>
                                            {mandant.mandant_phone.trim()!==""? <span>{ST.get("phone","mandant")+": "+mandant.mandant_phone}<br/></span>:null}
                                            {mandant.mandant_fax.trim()!==""?<span>{ST.get("fax","mandant")+": "+mandant.mandant_fax}<br/></span>:null}
                                            {mandant.mandant_email.trim()!==""?<span>{ST.get("email","person")+": "+mandant.mandant_email}<br/></span>:null}
                                            {mandant.mandant_web.trim()!==""?<span>{ST.get("web_page","mandant")+": "+mandant.mandant_web}<br/></span>:null}
                                            </p>
                                    </address>
                                </div>
                                <div className="col-md-4 col-sm-6 pre-footer-col">
                                    <h2>{ST.get("invoice_data","mandant")}</h2>
                                    <address className="margin-bottom-40">
                                        <p>
                                            {mandant.mandant_ico.trim()!==""? <span>{ST.get("ico","company")+": "+mandant.mandant_ico}<br/></span>:null}
                                            {mandant.mandant_dic.trim()!==""? <span>{ST.get("dic","company")+": "+mandant.mandant_dic}<br/></span>:null}
                                            {mandant.mandant_icdph.trim()!==""? <span>{ST.get("icdph","company")+": "+mandant.mandant_icdph}<br/></span>:null}
                                        </p>
                                        <p>
                                            {mandant.mandant_register.trim()!==""? <span>{ST.get("register","mandant")+": "+mandant.mandant_register}<br/></span>:null}
                                            {mandant.mandant_register_number.trim()!==""? <span>{ST.get("register_number","mandant")+": "+mandant.mandant_register_number}<br/></span>:null}

                                        </p>
                                    </address>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portal-second-footer">
                        <div className="pl-10 pr-10 pt-10">
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <a href="https://www.offeris.com/sk" target="_blank" rel="noopener noreferrer">{ST.get("created_in_offeris","global")}</a>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <a href="https://www.offeris.com/sk" target="_blank" rel="noopener noreferrer">www.offeris.com</a>
                                </div>
                                <div className="col-md-2 col-sm-2">
                                    ver. {packageJson.version}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return ("");
        }
    }
}
