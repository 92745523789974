import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../../global/SystemText";
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";


export default class ClaimButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            showClaim: false,
            descriptionError: false,
            data: {},
            selectedCover: 0,
            selectedType: 0,
            description: "",
            serno: "",
            catno: "",
            maxprice: "",
            code: "",
            files:[]
        };
    }



    componentDidMount() {

        //console.log(this.props.id);
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                idItem: this.props.id,
                action: "GET_CLAIM_FOR_ITEM"
            }
        })
            .then((response) => {
                //console.log(response.data);
                let cover = 0;
                if (response.data.covers.length > 0) {
                    cover = response.data.covers[0].id;
                }
                let type = 0;
                if (response.data.types.length > 0) {
                    type = response.data.types[0].id;
                }
                this.setState({
                    loaded: true,
                    data: response.data,
                    selectedCover: cover,
                    selectedType: type
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    toogleModal() {
        this.setState({
            showClaim: !this.state.showClaim
        })
    }

    changeCover(event) {
        this.setState({selectedCover: Number(event.target.value)})
    }

    changeType(event) {
        this.setState({selectedType: Number(event.target.value)})
    }

    changeDescription(event) {
        this.setState({description: event.target.value,descriptionError:false})
    }

    changeSerno(event) {
        this.setState({serno: event.target.value})
    }

    changeCatno(event) {
        this.setState({catno: event.target.value})
    }

    changeCode(event) {
        this.setState({code: event.target.value})
    }

    changeMaxprice(event) {
        this.setState({maxprice: event.target.value})
    }

    sendClaim() {
        console.log(this.state.description);
        if(this.state.description==="") {
            this.setState({descriptionError:true})
            return;
        }
        //console.log(this.state);
        let data = new FormData();
        this.state.files.map((item, index) => {
            data.append("file" + index, item);
            data.append("fileName" + index, item.name);
        });
        data.append("fileAmount",  this.state.files.length+"");
        data.append("action", "SEND_CLAIM");
        data.append("idMandant", Session.idMandant);
        data.append("idContact", Session.getItem("idContact"));
        data.append("idCompany", Session.getItem("idCompany"));
        data.append("idItem", this.props.id);
        data.append("cover", this.state.selectedCover+"");
        data.append("type", this.state.selectedType+"");
        data.append("serno", this.state.serno+"");
        data.append("catno", this.state.catno+"");
        data.append("code", this.state.code+"");
        data.append("maxprice", this.state.maxprice+"");
        data.append("description", this.state.description+"");
        axios.post(Session.getItem("endpoint") + 'PortalServlet', data,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then((response) => {
                let id=response.data.id;
                window.location=Session.getItem("basename")+"/claim/"+id;
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })

    }


    fileLoad(){
        const input = document.getElementById('fileInput');
        this.setState({files:Array.from(input.files)});
    }

    render() {
        if (this.state.loaded) {
            return (
                <Row>
                    <Col md={8}>
                        {this.state.data.claims.map((element, index) => {
                            return (<div key={index}><Link to={"../claim/"+element.id}>{element.number}</Link></div>)
                        })}
                    </Col>
                    <Col md={4}>
                        <Button className={"btn-sm"} onClick={() => this.toogleModal()}
                                title={ST.get("claim_append", "document")}><i className={"icon icon-plus3"}/></Button>
                        <Modal size="lg"
                               aria-labelledby="contained-modal-title-vcenter"
                               show={this.state.showClaim} onHide={() => this.toogleModal()}>
                            <Modal.Header style={{background:window.portalSettings.portal_pcolor,color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>
                                <Modal.Title>{ST.get("claim_append", "document")} - {this.props.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col md={7}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_type", "document")} <span
                                            style={{color: "red"}}>*</span>:</label>
                                        <select className="form-control"
                                                value={this.state.selectedType}
                                                onChange={this.changeType.bind(this)}
                                        >
                                            {this.state.data.types.map((element, index) => {
                                                return (<option key={index}
                                                                value={element.id}>{element.code} {element.name}</option>);
                                            })}
                                        </select>
                                    </Col>
                                    <Col md={5}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_cover", "document")} <span
                                            style={{color: "red"}}>*</span>:</label>
                                        <select className="form-control"
                                                value={this.state.selectedCover}
                                                onChange={this.changeCover.bind(this)}
                                        >
                                            {this.state.data.covers.map((element, index) => {
                                                return (<option key={index}
                                                                value={element.id}>{element.code} {element.name}</option>);
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col md={3}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_code", "document")}:</label>
                                        <input className="form-control" type={"text"}
                                               onChange={this.changeCode.bind(this)} value={this.state.code}/>
                                    </Col>
                                    <Col md={3}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_maxprice", "document")}:</label>
                                        <input className="form-control" type={"text"}
                                               onChange={this.changeMaxprice.bind(this)} value={this.state.maxprice}/>
                                    </Col>

                                    <Col md={3}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_serno", "document")}:</label>
                                        <input className="form-control" type={"text"}
                                               onChange={this.changeSerno.bind(this)} value={this.state.serno}/>
                                    </Col>
                                    <Col md={3}>
                                        <label style={{marginBottom: 0}}>{ST.get("claim_catno", "document")}:</label>
                                        <input className="form-control" type={"text"}
                                               onChange={this.changeCatno.bind(this)} value={this.state.catno}/>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col md={12}>
                                        <label className={this.state.descriptionError?"text-danger":""}
                                            style={{marginBottom: 0}}>{ST.get("claim_description", "document")}:<span
                                            style={{color: "red"}}>*</span></label>
                                        <textarea style={{borderTop:0}} className={"form-control "+(this.state.descriptionError?"has-error":"")} value={this.state.description}
                                                  onChange={(e) => this.changeDescription(e)}/>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col md={12}>
                                        <input style={{margin:"0 auto"}} className={"file-styled-primary"} type={"file"} id={"fileInput"} multiple  accept="image/jpg, image/jpeg" onChange={()=>{this.fileLoad()}}/>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button style={{background:window.portalSettings.portal_pcolor,color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => this.sendClaim()}>{ST.get("confirm")}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            );

        } else {
            return (<div>&nbsp;</div>)
        }
    }
}




