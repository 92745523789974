import React from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import SearchResult from "./SearchResult";
import axios from "axios";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Link} from "react-router-dom";
import WarningDialog from "../components/WarningDialog";

export default  class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowNew: false,
            isLoading: false,
            multiple: false,
            options: [],
            showSearch: false,
            showProductEanWarning: false,
            number:0,
            selected:[]
        };

    }

    componentDidMount() {
        window.addEventListener('showSearch', function (e) {
            this.setState({
                showSearch: !this.state.showSearch
            });
        }.bind(this));


    }

    componentWillUnmount() {
        window.removeEventListener('showSearch', function () {
        });
    }

    _scanBarCode = () => {
        window.cordova.plugins.barcodeScanner.scan(
            (result) => {
                let ean = JSON.parse(result.text);
                this._getProductIdByEan(ean);
            },
            (error) => {
                navigator.notification.alert(
                    "Scanning failed: " + error,  // message
                    () => {
                    },         // callback
                    'Error',            // title
                    'Done'                  // buttonName
                );
            },
            {
                preferFrontCamera : false, // iOS and Android
                showFlipCameraButton : true, // iOS and Android
                showTorchButton : true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: false, // Android, save scan history (default false)
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                disableAnimations : true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        );
    }

    _getProductIdByEan = (ean) => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    ean: ean,
                    action: "GET_PRODUCT_ID_BY_EAN"
                }
            })
            .then((response) => {
                let id = response.data.id;
                if (id > 0) {
                    this.props.history.push(Session.getItem("basename") + "/product/" + id);
                } else {
                    this.setState({
                        showProductEanWarning: true
                    })
                }

            })
            .catch((error) => {
                this.setState({
                    showProductEanWarning: true
                })
                PT.handleError(error, this.props.history);
            })
    }

    _handleSearch = (query) => {
        this.setState({isLoading: true});
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {idMandant: Session.idMandant,idCompany:Session.getItem("idCompany"),idContact:Session.getItem("idContact"),idLanguage:Session.getItem("idLanguage"),idCurrency:Session.getItem("idCurrency"), query: query, action: "GET_SEARCH_RESULTS"}})
            .then((response) => {
                this.setState({
                    isLoading: false,
                    options:response.data.options,
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })


    };
    _handleSelect = (selected) => {

        if(selected[0]!==undefined) {
            let basename = "/" + Session.instance + "/" + Session.idMandant;
            this.props.history.replace(basename + "/" + selected[0].type + "/" + selected[0].id);
            this.setState({selected:selected},()=>this.clearInput())
        }

/*
       if(selected!==undefined && selected[0]!==undefined){
           let basename = "/" + Session.instance + "/" + Session.idMandant;
           this.props.history.replace(basename+"/"+selected[0].type+"/"+selected[0].id);
       }
*/
    };

    clearInput(){
        const instance = this._typeahead;
        setTimeout(function(){
            console.log(instance)
            instance.clear()
            this.setState({
                selected:[]
            },()=>instance.clear())
        }.bind(this), 200);
    }

    render() {
        return (
            <div>
                <div className="visible-xs-block visible-sm-block">
                    {window.cordova ?
                        <i className="icon icon-camera position-left mr-10" style={{paddingRight:"10px"}} onClick={this._scanBarCode}/>
                        : null}
                    <i className="icon icon-search4 position-left" onClick={() => this.setState({showSearch: !this.state.showSearch})}/>
                    <div  className={"search-popup z-index999 " + (this.state.showSearch ? "" : "hidden")}>
                        <AsyncTypeahead
                            ref={(ref) => this._typeahead = ref}
                            {...this.state}
                            id={"Search"}
                            minLength={3}

                            className={"search-dropdown right0"}
                            filterBy={(option, props) => {
                                return true;
                            }}
                            clearButton
                            searchText={ST.get("searching","global")}
                            emptyLabel={ST.get("no_results","global")}
                            labelKey="searchString"
                            highlightOnlyResult={true}

                            onSearch={this._handleSearch}
                            onChange={this._handleSelect}
                            placeholder={ST.get("search","global")}
                            renderMenuItemChildren={(option, props) => (
                                <SearchResult  option={option} id={option.id} key={option.id} />
                            )}
                        />
                    </div>
                </div>
                <div className="hidden-xs hidden-sm" style={{display: "inline-flex"}}>
                    {window.cordova ?
                    <i className="icon icon-barcode2 position-left ean-camera" onClick={this._scanBarCode}/>
                    : null }
                    <AsyncTypeahead
                        {...this.state}
                        ref={(ref) => this._typeahead = ref}
                        id={"Search"}
                        minLength={3}
                        className={"search-dropdown"}
                        filterBy={(option, props) => {
                            return true;
                        }}
                        clearButton
                        searchText={ST.get("searching","global")}
                        emptyLabel={ST.get("no_results","global")}
                        labelKey="searchString"
                        highlightOnlyResult={true}
                        onSearch={this._handleSearch}
                        onChange={this._handleSelect}
                        placeholder={ST.get("search","global")}
                        renderMenuItemChildren={(option, props) => (
                            <SearchResult  option={option} id={option.id} key={option.id} />
                        )}
                    />
                </div>
                <WarningDialog
                    show={this.state.showProductEanWarning}
                    onHide={() => this.setState({showProductEanWarning: false})}
                    warningText={ST.get("product_not_found", "product")}
                />
            </div>
        );
    }
}