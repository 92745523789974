import React, {Component} from 'react';
import {Modal, FormGroup, Row, Col, Button, FormControl} from "react-bootstrap";
import LabeledInput from "../../components/LabeledInput";
import {ST} from "../../global/SystemText";
import axios from "axios/index";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";

export default class ContactModal extends Component{
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            person: this.props.person ? Object.assign({}, this.props.person) : {},
            title: this.props.title ? this.props.title : ST.get("add_person", "global"),
            countries: []
        };
    }

    componentDidMount() {
        this.getCountries();
        if(this.state.person.company_id===undefined){
            let person=this.state.person;
            person.company_id=0;
            this.setState({person:person});
        }
    }

    getCountries = () => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idDealer: Session.getItem("idDealer"),
                action: 'GET_PORTAL_COUNTRIES'
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    countries: response.data.countries,
                    loaded: true
                })

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    hideModal = () => {
        this.setState({showModal: false});
    }

    showModal = () => {
        this.setState({showModal: true});
    }

    handleEdit = (input, event) => {
        let person = this.state.person;
        person[input] = event.target.value;
        this.setState({person});
    };

    submitModal = () => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            ...this.state.person,
            idMandant: Session.idMandant,
            idDealer: Session.getItem("idDealer"),
            action: "SAVE_CONTACT"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }})
            .then((response) => {
                //console.log(response.data);
                this.props.callback(response.data);

            })
            .catch((error) => {
                //console.log(error);
            })

        this.hideModal();
    }

    clearData = () => {
        let newPerson = this.props.person ? Object.assign({}, this.props.person) : {}
        this.setState({person: newPerson});
    }

    render() {
        let editedChild = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            const props = { onClick: this.showModal };
            if (React.isValidElement(child)) {
                return React.cloneElement(child, props);
            }
            return child;
        });
        return (
            <div>
                {/*<Button onClick={this.showModal}>{ST.get("edit", "global")}</Button>*/}
                {editedChild}
                <Modal
                    show={this.state.showModal}
                    onHide={this.hideModal}
                    bsSize={"lg"}
                >
                    <Modal.Header className="bg-success">
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("name", "person")}
                                        onChange={this.handleEdit.bind(this, "firstname")}
                                        value={this.state.person.firstname || ''}
                                    />
                                </Col>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("surname", "person")}
                                        onChange={this.handleEdit.bind(this, "surname")}
                                        value={this.state.person.surname || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("mobile", "person")}
                                        onChange={this.handleEdit.bind(this, "mobile")}
                                        value={this.state.person.mobile || ''}
                                    />
                                </Col>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("email", "person")}
                                        onChange={this.handleEdit.bind(this, "email")}
                                        value={this.state.person.email || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className={"pl-10"}>{ST.get("address","global")}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <LabeledInput
                                        placeholder={ST.get("street", "person")}
                                        onChange={this.handleEdit.bind(this, "street")}
                                        value={this.state.person.street || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("zip", "person")}
                                        onChange={this.handleEdit.bind(this, "zip")}
                                        value={this.state.person.zip || ''}
                                    />
                                </Col>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("city", "person")}
                                        onChange={this.handleEdit.bind(this, "city")}
                                        value={this.state.person.city || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormControl componentClass="select" value={this.state.person.country_id} onChange={this.handleEdit.bind(this, "country_id")}>
                                        <option value={-1}>{ST.get("country", "global")}</option>
                                        {this.state.countries.map((element, index) => {
                                            return (<option key={index} value={element.id}>{element.name}</option>);
                                        })}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className={"pl-10"}>{ST.get("delivery_address", "person")}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <LabeledInput
                                        placeholder={ST.get("street", "person")}
                                        onChange={this.handleEdit.bind(this, "street2")}
                                        value={this.state.person.street2 || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("zip", "person")}
                                        onChange={this.handleEdit.bind(this, "zip2")}
                                        value={this.state.person.zip2 || ''}
                                    />
                                </Col>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("city", "person")}
                                        onChange={this.handleEdit.bind(this, "city2")}
                                        value={this.state.person.city2 || ''}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormControl componentClass="select" value={this.state.person.country_id2} onChange={this.handleEdit.bind(this, "country_id2")}>
                                        <option value={-1}>{ST.get("country", "global")}</option>
                                        {this.state.countries.map((element, index) => {
                                            return (<option key={index} value={element.id}>{element.name}</option>);
                                        })}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className={"pl-10"}>{ST.get("other", "global")}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <LabeledInput
                                        placeholder={ST.get("birthdate", "person")}
                                        onChange={this.handleEdit.bind(this, "birthdate")}
                                        value={this.state.person.birthdate || ''}
                                    />
                                </Col>

                            </Row>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.clearData}>{ST.get("clear", "global")}</Button>
                        <Button onClick={this.hideModal}>{ST.get("cancel", "global")}</Button>
                        <Button className="btn-success" onClick={this.submitModal}>{ST.get("confirm", "global")}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
