import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Document from './document/Document';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import B2C from "./B2C";
import B2B from "./B2B/B2B";
import Dashboard from "./B2B/Dashboard";
import MyProducts from "./myproducts/MyProducts";
import DocumentList from "./B2B/documents/DocumentList";
import Catalogue from "./B2B/Catalogue";
import Product2 from "./B2B/Product2";
import Basket from "./B2B/Basket";
import UndeliveredProducts from "./B2B/UndeliveredProducts";
import Contact from "./Contact";
import MandantText from "./MandantText";
import Dms from "./B2B/dms/Dms";
import {Session} from "./global/PortalTools";
import * as PT from "./global/PortalTools";
import QuickOrder from "./B2B/QuickOrder";
import Producers from "./B2B/Producers";
import Producer from "./B2B/Producer";
import CustomerList from "./B2B/customers/CustomerList";
import ContactDetail from "./B2B/customers/ContactDetail";
import CompanyDetail from "./B2B/customers/CompanyDetail";
import Claim from "./B2B/documents/Claim";
import Wishlists from "./B2B/documents/Wishlists";
import Wishlist from "./B2B/documents/Wishlist";

export default class Portal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
        //tuto si zistime z backendu aky typ portalu mame aby sme mohli nastavit zobrazenie a pripadne pytat prihlasenie
        // /offeris/11/quotation/95399/h8j4h3knhf4495399035
        //Session.setItem("portal_type", "0"); // 0 - B2C, 1 - B2C plateny, 2 - B2B
    }

    async componentDidMount() {
        let endpoint = "";

        let instance = this.props.match.params.instance;
        let idMandant = this.props.match.params.idMandant;

        if(instance!==undefined || idMandant!==undefined) {
            window.localStorage.setItem("instance", instance);
            window.localStorage.setItem("idMandant", idMandant);
        }

        await Session.initStorage(instance, idMandant);

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            endpoint = "/offeris/";
            Session.setItem("logoutTimer", Date.now() + 30000 * 60000);
            endpoint = "https://centrumnaradia.offeris.com/centrumnaradia/";
        }else{
            endpoint = "https://" + this.props.match.params.instance + ".offeris.com/" + this.props.match.params.instance + "/";
        }

        Session.setItem("endpoint", endpoint);
        let basename = "/" + instance + "/" + idMandant;
        Session.setItem("basename", basename);


        //TODO - nastavenie tokenu podla prihlasenia v Login.js pre B2B
        axios.get(endpoint + 'PortalServlet', {params: {idMandant: this.props.match.params.idMandant, action: "GET_PORTAL_TYPE"}})
            .then((response) => {
                Session.setItem("portal_type", response.data);
                this.setState({loaded: true});
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    render() {
        let basename = Session.getItem("basename");

        let {loaded} = this.state;

        if (loaded) {
            if (Number(Session.getItem("portal_type")) < 2) {
                return (
                    <Switch>
                        <Route path={basename + "/:document/:id/:hash/:response"} component={B2C}/>
                        <Route path={basename + "/:document/:id/:hash"} component={B2C}/>
                        <Redirect to={"/403"}/>
                    </Switch>
                );
            } else {
                if (Number(Session.getItem("logoutTimer")) < Date.now()||Session.getItem("logged") ==="false") {
                    return (
                        <Switch>
                            <Route path={basename + "/:document/:id/:hash"} component={B2C}/>
                            <Redirect to={basename +"/login?logout=true"}/>
                        </Switch>
                    );
                } else {
                    return (
                        <div>
                        <B2B {...this.props}>
                            <Switch>
                                <Route exact path={basename + "/"} component={Dashboard}/>
                                <Route exact path={basename + "/documents/:document/:type"} component={DocumentList}/>
                                <Route exact path={basename + "/documents/:document/"} component={DocumentList}/>
                                <Route exact path={basename + "/customers/:type/"} component={CustomerList}/>
                                <Route exact path={basename + "/undelivered_products"} component={UndeliveredProducts}/>
                                <Route path={basename + "/myproducts"} component={MyProducts}/>
                                <Route path={basename + "/dms/:id"} component={Dms}/>
                                <Route path={basename + "/dms"} component={Dms}/>
                                <Route path={basename + "/claim/:id"} component={Claim}/>
                                <Route path={basename + "/wishlists"} component={Wishlists}/>
                                <Route path={basename + "/wishlist/:id"} component={Wishlist}/>
                                <Route path={basename + "/quickorder"} component={QuickOrder}/>
                                <Route path={basename + "/terms"} render={props => <span><MandantText type={1} {...props} settings={window.portalSettings}/><MandantText type={2} {...props} settings={window.portalSettings}/></span>}/>
                                <Route path={basename + "/reference"} render={props => <MandantText {...props} settings={window.portalSettings} type={4}/>}/>
                                <Route path={basename + "/basket"} component={Basket}/>
                                <Route path={basename + "/product/:id"} component={Product2}/>
                                <Route path={basename + "/product/:id/:idCatalogue"} component={Product2}/>
                                <Route path={basename + "/catalogue/:id"} component={Catalogue}/>
                                <Route path={basename + "/producers"} component={Producers}/>
                                <Route path={basename + "/producer/:id/:idCatalogue"} component={Producer}/>
                                <Route path={basename + "/producer/:id"} component={Producer}/>
                                <Route path={basename + "/company/:id"} render={props => <CompanyDetail {...props} settings={window.portalSettings} type={"company"}/>}/>
                                <Route path={basename + "/contact/:id"} render={props => <ContactDetail {...props} settings={window.portalSettings} type={"contact"}/>}/>
                                <Route path={basename + "/contact"} render={props => <Contact {...props} settings={window.portalSettings}/>}/>

                                <Route exact path={basename + "/null"} render={() => <Redirect from={basename +"/null"} to={basename +"/"}/>}/>
                                {/* toto musia byt posledne 2 riadky*/}
                                <Route path={basename + "/:document/:id/:hash"} component={Document}/>
                                <Route path={basename + "/:document/:id"} component={Document}/>

                            </Switch>
                        </B2B>
                </div>
                    );
                }
            }
        } else {
            return (<BubbleLoader/>);
        }
    }
}
