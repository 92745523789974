import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {ST} from "../global/SystemText";

import {Session} from "../global/PortalTools";

export default class BiznisMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            expand: true,
            active:0
        }
    }

    handleMobileLeftMenu(){
        var evt = new CustomEvent('handleMobileLeftMenu');
        window.dispatchEvent(evt);
    }

    render() {

            return (

                <div className="sidebar-category">
                    <div onClick={()=>this.setState({expand:!this.state.expand})} className="category-title" style={{background:window.portalSettings.portal_pcolor+"0d"}}>
                        <span>{ST.get("business","global")}</span>
                        <ul className="icons-list">
                            <li><i onClick={()=>this.setState({expand:!this.state.expand})} className={"icon icon-chevron-"+ (this.state.expand?"up":"down") +" cursor-pointer"}/></li>
                        </ul>
                    </div>
                    <ul className="navigation navigation-alt navigation-accordion navigation-sm no-padding-top" style={!this.state.expand ? {display:"none"} : {}}>
                        <li onClick={()=>this.handleMobileLeftMenu()}>
                            {Number(window.portalSettings.quotation_access)>0?
                                <Link   to={Session.getItem("basename")+"/documents/quotation"}>{ST.get("quotations_list","global")}</Link>:null}
                            {Number(window.portalSettings.orderin_access)>0?
                            <Link   to={Session.getItem("basename")+"/documents/orderin"}>{ST.get("orderins_list","global")}</Link>:null}
                            {Number(window.portalSettings.invoice_access)>0?
                            <Link   to={Session.getItem("basename")+"/documents/invoice"}>{ST.get("invoice_list","global")}</Link>:null}
                            {window.portalSettings.allow_claim?
                                <Link   to={Session.getItem("basename")+"/documents/claim"}>{ST.get("claim_list","global")}</Link>:null}
                            {Number(window.portalSettings.warehouse_access)>0?
                            <Link   to={Session.getItem("basename")+"/documents/warehouse"}>{ST.get("delivery_notes_list","global")}</Link>:null}
                            {Number(window.portalSettings.warehouse_access)>0 || window.localStorage.getItem("instance")==="pronar"?
                            <Link   to={Session.getItem("basename") + "/undelivered_products"}>{ST.get("undelivered_products", "global")}</Link>:null}
                            {window.portalSettings.dms_allow===true?
                            <Link   to={Session.getItem("basename")+"/dms"}>{ST.get("for_download","global")}</Link>:null}
                            {window.portalSettings.allow_wishlist?
                                <Link   to={Session.getItem("basename")+"/wishlists"}>{ST.get("wishlists_list","global")}</Link>:null}
                        </li>
                    </ul>
                </div>
            );

    }
}
