import React, {Component} from 'react';
import Place from "./map-marker.png";

import {OverlayTrigger, Popover} from "react-bootstrap";


export default class Marker extends Component {

    render() {
        let hint = (<Popover id="popover" className="" onMouseOver={() => {
        }}>
            <p>{this.props.address.street}<br/>{this.props.address.zip},&nbsp;{this.props.address.city}<br/>{this.props.address.country_name}
            </p>

        </Popover>);

        return (
            <div>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    delay={100}
                    placement="top"
                    overlay={hint}>
                    <img style={{height: "40px", width: "40px", marginTop: "-40px", marginLeft: "-20px"}} src={Place}
                         alt="Marker"/>
                </OverlayTrigger>
            </div>);

    }
}







