import React, {Component} from 'react';
import {BrowserRouter as BRouter, HashRouter as HRouter, Route, Switch} from 'react-router-dom';
import Portal from "./Portal";
import E404 from "./Error/E404";
import E403 from "./Error/E403";
import Login from "./B2B/Login";
import MobileHomePage from "./MobileHomePage";

const Router = window.cordova ? HRouter : BRouter

class App extends Component {
    render() {


        return (<div>
            <Router>
                <Switch>
                    <Route exact path="/404" component={E404}/>
                    <Route exact path="/403" component={E403}/>
                    <Route path="/:instance/:idMandant/login" component={Login}/>
                    <Route path="/:instance/:idMandant/" component={Portal}/>
                    {window.cordova ?
                        <Route component={MobileHomePage}/>
                        :
                        <Route component={E404}/>
                    }
                </Switch>
            </Router>

        </div>);
    }
}

export default App;
