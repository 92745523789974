import React, {Component} from 'react';
import {FormControl} from "react-bootstrap";

export default class LabeledInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : '',
            isFocused: String(props.value).trim().length !== 0
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({value: nextProps.value});
    }

    handleFocus = () => {
        this.setState({isFocused: true});
    };
    handleBlur = () => {
        let {value} = this.state;
        if(String(value).trim().length === 0)
            this.setState({isFocused: false});
    };

    masterFocus(){
        this.input.focus();
    }

    render() {
        return (
            <div className="floating-label-container" onClick={this.masterFocus.bind(this)}>
                <span className={this.state.isFocused ? "active" : ""} onClick={this.masterFocus.bind(this)}>{this.props.placeholder}</span>
                <FormControl
                    inputRef={input => this.input = input}
                    type="text"
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onChange={this.props.onChange}
                    value={this.state.value || ''}
                />
            </div>
        );
    }
}