import React, {Component} from 'react';

export default class PortalBanner extends Component {

    render() {
            return (
                <div className="panel">
                    <a href="https://offeris.com" target="_blank" rel="noopener noreferrer">
                        <img src="https://www.offeris.com/banner.png" alt="offeris banner" className="img-responsive"/>
                    </a>
                </div>
            );

    }
}
