import React, {Component} from 'react';
import TopMenu from "./TopMenu";
import Document from "./document/Document";
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import Contact from "./Contact";
import MandantText from "./MandantText";
import PortalBanner from "./PortalBanner";
import Footer from "./Footer";
import {ST} from "./global/SystemText";
import * as PT from "./global/PortalTools";
import {Session} from "./global/PortalTools";

export default class B2C extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: this.props.match.params.id,
            hash: this.props.match.params.hash,
            document: this.props.match.params.document,
            settings: {},
            panel: "document"
        };
    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                hash: this.state.hash,
                id: this.state.id,
                document: this.state.document,
                action: "GET_PORTAL_SETTINGS"
            }
        })
            .then(async (response) => {
                Session.setItem("idLanguage",response.data.idLanguage);
                ST.clean();
                await ST.init();

                this.setState({
                    loaded: true,
                    settings: response.data

                });
                window.portalSettings = response.data;
                this.setLang();
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }
    setLang(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idLanguage: Session.getItem("idLanguage"),
                action: "GET_PORTAL_LANG_ISO"
            }
        }).then((response) => {
            document.documentElement.lang=response.data.lang;
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }
    changePanel(panel) {
        this.setState({
            panel: panel
        })
    }

    render() {
        if (this.state.loaded) {
            let content = "";
            switch (this.state.panel) {
                default:
                case "document":
                    content = <Document {...this.props} settings={this.state.settings} isB2C={true}/>;
                    break;
                case "terms":
                    content = <span><MandantText type={1} {...this.props} settings={this.state.settings}/><MandantText type={2} {...this.props} settings={this.state.settings}/></span>;
                    break;
                case "contact":
                    content = <Contact {...this.props} settings={this.state.settings}/>;
                    break;
                case "reference":
                    content = <MandantText type={4} {...this.props} settings={this.state.settings}/>;
                    break;
            }

            return (
                <div>

                    <TopMenu document={this.state.document} id={this.state.id} settings={this.state.settings} changePanel={this.changePanel.bind(this)} active={this.state.panel}/>
                    <div className="container">
                        {content}
                        {(Number(Session.getItem("portal_type")) < 1) ? <PortalBanner/> : ""}
                    </div>
                    <Footer settings={this.state.settings}/>
                </div>
            );
        } else {
            return (<BubbleLoader color={this.state.settings.portal_pcolor}/>);
        }
    }
}
