import React, {Component} from 'react';
import quotation from "./../images/quotation.png";
import orderin from "./../images/orderin.png";
import invoice from "./../images/invoice.png";
import warehouse from "./../images/warehouse.png";
import product from "./../images/product.png";
import unknown from "./../images/unknown.jpg";

export default class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.images = {
            product, quotation, orderin, invoice, warehouse
        };
    }

    handleShowSearch(){
        var evt = new CustomEvent('showSearch');
        window.dispatchEvent(evt);
    }

    render() {
        let {type, label} = this.props.option;
        let image = this.images[type];
        if (image === undefined) {
            image = unknown
        }

        return (<div onClick={() => this.handleShowSearch()}><img className="search-icon" alt="search-icon" src={image}/><span dangerouslySetInnerHTML={{ __html: label }} /></div>);

    }
}
