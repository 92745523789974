import React, {Component} from "react";
import {getTagContent} from "../XMLTools";

export default class NoteItem extends Component {
    render(){
        let {document, columns, item} = this.props;
        let cols;

        switch(document) {
            case "quotation":
                cols = {
                    name: "qi_name",
                    description: "qi_description"
                };break;

            case "orderin":
                cols = {
                    name: "oi_name",
                    description: "oi_description"
                };break;

            case "invoice":
                cols = {
                    name: "ii_name",
                    description: "ii_description"
                };break;

            case "orderout":
                cols = {
                    name: "oi_name",
                    description: "oi_description"
                };break;

            default:
                cols = {}; break;
        }

        let nameText = getTagContent(item, cols["name"]);
        let descriptionText = getTagContent(item, cols["description"]);
        if(getTagContent(item, "hide_portal")==="T"){
            return null;
        }
        return(
            <tbody>
            <tr>
                {columns.map((element, index) => {
                    if (element === "item_name_without_model" || element === "item_name_with_model") {
                        return (<td key={"col_" + index} className="text-semibold">
                            {nameText}
                        </td>);
                    }
                    return <td className="" key={"col_" + index}>&nbsp;</td>;
                })}
            </tr>
            {descriptionText.length > 0 ? (
                <tr>
                    <td className="text-muted text-justify" colSpan={columns.length}>{descriptionText}</td>
                </tr>
            ) : null}
            </tbody>
        )
    }
}