import React, {Component} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ST} from "../../global/SystemText";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";

export default class DocumentRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            document: props.document,
            documentData: props.documentData,
            dataIndex: props.dataIndex
        };
    }

    static getDerivedStateFromProps(newProps, newState) {
        if(newState.document === newProps.document && newState.documentData === newProps.documentData && newState.dataIndex === newProps.dataIndex){
            return null;
        } else {
            newState.document = newProps.document;
            newState.documentData = newProps.documentData;
            newState.dataIndex = newProps.dataIndex;
            return newState
        }
    }

    getDocumentRow(col, index, basename) {
        if (col === "number" || col === "number") {
            return (<td key={"td" + index} className="text-left">
                <Link to={basename + "/" + document + "/" + this.state.documentData.id}>{this.state.documentData[col]}</Link>

            </td>);

        } else if (col === "orderin_list" || col === "invoice_list" || col === "warehouse_list") {
            return (<td key={"td" + index} className="text-right">
                {this.state.documentData[col].map((doc, index) => {
                    return (
                        <Row key={"drow" + index}>
                            <Col key={"dcol" + index}>
                                <Link to={basename + "/" + doc.document + "/" + doc.id}>{doc.number}</Link>
                            </Col>
                        </Row>
                    )
                })}
            </td>)

        } else if (col.includes("price") || col === 'invoice_payment_parts_rest') {
            return (<td className="text-right no-wrap"
                        key={"td" + index}>{this.state.documentData[col] + " " + this.state.documentData["currency_code"].replace(/eur/ig, "€")}</td>);

        } else if (col === "is_paid") {
            return (!this.state.documentData[col] ? <td key={"td" + index} className="text-right"><button>Uhradit</button></td> : <td key={"td" + index}></td>);

        } else if (col === 'state') {
            return (<td className="text-center" key={"td" + index}><span className={"stateBox" + (parseInt(this.state.documentData["state_percent"]) === 100 ? " document-end-state" : " document-progress-state")}>{this.state.documentData[col]}</span></td>)

        } else if (col.includes("date")) {
            return (<td key={"td" + index} className="text-center">{this.state.documentData[col]}</td>)

        } else {
            return (<td className="text-right" key={"td" + index}>{this.state.documentData[col]}</td>);
        }
    }

    render() {
        let {document} = this.state;

        let columns;

        switch (document) {
            case "quotation":
                columns = [
                    "number",
                    "creation_date",
                    "valid_date",
                    "address",
                    "state",
                    "priceinclvat",
                    "priceexclvat",
                    "orderin_list"
                ];
                break;

            case "orderin":
                columns = [
                    "number",
                    "creation_date",
                    "address",
                    "state",
                    "priceinclvat",
                    "priceexclvat",
                    "invoice_list",
                    "warehouse_list"
                ];
                break;

            case "invoice":
                columns = [
                    "number",
                    "creation_date",
                    "address",
                    "variable_symbol",
                    "payment_rest",
                    "priceinclvat",
                    "priceexclvat",
                    "is_paid",
                    "warehouse_list"
                ];
                break;

            case "warehouse":
                columns = [
                    "number",
                    "creation_date",
                    "address",
                    "priceinclvat",
                    "priceexclvat",
                    "invoice_list",
                    "orderin_list"
                ];
                break;
            case "claim":
                columns = [
                    "number",
                    "date",
                    "invoice_number",
                    "ii_name",
                    "state"
                ];
                break;

            default:
                columns = [];
                break;
        }

        if( !Boolean(window.portalSettings.show_prices)){
            let newColumns = [];
            columns.forEach((col, index, array) => {
                if(col.includes("price") || col === 'payment_rest'){
                    return;
                }
                newColumns.push(col);
            });
            columns = newColumns;
        }


        let basename = Session.getItem("basename");
        return (
            <tr>
                {columns.map((col, index) => {
                    if (col === "number" || col === "number") {
                        let proforma = null;
                        if(document === "invoice" && this.state.documentData["invoice_type"] === "1"){
                            proforma = <div>{ST.get("proforma_invoice", "document")}</div>;
                        }
                        return (<td key={"td" + index} className="text-left">
                            <Link to={basename + "/" + document + "/" + this.state.documentData.id}>{proforma}<span style={{color:"#1E88E5"}}>{this.state.documentData[col]}</span></Link>
                            <div>{document==="orderin"?this.state.documentData.customerNumber:""}</div>
                        </td>);

                    } else if (col === "orderin_list" || col === "invoice_list" || col === "warehouse_list") {
                        if (col === 'orderin_list' && !Number(window.portalSettings.orderin_access) > 0) return null;
                        if (col === 'invoice_list' && !Number(window.portalSettings.invoice_access) > 0) return null;
                        if (col === 'warehouse_list' && !Number(window.portalSettings.warehouse_access) > 0) return null;

                        return (<td key={"td" + index} className="text-right">
                            {this.state.documentData[col].map((doc, index) => {
                                return (
                                    <Row key={"drow" + index}>
                                        <Col key={"dcol" + index}>
                                            {(doc.document === 'invoice' ?
                                                (doc.is_paid === 'true' ?
                                                    <Link to={basename + "/" + doc.document + "/" + doc.id}>{doc.number}</Link>
                                                    :
                                                    <Link to={basename + "/" + doc.document + "/" + doc.id}><span style={{color: 'red'}}>{doc.number}</span></Link>)
                                                :
                                                <Link to={basename + "/" + doc.document + "/" + doc.id}>{doc.number}</Link>)
                                            }
                                        </Col>
                                    </Row>
                                )
                            })}
                        </td>)

                    } else if (col.includes("price") || col === 'payment_rest') {
                        return (<td className="text-right no-wrap"
                                     key={"td" + index}>{this.state.documentData[col] + " " + this.state.documentData["currency_code"].replace(/eur/ig, "€")}</td>);

                    } else if (col === "is_paid") {
                        return (!this.state.documentData[col] ? <td key={"td" + index} className="text-right"><Button style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={() => this.props.togglePayment(this.state.dataIndex, this.props.type)}>{ST.get("pay", "global")}</Button></td> : <td key={"td" + index}></td>);

                    } else if (col === 'state') {
                        return (<td className="text-center" key={"td" + index}><span className={"stateBox" + (parseInt(this.state.documentData["state_percent"]) === 100 ? " document-end-state" : " document-progress-state")}>{this.state.documentData[col]}</span></td>)

                    } else if (col.includes("date")) {
                        return (<td key={"td" + index} className="text-center">{this.state.documentData[col]}</td>)

                    } else if (col === 'address'){
                        return(
                            <td key={"td" + index}>
                                <Row>
                                    <Col>
                                        {this.state.documentData.company}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.documentData.contact}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.documentData.address}

                                    </Col>
                                </Row>
                            </td>
                        )
                    } else {
                        return (<td className="text-right" key={"td" + index}>{this.state.documentData[col]}</td>);
                    }
                })}
            </tr>
        );
    }
}