import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {ST} from "../global/SystemText";

import {Session} from "../global/PortalTools";
import * as PT from "../global/PortalTools";

export default class Breadcrumbs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id:props.id,
            data:[],
            type:props.type?props.type:"other",
            loaded:false
        }
    };

    componentDidMount() {
        if(this.state.type==="catalogue" || this.state.type==="product") {
            this.getBreadcrumbs("GET_PORTAL_CATALOGUE_BREADCRUMBS");
        } else if (this.state.type==="dms"){
            this.getBreadcrumbs("GET_PORTAL_DMS_BREADCRUMBS");
        } else {
            this.setState({loaded:true});
        }
    }

    getBreadcrumbs(action){
        //console.log(this.state.id);
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idCatalogue: this.state.id,
                idDMS: this.props.activeId,
                idLanguage: Session.getItem("idLanguage"),
                action: action
            }
        }).then((response) => {
            this.setState({
                loaded: true,
                data:response.data.reverse()

            });

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    render() {
        if (this.state.loaded){
        if(this.state.type==="catalogue") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home", "global")}</Link></li>
                    {this.state.data.map((element, index) => {
                        return (
                            <li key={index}><Link to={Session.getItem("basename") + "/catalogue/" + element.id}>{element.name}</Link></li>
                        )
                    })}
                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="product") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    {this.state.data.map((element, index) => {
                        return (
                            <li key={index}><Link to={Session.getItem("basename") + "/catalogue/" + element.id}>{element.name}</Link></li>
                        )
                    })}
                    <li><Link to={Session.getItem("basename") + "/catalogue/" + this.state.id}>{this.props.catName}</Link></li>
                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="quotation"){
            return(<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/documents/quotation"}>{ST.get("quotations_list","global")}</Link></li>

                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="orderin"){
            return(<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/documents/orderin"}>{ST.get("orderins_list","global")}</Link></li>

                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="invoice"){
            return(<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/documents/invoice"}>{ST.get("invoice_list","global")}</Link></li>

                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="warehouse"){
            return(<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/documents/warehouse"}>{ST.get("delivery_notes_list","global")}</Link></li>

                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }else if(this.state.type==="dms") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    { this.props.active === undefined ?
                        <li className="active">{this.props.default}</li>
                        :
                        <li><Link to={Session.getItem("basename") + "/dms"}>{this.props.default}</Link></li>
                    }
                    {this.state.data.reverse().map((element, index) => {
                        return (
                            <li key={index}><Link to={Session.getItem("basename") + "/dms/" + element.id}>{element.nameParent}</Link></li>
                        )
                    })}
                    { this.props.active === undefined ?
                        null
                        :
                        <li className="active">{this.props.active}</li>
                    }
                </ul>
            </div>)
        }else if(this.state.type==="contact") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/customers/contact"}>{ST.get("people", "global")}</Link></li>

                    <li className="active" dangerouslySetInnerHTML={{__html:this.props.active}} />
                </ul>
            </div>)
        }else if(this.state.type==="company") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/customers/company"}>{ST.get("companies", "global")}</Link></li>

                    <li className="active" dangerouslySetInnerHTML={{__html:this.props.active}}/>
                </ul>
            </div>)
        }else if(this.state.type==="claim") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/invoice/"+this.props.idInvoice}>{this.props.numberInvoice}</Link></li>

                    <li className="active" dangerouslySetInnerHTML={{__html:this.props.active}}/>
                </ul>
            </div>)
        }else if(this.state.type==="wishlist") {
            return (<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>
                    <li><Link to={Session.getItem("basename")+"/wishlists/"}>{ST.get("wishlists_list","document")}</Link></li>
                    <li className="active" dangerouslySetInnerHTML={{__html:this.props.active}}/>
                </ul>
            </div>)
        }else{
            return(<div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><Link to={Session.getItem("basename")}><i className="icon-home2 position-left"></i> {ST.get("home","global")}</Link></li>

                    <li className="active">{this.props.active}</li>
                </ul>
            </div>)
        }
        } else {return (<div/>);}
    }

}