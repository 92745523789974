import React, {Component} from "react";
import {Card} from "react-bootstrap";
import ItemsHead from "./items/ItemsHead";
import ProductItem from "./items/ProductItem";
import NoteItem from "./items/NoteItem";
import SubcountItem from "./items/SubcountItem";
import Summary from "./Summary";
import {getTagContent} from "./XMLTools";

export default class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: JSON.parse(props.columns[props.document.includes("order") ? "order" : props.document]).map(element => element.columnName)
        };

        this.colBlacklist = [
            "item_description", "item_picture"
        ];
    }

    getPrefixByDocument(document){
        switch (document) {
            default:
            case "quotation": return "qi";
            case "orderin": return "oi";
            case "orderout": return "oi";
            case "invoice": return "ii";
            case "warehouse": return "wi";
        }
    }

    render() {
        let {documentXml, document,  settings} = this.props;
        let {columns} = this.state;
        let documentDOM = new DOMParser().parseFromString(documentXml, "text/xml");
        let items;
        let printDiscount=getTagContent(documentDOM, "show_discount");
        if(printDiscount==='F'){
            this.colBlacklist.push("item_discount","item_priceafterdiscount","item_pricediscount","item_price_total");
        }

        if (document === 'warehouse'){
            items = Array.from(documentDOM.getElementsByTagName("item"));
        } else {
            items = Array.from(documentDOM.getElementsByTagName(document + "_item"));
        }

        let filteredColumns = columns.filter((ele) => !this.colBlacklist.includes(ele));

        return (
            <div className="panel border-top-xlg" style={{borderColor: settings.portal_pcolor}}>
                <div className="panel-body">
                    <div className="table-responsive">
                        <table className="table  items-table">
                            <ItemsHead dom={documentDOM} columns={filteredColumns} document={document}/>

                            {items.map((element, index) => {
                                let type = element.getElementsByTagName(this.getPrefixByDocument(document) + "_type")[0].textContent;
                                switch (Number(type)) {
                                    case 0: return (<ProductItem key={"item_" + index} columns={filteredColumns} allColumns={columns} item={element} document={document} settings={settings}/>);
                                    case 2: return (<NoteItem key={"item_" + index} columns={filteredColumns} item={element} document={document}/>);
                                    case 3: return (<SubcountItem key={"item_" + index} columns={filteredColumns} item={element} document={document}/>);
                                    default: return null;
                                }
                            })}
                        </table>
                        <hr/>
                    </div>
                    {settings[document + "_show_summary"] ? (<Summary document={document} dom={documentDOM} settings={settings}/>) : ""}
                </div>
            </div>
        );
    }
}