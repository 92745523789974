import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import avatar from "../images/avatar.png";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Button, Row} from "react-bootstrap";
import DmsTile from "./dms/DmsTile";

export default class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: [],
            expand: true,
            showDropdown: false,
            selected: {}
        }

    }

    componentDidMount() {

        this.getLanguages();
    }


    getLanguages() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                id: this.props.id,
                action: "GET_LANGUAGES"
            }
        })
            .then((response) => {
                //console.log(response.data);
                var selected = response.data[0];
                response.data.forEach(function (element, index) {
                    if (element.selected) {
                        selected = element;
                    }
                });
                this.setState({
                    loaded: true,
                    data: response.data,
                    selected: selected
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    changeLanguage(id) {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: Session.getItem("idContact"),
            idLanguage: id + "",
            action: "CHANGE_LANGUAGE"
        })
            .then((response) => {
                Session.setItem("idLanguage", id);
                window.location.reload(false);
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    toogleDropdown = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        }, () => {
            document.addEventListener('mousedown', this.handleClickOutside)
        })
    }

    handleClickOutside = () => {
        setTimeout(() => {
            this.setState({
                showDropdown: !this.state.showDropdown
            }, () => {
                document.removeEventListener('mousedown', this.handleClickOutside)
            })
        }, 300);
    }

    render() {
        if (this.state.loaded && window.portalSettings.change_lang) {
            return (
                <span>
                    <a className="legitRipple language-flag" style={{color: "white"}}
                       onClick={() => this.toogleDropdown()}>
                        <img style={{marginRight: 0}}
                             src={process.env.PUBLIC_URL + "/assets/images/flags/" + this.state.selected.iso.toLowerCase() + ".png"}
                             className="position-left" alt={this.state.selected.iso}/>
                    </a>
                    {this.state.showDropdown ?
                        <ul className="dropdown-menu" style={{display: "block", minWidth: "auto"}}>
                            {this.state.data.map((element, index) => {
                                return <li key={index}><a onClick={() => this.changeLanguage(element.id)}><img
                                    style={{marginRight: 0}}
                                    src={process.env.PUBLIC_URL + "/assets/images/flags/" + element.iso.toLowerCase() + ".png"}
                                    alt={element.iso}/></a></li>
                            })}


                        </ul>
                        : null}
                </span>
            );
        } else {
            return (<div></div>);
        }
    }
}
