import React, {Component} from "react";
import {ST} from "../../global/SystemText";
import {BubbleLoader} from 'react-css-loaders';
import {Col, Row} from "react-bootstrap";

export default class DocumentListSummary extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.loaded) {
            return (
                <Row>
                    <Col lg={4} className={"pull-right pt-10"} style={{paddingTop: "0px"}}>
                        <div className="table-responsive">
                            <table className="table table-sm summary-finalPrices"
                                   style={{background: window.portalSettings.portal_pcolor + "2e"}}>
                                <tbody>
                                <tr>
                                    <td style={{fontSize: "17px"}}>{ST.get("exclvattotal", "global") + ":"}</td>
                                    <td className="text-right text-bold no-wrap"
                                        style={{fontSize: "20px"}}>{this.props.summaryData.priceExclVat + " " + this.props.summaryData.currencyCode}</td>
                                </tr>
                                <tr>
                                    <td style={{
                                        borderTop: "none",
                                        fontSize: "17px"
                                    }}>{ST.get("vattotal", "global") + ":"}</td>
                                    <td className="text-right text-bold no-wrap" style={{
                                        borderTop: "none",
                                        fontSize: "20px"
                                    }}>{this.props.summaryData.priceInclVat + " " + this.props.summaryData.currencyCode}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return (<Row>
                <Col lg={4} className={"pull-right pt-10"} style={{paddingTop: "0px"}}>
                    <div className="table-responsive">
                        <table className="table table-sm summary-finalPrices"
                               style={{background: window.portalSettings.portal_pcolor + "2e"}}>
                            <tbody>
                            <tr>
                                <td>
                                    <BubbleLoader color={window.portalSettings.portal_pcolor}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </Col>
            </Row>)
        }
    }
}