import React, {Component} from "react";
import {ST} from "../../global/SystemText";
import Breadcrumbs from "../Breadcrumbs";
import {Button, Col, Row} from "react-bootstrap";
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import Map from "../../Map";
import {BubbleLoader} from 'react-css-loaders';
import {Link} from "react-router-dom";
import ContactModal from "./ContactModal";

export default class CompanyDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaded: false
        }
    }

    componentDidMount() {
        this.getCompany();
    }

    getCompany = () => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idDealer: Session.getItem("idDealer"),
                id: this.props.match.params.id,
                action: 'GET_COMPANY_DETAIL'
            }
        })
            .then((response) => {
                //console.log(response.data);
                if(!response.data.name){
                    this.props.history.replace(Session.getItem("basename") + "/customers/company");
                }
                this.setState({
                    data: response.data,
                    loaded: true
                })

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    loginCustomer = (idContact) => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: String(idContact),
            idCompany: String(this.props.match.params.id),
            action: "PORTAL_LOGIN_CUSTOMER"
        })
            .then((response) => {
                let {idContact, idCompany, idLanguage, idDealer, idCurrency, codeCurrency, priceLevel, contactName, companyName, contactGender} = response.data;
                //console.log(response.data);
                if (idContact !== "") {
                    Session.setItem("logged", "true");
                    Session.setItem("idContact", idContact);
                    Session.setItem("idCompany", idCompany);
                    // pokial sa toto prepise, tak portal bude v jazyku zakaznika ak kym sa nevyberie zakaznik s inym jazykom
                    // Session.setItem("idLanguage", idLanguage);
                    Session.setItem("idDealer", idDealer);
                    Session.setItem("idCurrency", idCurrency);
                    Session.setItem("codeCurrency", codeCurrency.replace(/eur/ig, "€"));
                    Session.setItem("priceLevel", priceLevel);
                    Session.setItem("contactName", contactName);
                    Session.setItem("contactGender", contactGender);
                    Session.setItem("companyName", companyName);
                    if (window.cordova) {
                        //2 tyzdne
                        Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
                    } else {
                        Session.setItem("logoutTimer", Date.now() + 30 * 60000);
                    }

                    axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                        params: {
                            idMandant: Session.idMandant,
                            idContact: Session.getItem("idContact"),
                            idCompany: Session.getItem("idCompany"),
                            action: "GET_PORTAL_SETTINGS"
                        }
                    }).then((response) => {
                        window.portalSettings = response.data;
                        document.title=response.data.portal_name;
                        document.documentElement.lang="";
                        //PT.resetBasket();
                        PT.initBasket();

                        this.props.history.replace(Session.getItem("basename") + "/");

                    }).catch((error) => {
                        PT.handleError(error, this.props.history);
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    onContactChange = (data) => {
        this.props.history.push(Session.getItem("basename") + "/contact/" + data.id);
    }

    render(){
        let basename = Session.getItem("basename");
        if(this.state.loaded) {
            let addresses = this.state.data.addresses.slice(1);
            return (
                <div>
                    <div className="panel border-top-xlg" style={{borderColor: this.props.settings.portal_pcolor}}>
                        <Breadcrumbs type={"company"} active={this.state.data.name}/>
                        <div className="panel-heading">
                            <h1 className="panel-title" dangerouslySetInnerHTML={{__html:this.state.data.name}}/>
                        </div>

                        <div className="panel-body">
                            <Row className="pt-15">
                                <Col md={3}>
                                    <Row>
                                        <Col md={6}>
                                            <h4 className="no-margin-top"><u>{ST.get("headquarter_address","global")}:</u></h4>
                                            <p className="text-size-large">
                                                {this.state.data.street}<br/>
                                                {this.state.data.zip} {this.state.data.city}<br/>
                                                {this.state.data.country_name}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-10"}>
                                        <Col md={4}><strong>{ST.get("ico","company")}:</strong></Col>
                                        <Col md={8}>{this.state.data.ico}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("dic","company")}:</strong></Col>
                                        <Col md={8}>{this.state.data.dic}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("icdph","company")}:</strong></Col>
                                        <Col md={8}>{this.state.data.icdph}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("phone","mandant")}:</strong></Col>
                                        <Col md={8}>
                                            {this.state.data.phones.map((val, index) => {
                                                return <a href={"tel://" + val.replace(/ /gi, "")} key={"phone" + index}>{index > 0 ? (<br/>) : ""}{val}</a>
                                            })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("email","person")}:</strong></Col>
                                        <Col md={8}>
                                            {this.state.data.emails.map((val, index) => {
                                                return <a href={"mailto://" + val} key={"email" + index}>{index > 0 ? (<br/>) : ""}{val}</a>
                                            })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("web","global")}:</strong></Col>
                                        <Col md={8}>
                                            {this.state.data.webs.map((val, index) => {
                                                return <a href={val} key={"web" + index}>{index > 0 ? (<br/>) : ""}{val}</a>
                                            })}
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={3}>

                                        <h4 className="no-margin-top"><u>{ST.get("mandants", "global")}:</u></h4>
                                        {this.state.data.addresses.map((value, index) => {
                                            return (<p className="text-size-large" key={"address" + index}>
                                                {value.name}, {value.street}, {value.zip} {value.city}, {value.country_name}
                                            </p>)
                                        })}
                                </Col>

                                <Col md={6}>
                                    {this.state.data.city === "" || this.state.data.city === undefined || this.state.data.city === null
                                        ? null
                                        : (<Map address={{city: this.state.data.city, street: this.state.data.street, zip: this.state.data.zip, lat: this.state.data.lat, lng: this.state.data.lng}}
                                                addresses={addresses}/>)}

                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} style={{overflowY: "auto"}}>
                                    <table className="table table-lg table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("name", "person")}</th>
                                            <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("mobile", "person")}</th>
                                            <th className={"text-left"} style={{minWidth: "100px"}}>{ST.get("email", "person")}</th>
                                            <th className={"text-left"} style={{minWidth: "100px"}}>{""}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.contacts.map((val, index) => {
                                                return <tr key={"contact" + index}>
                                                    <td><Link to={basename + "/contact/" + val.id}>{val.name}</Link></td>
                                                    <td>{val.mobile}</td>
                                                    <td>{val.email}</td>
                                                    <td><Button className={"btn-primary"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this.loginCustomer.bind(this, val.id)}>{ST.get("login_as_customer", "portal")}</Button></td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ContactModal history={this.props.history} person={{company_id: this.props.match.params.id}} callback={this.onContactChange} title={ST.get("add_person", "global")}>
                                        <Button className={"mt-10"} bsStyle={"success"}>{ST.get("add_person", "global")}</Button>
                                    </ContactModal>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            );
        }else{
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>)
        }
    }
}