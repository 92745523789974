import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {getTagContent} from "./XMLTools";

export default class Summary extends Component {

    render() {
        let {dom, document} = this.props;

        let listPairs, summaryPairs;
        switch (document) {
            case "quotation":
                listPairs = [
                    {value: "quotation_total_price", name: "quotation_sum_final_price_text"},
                    {value: "quotation_sum_discount_items", name: "quotation_sum_discount_items_text"},
                    {value: "quotation_sum_work", name: "quotation_sum_work_text"},
                    {value: "quotation_sum_discount", name: "quotation_sum_discount_text"},
                    {value: "quotation_sum_after_discount", name: "quotation_sum_after_discount_text"},
                    {value: "quotation_sum_transport", name: "quotation_sum_transport_text"},
                    {value: "quotation_sum_custom1", name: "quotation_sum_custom_text1"},
                    {value: "quotation_sum_custom2", name: "quotation_sum_custom_text2"},
                    {value: "quotation_sum_custom3", name: "quotation_sum_custom_text3"}
                ];
                summaryPairs = {
                    titleBase: "quotation_report_vatbase",
                    titlePercent: "quotation_report_vatrate",
                    titleVat: "quotation_report_vat",
                    titleTotal: "quotation_report_vattotal",
                    valueBase: "price_excl",
                    valuePercent: "percent",
                    valueVat: "price_vat",
                    valueTotal: "price_incl",
                    titleFinal: "quotation_title_price_excl_vat",
                    valueFinal: "quotation_sum_global_excl",
                    titleFinalIncl: "quotation_title_price_incl_vat",
                    valueFinalIncl: "quotation_sum_global_incl",
                    print_summary:"quotation_print_summary"
                };
                break;

            case "warehouse":
                listPairs = [
                    {value: "warehouse_sum_final_price", name: "warehouse_sum_final_price_text2"},
                    {value: "warehouse_sum_discount_items", name: "warehouse_sum_discount_items_text"},
                    {value: "warehouse_sum_work", name: "warehouse_sum_work_text"},
                    {value: "warehouse_sum_discount", name: "warehouse_sum_discount_text"},
                    {value: "warehouse_sum_after_discount", name: "warehouse_sum_after_discount_text"},
                    {value: "warehouse_sum_transport", name: "warehouse_sum_transport_text"},
                    {value: "warehouse_sum_custom1", name: "warehouse_sum_custom_text1"},
                    {value: "warehouse_sum_custom2", name: "warehouse_sum_custom_text2"},
                    {value: "warehouse_sum_custom3", name: "warehouse_sum_custom_text3"}
                ];
                summaryPairs = {
                    titleBase: "warehouse_report_vatbase",
                    titlePercent: "warehouse_report_vatrate",
                    titleVat: "warehouse_report_vat",
                    titleTotal: "warehouse_report_vattotal",
                    valueBase: "price_excl",
                    valuePercent: "percent",
                    valueVat: "price_vat",
                    valueTotal: "price_incl",
                    titleFinal: "warehouse_title_price_excl_vat",
                    valueFinal: "warehouse_sum_global_excl",
                    titleFinalIncl: "warehouse_title_price_incl_vat",
                    valueFinalIncl: "warehouse_sum_global_incl",
                    print_summary:"warehouse_print_summary"
                };
                break;

            case "orderin":
                listPairs = [
                    {value: "orderin_total_price", name: "orderin_sum_final_price_text"},
                    {value: "orderin_sum_discount_items", name: "orderin_sum_discount_items_text"},
                    {value: "orderin_sum_work", name: "orderin_sum_work_text"},
                    {value: "orderin_sum_discount", name: "orderin_sum_discount_text"},
                    {value: "orderin_sum_after_discount", name: "orderin_sum_after_discount_text"},
                    {value: "orderin_sum_transport", name: "orderin_sum_transport_text"},
                    {value: "orderin_sum_custom1", name: "orderin_sum_custom_text1"},
                    {value: "orderin_sum_custom2", name: "orderin_sum_custom_text2"},
                    {value: "orderin_sum_custom3", name: "orderin_sum_custom_text3"}
                ];
                summaryPairs = {
                    titleBase: "orderin_report_vatbase",
                    titlePercent: "orderin_report_vatrate",
                    titleVat: "orderin_report_vat",
                    titleTotal: "orderin_report_vattotal",
                    valueBase: "price_excl",
                    valuePercent: "percent",
                    valueVat: "price_vat",
                    valueTotal: "price_incl",
                    titleFinal: "orderin_title_price_excl_vat",
                    valueFinal: "orderin_sum_global_excl",
                    titleFinalIncl: "orderin_title_price_incl_vat",
                    valueFinalIncl: "orderin_sum_global_incl",
                    print_summary:"orderin_print_summary"
                };
                break;

            case "invoice":
                listPairs = [
                    {value: "invoice_total_price", name: "invoice_sum_final_price_text"},
                    //{value: "invoice_sum_work", name: "invoice_sum_work_text"},
                    {value: "invoice_sum_discount", name: "invoice_sum_discount_text"},
                    {value: "invoice_sum_after_discount", name: "invoice_sum_after_discount_text"},
                    {value: "invoice_sum_transport", name: "invoice_sum_transport_text"},
                    {value: "invoice_sum_custom1", name: "invoice_sum_custom_text1"},
                    {value: "invoice_sum_custom2", name: "invoice_sum_custom_text2"},
                    {value: "invoice_sum_custom3", name: "invoice_sum_custom_text3"}
                ];
                if(String(getTagContent(dom, "invoice_sum_discount_print"))==="T") {
                    listPairs.push({value: "invoice_sum_discount_items", name:"invoice_sum_discount_items_text"});
                }


                summaryPairs = {
                    titleBase: "invoice_report_vatbase",
                    titlePercent: "invoice_report_vatrate",
                    titleVat: "invoice_report_vat",
                    titleTotal: "invoice_report_vattotal",
                    valueBase: "price_excl",
                    valuePercent: "percent",
                    valueVat: "price_vat",
                    valueTotal: "price_incl",
                    titleFinal: "titlePriceTotal",
                    valueFinal: "invoice_sum_global_excl",
                    titleFinalIncl: "invoice_report_vattotal",
                    valueFinalIncl: "invoice_sum_global_incl",
                    print_summary:"invoice_print_summary"
                };
                break;

            case "orderout":
                listPairs = [
                    {value: "orderout_total_price", name: "orderout_sum_final_price_text"},
                    {value: "orderout_sum_discount_items", name: "orderout_sum_discount_items_text"},
                    {value: "orderout_sum_work", name: "orderout_sum_work_text"},
                    {value: "orderout_sum_discount", name: "orderout_sum_discount_text"},
                    {value: "orderout_sum_after_discount", name: "orderout_sum_after_discount_text"},
                    {value: "orderout_sum_transport", name: "orderout_sum_transport_text"},
                    {value: "orderout_sum_custom1", name: "orderout_sum_custom_text1"},
                    {value: "orderout_sum_custom2", name: "orderout_sum_custom_text2"},
                    {value: "orderout_sum_custom3", name: "orderout_sum_custom_text3"}
                ];
                summaryPairs = {
                    titleBase: "orderout_report_vatbase",
                    titlePercent: "orderout_report_vatrate",
                    titleVat: "orderout_report_vat",
                    titleTotal: "orderout_report_vattotal",
                    valueBase: "price_excl",
                    valuePercent: "percent",
                    valueVat: "price_vat",
                    valueTotal: "price_incl",
                    titleFinal: "orderout_title_price_excl_vat",
                    valueFinal: "orderout_sum_global_excl",
                    titleFinalIncl: "orderout_title_price_incl_vat",
                    valueFinalIncl: "orderout_sum_global_incl"
                };
                break;

            default:
                listPairs = [];
                summaryPairs = {
                    titleBase: "",
                    titlePercent: "",
                    titleVat: "",
                    titleTotal: "",
                    valueBase: "",
                    valuePercent: "",
                    valueVat: "",
                    valueTotal: "",
                    titleFinal: "",
                    valueFinal: "",
                    titleFinalIncl: "",
                    valueFinalIncl: ""
                };
                break;
        }

        let vats;
        if (document === 'warehouse' || document === 'invoice') {
            vats = Array.from(dom.getElementsByTagName("vat_summary"));
        } else {
            if(dom.getElementsByTagName("vat_summary")[0]!==undefined) {
                vats = Array.from(dom.getElementsByTagName("vat_summary")[0].getElementsByTagName("item"));
            }else{
                vats=[];
            }
        }

        return (
            <Row className="row">

                <Col lg={4} style={{paddingTop: "0px"}}>
                    <div className="table-responsive">
                        <table className="table table-sm summary-list">
                            <tbody>

                            {listPairs
                                .filter(element => String(getTagContent(dom, element.value)).trim().length > 0)
                                .map((element, index) => {
                                    let value, name;

                                    value = getTagContent(dom, element.value).replace(/eur/ig, "€");
                                    name = getTagContent(dom, element.name);

                                    return (
                                        <tr key={"summary_" + index}>
                                            <td>{name}</td>
                                            <td className="text-semibold text-right">{value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Col>


                <Col lg={6} className={"pull-right"} style={{paddingTop: "0px"}}>
                    <div className="table-responsive">
                        <table className="table table-sm">
                            <thead>
                            <tr>
                                <th className="text-right">{getTagContent(dom, summaryPairs["titleBase"])}</th>
                                <th className="text-right">{getTagContent(dom, summaryPairs["titlePercent"])}</th>
                                <th className="text-right">{getTagContent(dom, summaryPairs["titleVat"])}</th>
                                <th className="text-right">{getTagContent(dom, summaryPairs["titleTotal"])}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vats.map((element, index) => {
                                return (
                                    <tr key={"vats_" + index}>
                                        <td className="text-right no-wrap">{getTagContent(element, summaryPairs["valueBase"]).replace(/eur/ig, "€")}</td>
                                        <td className="text-right no-wrap">{getTagContent(element, summaryPairs["valuePercent"])}</td>
                                        <td className="text-right no-wrap">{getTagContent(element, summaryPairs["valueVat"]).replace(/eur/ig, "€")}</td>
                                        <td className="text-right no-wrap">{getTagContent(element, summaryPairs["valueTotal"]).replace(/eur/ig, "€")}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div className="table-responsive">
                            <table className="table table-sm summary-finalPrices" style={{background: this.props.settings.portal_pcolor + "2e"}}>
                                <tbody>
                                <tr>
                                    <td style={{fontSize: "17px"}}>{getTagContent(dom, summaryPairs["titleFinal"])+(getTagContent(dom, summaryPairs["titleFinal"]).trim().endsWith(":")?"":":")}</td>
                                    <td className="text-right text-bold no-wrap" style={{fontSize: "20px"}}>{getTagContent(dom, summaryPairs["valueFinal"]).replace(/eur/ig, "€")}</td>
                                </tr>
                                <tr>
                                    <td style={{borderTop: "none", fontSize: "17px"}}>{getTagContent(dom, summaryPairs["titleFinalIncl"])+(getTagContent(dom, summaryPairs["titleFinalIncl"]).trim().endsWith(":")?"":":")}</td>
                                    <td className="text-right text-bold no-wrap" style={{borderTop: "none", fontSize: "20px"}}>{getTagContent(dom, summaryPairs["valueFinalIncl"]).replace(/eur/ig, "€")}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}