import React, {Component} from 'react';
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import Breadcrumbs from "../Breadcrumbs";
import {getTagContent} from "../../document/XMLTools";
import {ST} from "../../global/SystemText";
import {Col, Row,Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import Lightbox from "react-image-lightbox";
import ProductQORow from "../../components/ProductQORow";
import {BubbleLoader} from "react-css-loaders";

export default class Wishlist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: this.props.match.params.id,
            data: {},
            openImage:false,
            openImageIndex:0
        };
    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                idCurrency: Session.getItem("idCurrency"),
                priceLevel: Session.getItem("priceLevel"),
                id: this.state.id,
                action: "GET_WISHLIST",

            }
        })
            .then((response) => {
                console.log(response.data);
                this.setState({data: response.data, loaded: true})
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    addToBasket(){
        this.state.data.items.map(async (element,index)=> {
            console.log(element);

            let typeIndex=0;
            element.product.types.forEach((elementInner,index)=>{
                console.log(elementInner.id+ "="+element.idType)
                if(elementInner.id===Number(element.idType)){
                    typeIndex=index;
                }
            });
            //return;
            await PT.addToBasket(element.amount, element.product.id, element.idType, element.product, element.product.types[typeIndex]);
        });
    }

    render() {
        if (this.state.loaded) {
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs type={"wishlist"} active={this.state.data.number}/>
                    <div className="panel-heading">
                        <h1 className="panel-title text-semibold">
                            {ST.get("wishlist", "document")}
                            <span className="pull-right"> {this.state.data.number}</span>
                        </h1>
                    </div>

                    <div className="panel-body ">
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("creation_date", "document")}: </Col>
                                    <Col xs={6} sm={8}>{this.state.data.date}</Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("name", "person")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.name}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("city", "person")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.city}</b></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("mobile", "person")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.phone}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("email", "person")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.email}</b></Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className={"table-responsive"}>
                                    <table className={"table table-lg"}>
                                        <thead>
                                        <tr>
                                        <th className="text-left">{ST.get("product_name", "product")}</th>
                                        <th className="text-left">{ST.get("product_order_our", "product")}</th>
                                        <th className="text-left">{ST.get("product_availability", "product")}</th>
                                        {Boolean(window.portalSettings.show_prices) ?
                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                                :
                                                <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                            : null }
                                        {Boolean(window.portalSettings.show_prices) ?
                                            Boolean(window.portalSettings.main_price_excl_vat) ?
                                                <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                                :
                                                <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                            : null }
                                        <th className="text-right">&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.items.map((element,index)=>{
                                            return <ProductQORow key={index} data={element.product} selectedType={element.idType} amount={element.amount} />
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Button onClick={()=>this.addToBasket()} style={{float:"right",background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}><i className="icon icon-cart5"/> {ST.get("add_to_basket","portal")}</Button>
                            </Col>
                        </Row>

                    </div>
                </div>

            )
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}




