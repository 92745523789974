import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';

import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";


export default class Properties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            idProduct:props.idProduct,
            idType:props.idType,
            data: {}
        }
    }

    componentDidMount() {
        this.getProperties();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loaded) {
            this.getProperties();
        }
    }
    static getDerivedStateFromProps(newProps, newState) {
        if (newState.idProduct === newProps.idProduct&&newState.idType === newProps.idType) {
            return null;
        } else {
            newState.idProduct = newProps.idProduct;
            newState.idType = newProps.idType;
            newState.loaded = false;
            return newState;
        }
    }

    getProperties() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
                idMandant: Session.idMandant,
                idProduct: this.state.idProduct,
                idType: this.state.idType,
                idLanguage: Session.getItem("idLanguage"),
                action: "GET_PORTAL_PRODUCT_PROPERTIES"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if (this.state.loaded) {
            return (
                <div>
                    {this.state.data.length>0?
                        <div>
                            <h3>Vlastnosti</h3>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    {this.state.data.map((element, index) => (
                                        <tr key={index}>
                                            <td>{element.name}</td>
                                            <td>{element.value}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :null}

                </div>
            );
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
