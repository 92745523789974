import React, {Component} from 'react';
import Map from "./Map";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {ST} from "./global/SystemText";
import * as PT from "./global/PortalTools";
import {Session} from "./global/PortalTools";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            loaded:false,
            data:{}
        }
    }

    componentDidMount() {
        this.getMandant();

    }

    getMandant(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idLanguage:this.props.settings.idLanguage===undefined || this.props.settings.idLanguage===0 ?Session.getItem("idLanguage"):this.props.settings.idLanguage,
                action: "GET_PORTAL_CMS_CONTACT"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if(this.state.loaded) {
            return (
                <div>
                    <div className="panel border-top-xlg" style={{borderColor: this.props.settings.portal_pcolor}}>
                        <div className="panel-heading">
                            <h1 className="panel-title">
                                {this.state.data.mandant.mandant_name}
                            </h1>
                        </div>

                        <div className="panel-body">
                            <Row className="pt-15">
                                <Col md={4}>
                                    <h4 className="no-margin-top"><u>{ST.get("mandant_address3","mandant")}:</u></h4>
                                    <p className="pl-10 text-size-large">
                                        {this.state.data.mandant.mandant_street2}<br/>
                                        {this.state.data.mandant.mandant_zip2} {this.state.data.mandant.mandant_city2}<br/>
                                        {this.state.data.mandant.oCountry2.country_name}
                                    </p>
                                    <h4><u>{this.state.data.opening_hours.name}</u></h4>
                                    <div className="pl-10" dangerouslySetInnerHTML={{__html:this.state.data.opening_hours.text}}/>
                                </Col>

                                <Col md={8}>
                                    <Map address={{city: this.state.data.mandant.mandant_city2, street: this.state.data.mandant.mandant_street2, zip: this.state.data.mandant.mandant_zip2}}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="panel">
                        <div className="panel-body">
                            <Row>
                                <Col md={4}>
                                    <h4 className="no-margin-top"><u>{ST.get("invoice_data","mandant")}:</u></h4>
                                    <p className="pl-10 text-size-large">
                                        {this.state.data.mandant.mandant_street1}<br/>
                                        {this.state.data.mandant.mandant_zip1} {this.state.data.mandant.mandant_city1}<br/>
                                        {this.state.data.mandant.oCountry1.country_name}
                                    </p>
                                    <p className="pl-10">
                                        {ST.get("phone","mandant")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_phone}</span><br/>
                                        {ST.get("fax","mandant")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_fax}</span><br/>
                                        {ST.get("email","person")}:&nbsp;<span className="text-semibold"><a href={"mailto:"+this.state.data.mandant.mandant_email}>{this.state.data.mandant.mandant_email}</a></span><br/>
                                        {ST.get("web_page","mandant")}:&nbsp;<span className="text-semibold"><a href={"http://"+this.state.data.mandant.mandant_web} target="_blank" rel="noopener noreferrer">{this.state.data.mandant.mandant_web}</a></span>
                                    </p>
                                    <p className="pl-10">
                                        {ST.get("ico","company")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_ico}</span><br/>
                                        {ST.get("dic","company")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_dic}</span><br/>
                                        {ST.get("icdph","company")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_icdph}</span><br/>
                                    </p>
                                    <p className="pl-10">
                                        {ST.get("register","mandant")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_register}</span><br/>
                                        {ST.get("register_number","mandant")}:&nbsp;<span className="text-semibold">{this.state.data.mandant.mandant_register_number}</span><br/>
                                    </p>
                                </Col>

                                <Col md={8}>
                                    <Map address={{city: this.state.data.mandant.mandant_city1, street: this.state.data.mandant.mandant_street1, zip: this.state.data.mandant.mandant_zip1}}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            );
        }else{
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>)
        }
    }
}
