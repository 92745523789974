import axios from "axios/index";
import {Session} from "../global/PortalTools";

export class ST {

    static continue = true;
    static done = false;

    static delay = ms => {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    };

    static clean() {
        window.localStorage.removeItem("systemText");
    }

    static get = (code, type) => {
        let result;
        let systemText = window.localStorage.getItem("systemText");

        if (type === undefined) {
            type = 'global';
        }
        systemText = JSON.parse(systemText);
        if (systemText === null || systemText.global === undefined) {
            return "**" + code + "**";
        }
        result = systemText.custom[code];
        if (!result) {
            if (systemText[type] !== undefined) {
                result = systemText[type][code];
            }
        }
        if (!result) {
            result = systemText.global[code];
        }
        if (code === '---') {
            return "---";
        }
        return result || "**" + code + "**";

    };

    static init = async () => {
        let systemText = window.localStorage.getItem("systemText");
        if (systemText === null) {
            try {
                console.time("st");
                let token = window.localStorage.getItem("token");
                let endpoint = Session.getItem("endpoint");
                let response = await axios.get(endpoint + 'SystemTextServlet', {
                    params: {
                        token: token,
                        idLanguage: Session.getItem("idLanguage"),
                        langIsoCode: Session.getItem("langIsoCode"),
                        action: "SYSTEM_TEXT_PORTAL"
                    }
                });

                let {data} = response;
                window.localStorage.setItem("systemText", JSON.stringify(data));
                //console.timeEnd("st");
            } catch (err) {
                //TODO osetrit chybu
                //console.log(err);
                console.timeEnd("st");
            }
        }

    }
    ;
}