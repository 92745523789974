import React, {Component} from 'react';
import {Row} from "react-bootstrap";
import ProductTile from "../components/ProductTile";
import axios from "axios";
import {BubbleLoader} from "react-css-loaders";

import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";

export default class ProductAccessory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:props.id,
            loaded: false,
            accessories: []
        }
    }

    componentDidMount() {
        this.getAccessories();
    }

    getAccessories() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idProduct: this.state.id,
                    idCatalogue: "0",
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    action: "GET_PORTAL_PRODUCT_ACCESSORIES"
                }
            })
            .then((response) => {
                this.setState({accessories: response.data, loaded: true});
                if(!response.data.length>0){
                    this.props.isEmpty();
                }
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    render() {

        return (
            <div>
                {this.state.loaded ?
            <Row>
                {this.state.accessories.map((element, index) => {
                    return (<ProductTile data={element} key={"acc" + index}/>);
                })}
            </Row>
                    :<BubbleLoader color={window.portalSettings.portal_pcolor}/>}
            </div>
        )
    }
}
