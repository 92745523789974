import React from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import SearchResult from "./SearchResult";
import axios from "axios";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";

export default  class ProductAutocomplete extends React.Component {
    state = {
        allowNew: false,
        isLoading: false,
        multiple: false,
        options: [],
    };

    _handleSearch = (query) => {
        this.setState({isLoading: true});
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
            idMandant: Session.idMandant,
                idCompany:Session.getItem("idCompany"),
                idContact:Session.getItem("idContact"),
                idLanguage:Session.getItem("idLanguage"),
                idCurrency:Session.getItem("idCurrency"),
                query: query,
                action: "GET_PRODUCT_AUTOCOMPLETE"}})
            .then((response) => {
                this.setState({
                    isLoading: false,
                    options:response.data.options,
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })


    };
    _handleSelect = (selected) => {
        //console.log(selected);
       this.props.onSelect(selected[0].id,selected[0].idType);
    };

    render() {
        return (
            <div className={"product-autocomplete"}>
                <AsyncTypeahead
                    {...this.state}
                    id={"Search"}
                    minLength={3}
                    className={"search-dropdown"}
                    filterBy={(option, props) => {
                       return true;
                    }}
                    clearButton
                    searchText={ST.get("searching","global")}
                    emptyLabel={ST.get("no_results","global")}
                    placeholder={ST.get("product_order_our", "product")}
                    promptText={ST.get("product_order_our", "product")}
                    labelKey="searchString"
                    highlightOnlyResult={true}
                    onSearch={this._handleSearch}
                    onChange={this._handleSelect}
                    defaultInputValue ={this.props.value}
                    renderMenuItemChildren={(option, props) => (
                        <SearchResult  option={option} id={option.id} key={option.id} />
                    )}
                />

            </div>
        );
    }
}