import React, {Component} from 'react';

import {Session} from "../../global/PortalTools";
import {Link} from "react-router-dom";
import {ST} from "../../global/SystemText";
import ejpg from '../../images/extensions/jpg.png';
import epdf from '../../images/extensions/pdf.png';
import exls from '../../images/extensions/xls.png';
import edoc from '../../images/extensions/doc.png';
import e3ds from '../../images/extensions/3ds.png';
import eaac from '../../images/extensions/aac.png';
import eai from '../../images/extensions/ai.png';
import eavi from '../../images/extensions/avi.png';
import ebmp from '../../images/extensions/bmp.png';
import ecad from '../../images/extensions/cad.png';
import ecdr from '../../images/extensions/cdr.png';
import ecss from '../../images/extensions/css.png';
import edat from '../../images/extensions/dat.png';
import edll from '../../images/extensions/dll.png';
import edmg from '../../images/extensions/dmg.png';
import eeps from '../../images/extensions/eps.png';
import efla from '../../images/extensions/fla.png';
import eflv from '../../images/extensions/flv.png';
import egif from '../../images/extensions/gif.png';
import ehtml from '../../images/extensions/html.png';
import eindd from '../../images/extensions/indd.png';
import eiso from '../../images/extensions/iso.png';
import ejs from '../../images/extensions/js.png';
import emidi from '../../images/extensions/midi.png';
import emov from '../../images/extensions/mov.png';
import emp3 from '../../images/extensions/mp3.png';
import empg from '../../images/extensions/mpg.png';
import ephp from '../../images/extensions/php.png';
import epng from '../../images/extensions/png.png';
import eppt from '../../images/extensions/ppt.png';
import eps from '../../images/extensions/ps.png';
import epsd from '../../images/extensions/psd.png';
import eraw from '../../images/extensions/raw.png';
import esql from '../../images/extensions/sql.png';
import esvg from '../../images/extensions/svg.png';
import etif from '../../images/extensions/tif.png';
import etxt from '../../images/extensions/txt.png';
import ewmv from '../../images/extensions/wmv.png';
import exml from '../../images/extensions/xml.png';
import ezip from '../../images/extensions/zip.png';
import efolder from '../../images/extensions/folder.png';
import unknownPicture from "../../images/unknown.jpg";
import returnArrow from "../../images/return.png";

export default class DmsRow extends Component {

    constructor(props) {
        super(props);

    };

    getIconByExtension(type, extension) {
        if (type === '0') {
            return efolder;
        }
        if (extension !== undefined) {
            if (extension.toLowerCase() === 'jpg') {
                return ejpg;
            } else if (extension.toLowerCase() === 'pdf') {
                return epdf;
            } else if (extension.toLowerCase() === 'xls') {
                return exls;
            } else if (extension.toLowerCase() === 'xlsx') {
                return exls;
            } else if (extension.toLowerCase() === '3ds') {
                return e3ds;
            } else if (extension.toLowerCase() === 'aac') {
                return eaac;
            } else if (extension.toLowerCase() === 'ai') {
                return eai;
            } else if (extension.toLowerCase() === 'avi') {
                return eavi;
            } else if (extension.toLowerCase() === 'bmp') {
                return ebmp;
            } else if (extension.toLowerCase() === 'cad') {
                return ecad;
            } else if (extension.toLowerCase() === 'cdr') {
                return ecdr;
            } else if (extension.toLowerCase() === 'css') {
                return ecss;
            } else if (extension.toLowerCase() === 'dat') {
                return edat;
            } else if (extension.toLowerCase() === 'dll') {
                return edll;
            } else if (extension.toLowerCase() === 'dmg') {
                return edmg;
            } else if (extension.toLowerCase() === 'eps') {
                return eeps;
            } else if (extension.toLowerCase() === 'fla') {
                return efla;
            } else if (extension.toLowerCase() === 'flv') {
                return eflv;
            } else if (extension.toLowerCase() === 'gif') {
                return egif;
            } else if (extension.toLowerCase() === 'html') {
                return ehtml;
            } else if (extension.toLowerCase() === 'indd') {
                return eindd;
            } else if (extension.toLowerCase() === 'iso') {
                return eiso;
            } else if (extension.toLowerCase() === 'js') {
                return ejs;
            } else if (extension.toLowerCase() === 'midi') {
                return emidi;
            } else if (extension.toLowerCase() === 'mov') {
                return emov;
            } else if (extension.toLowerCase() === 'mp3') {
                return emp3;
            } else if (extension.toLowerCase() === 'mpg') {
                return empg;
            } else if (extension.toLowerCase() === 'php') {
                return ephp;
            } else if (extension.toLowerCase() === 'png') {
                return epng;
            } else if (extension.toLowerCase() === 'ppt') {
                return eppt;
            } else if (extension.toLowerCase() === 'ps') {
                return eps;
            } else if (extension.toLowerCase() === 'psd') {
                return epsd;
            } else if (extension.toLowerCase() === 'raw') {
                return eraw;
            } else if (extension.toLowerCase() === 'sql') {
                return esql;
            } else if (extension.toLowerCase() === 'svg') {
                return esvg;
            } else if (extension.toLowerCase() === 'tif') {
                return etif;
            } else if (extension.toLowerCase() === 'txt') {
                return etxt;
            } else if (extension.toLowerCase() === 'wmv') {
                return ewmv;
            } else if (extension.toLowerCase() === 'xml') {
                return exml;
            } else if (extension.toLowerCase() === 'zip') {
                return ezip;
            } else if (extension.toLowerCase() === 'docx' || extension.toLowerCase() === 'doc') {
                return edoc;
            } else return unknownPicture;
        }
    }

    render() {
        let {name, id, linkPartner, linkId, linkName, type, extension, url, description, partners} = this.props.data;
        if (this.props.parent !== undefined && this.props.parent !== "undefined" ) {
            let returnlink = "/dms/" + this.props.parent;
            if (this.props.parent === "0" || this.props.parent === 0){
                returnlink = "/dms"
            }
            return (
                    <tr>
                        <td><Link to={Session.getItem("basename") + returnlink}><img src={returnArrow} className="img-responsive" style={{minHeight:"50px"}} alt={name}/></Link></td>
                        <td><Link className={"dms-table-link"} to={Session.getItem("basename") + returnlink}>{ST.get("go_back","global")}</Link></td>
                        <td/>
                        <td/>
                    </tr>
            )
        } else {
            return (
                type==="1"?
                    <tr>

                        <td><a target="_blank" rel="noopener noreferrer" href={Session.getItem("endpoint") + url}><img src={this.getIconByExtension(type,extension)} className="img-responsive" style={{minHeight:"50px"}} alt={name}/></a></td>
                        <td><a className={"dms-table-link"} target="_blank" rel="noopener noreferrer" href={Session.getItem("endpoint") + url}>{name}</a></td>
                        <td>{description}</td>
                        <td>
                        {partners!==undefined && partners.map((element, index) => {
                            return (<div key={"partner"+index}><Link to={Session.getItem("basename") + "/" + element.linkPartner + "/" + element.linkId}>{element.linkName}</Link></div>)
                        })}
                        </td>
                    </tr>
                    :
                    <tr>

                        <td><Link to={Session.getItem("basename")+"/dms/"+id}><img src={this.getIconByExtension(type,extension)} className="img-responsive" style={{minHeight:"50px"}} alt={name}/></Link></td>
                        <td ><Link className={"dms-table-link"} to={Session.getItem("basename")+"/dms/"+id}>{name}</Link></td>
                        <td>{description}</td>
                        <td><Link to={Session.getItem("basename") + "/" + linkPartner + "/" + linkId}>{linkName}</Link></td>
                    </tr>

            )
        }

    }

}