import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import {ST} from "../../global/SystemText";
import {Button} from "react-bootstrap";
import axios from "axios";

export default class CustomerRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.type,
            data: props.data,
            dataIndex: props.dataIndex
        };
    }

    static getDerivedStateFromProps(newProps, newState) {
        if(newState.type === newProps.type && newState.data === newProps.data && newState.dataIndex === newProps.dataIndex){
            return null;
        } else {
            newState.type = newProps.type;
            newState.data = newProps.data;
            newState.dataIndex = newProps.dataIndex;
            return newState
        }
    }

    loginCustomer = (idContact,idCompany) => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: String(idContact),
            idCompany: String(idCompany),
            action: "PORTAL_LOGIN_CUSTOMER"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }})
            .then((response) => {
                let {idContact, idCompany, idLanguage, idDealer, idCurrency, codeCurrency, priceLevel, contactName, companyName, contactGender} = response.data;
                //console.log(response.data);
                if (idContact !== "") {
                    Session.setItem("logged", "true");
                    Session.setItem("idContact", idContact);
                    Session.setItem("idCompany", idCompany);
                    // pokial sa toto prepise, tak portal bude v jazyku zakaznika ak kym sa nevyberie zakaznik s inym jazykom
                    // Session.setItem("idLanguage", idLanguage);
                    Session.setItem("idDealer", idDealer);
                    Session.setItem("idCurrency", idCurrency);
                    Session.setItem("codeCurrency", codeCurrency.replace(/eur/ig, "€"));
                    Session.setItem("priceLevel", priceLevel);
                    Session.setItem("contactName", contactName);
                    Session.setItem("contactGender", contactGender);
                    Session.setItem("companyName", companyName);
                    if (window.cordova) {
                        //2 tyzdne
                        Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
                    } else {
                        Session.setItem("logoutTimer", Date.now() + 30 * 60000);
                    }

                    axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                        params: {
                            idMandant: Session.idMandant,
                            idContact: Session.getItem("idContact"),
                            idCompany: Session.getItem("idCompany"),
                            action: "GET_PORTAL_SETTINGS"
                        }
                    }).then((response) => {
                        window.portalSettings = response.data;
                        document.title=response.data.portal_name;
                        document.documentElement.lang="";
                        //PT.resetBasket();
                        PT.initBasket();

                        this.props.history.replace(Session.getItem("basename") + "/");

                    }).catch((error) => {
                        PT.handleError(error, this.props.history);
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    }


    render() {
        let {type, dataIndex} = this.state;
        let basename = Session.getItem("basename");
        let secondContent = "";
        if(type === "company") {
            secondContent = this.state.data['ico'];
        } else if(type === "contact") {
            secondContent = (<Link to={basename + "/company/" + this.state.data.company_id}><span style={{color:"#1E88E5"}} dangerouslySetInnerHTML={{__html:this.state.data['company_name']}}/></Link>);
        }

        return (
            <tr>
                <td className="text-left"><Link to={basename + "/" + type + "/" + this.state.data.id}><b dangerouslySetInnerHTML={{__html:this.state.data['name']}}/></Link></td>
                <td className="text-left">{secondContent}</td>
                <td className="text-left">{this.state.data['street']}</td>
                <td className="text-left">{this.state.data['city'] + " " + this.state.data['zip']}</td>
                <td className="text-left">{this.state.data['nameCountry']}</td>
                <td className="text-left">
                    {type=="contact"?
                        <Button className={"btn-primary"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this.loginCustomer.bind(this, this.state.data['id'], this.state.data['company_id'])}>{ST.get("login_as_customer", "portal")}</Button>
                    :
                    null
                    }
                </td>
            </tr>
        );
    }
}