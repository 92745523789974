import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {Link} from "react-router-dom";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";

export default class CatalogueMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            expand: true,
            active:0
        }

    }

    componentDidMount() {
        if(!PT.canShowToDealer()){
            return;
        }
        this.getCatalogueMenu();
    }

    handleMobileLeftMenu(){
        var evt = new CustomEvent('handleMobileLeftMenu');
        window.dispatchEvent(evt);
    }

    getCatalogueMenu() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idCurrency:Session.getItem("idCurrency"),
                idLanguage:Session.getItem("idLanguage"),
                action: "GET_PORTAL_CATALOGUE_MENU"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data.catalogues

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    render() {

        if (this.state.loaded) {
            let producers=<li onClick={()=>this.handleMobileLeftMenu()}>
                <Link style={{color:window.portalSettings.portal_pcolor}}  to={Session.getItem("basename")+"/producers"}>{ST.get("producers","global")}</Link>
            </li>;
            return (

                <div className="sidebar-category">
                    <div onClick={()=>this.setState({expand:!this.state.expand})} className="category-title" style={{background:window.portalSettings.portal_pcolor+"0d"}}>
                        <span>{ST.get("products","global")}</span>
                        <ul className="icons-list">
                            <li><i onClick={()=>this.setState({expand:!this.state.expand})} className={"icon icon-chevron-"+ (this.state.expand?"up":"down") +" cursor-pointer"}/></li>
                        </ul>
                    </div>
                    <ul className="navigation navigation-alt navigation-accordion navigation-sm no-padding-top" style={!this.state.expand ? {display:"none"} : {}}>
                        <li onClick={()=>this.handleMobileLeftMenu()}>
                            <Link style={{color:window.portalSettings.portal_pcolor}}  to={Session.getItem("basename")+"/myproducts"}>{ST.get("my_products","global")}</Link>
                        </li>
                        {window.portalSettings.producers_allow?producers:null}
                        {this.state.data.map((element, index) => {
                            return(
                        <li key={index} onClick={()=>{this.setState({active:element.id});this.handleMobileLeftMenu()}}>
                            <Link className={element.subs.length>0?"has-ul":""} to={Session.getItem("basename")+"/catalogue/"+element.id}>{element.name}</Link>
                            {element.subs.length>0?
                            <ul style={this.state.active===element.id?{}:{display:"none"}}>
                                {element.subs.map((element2, index) => {
                                    return(<li onClick={()=>this.handleMobileLeftMenu()} key={index}>
                                        <Link to={Session.getItem("basename")+"/catalogue/"+element2.id}>{element2.name}</Link>
                                    </li>)
                                })}

                            </ul>
                            :null}
                        </li>)
                        })}


                    </ul>


                </div>
            );
        } else {
            if(PT.canShowToDealer()){
                return (<div className="sidebar-category"><BubbleLoader color={window.portalSettings.portal_pcolor}/></div>);
            } else {
                return null;
            }
        }
    }
}
