import React, {Component} from 'react';
import Lightbox from "react-image-lightbox";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Session} from "../global/PortalTools";


export default class ProductPictures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:props.id,
            pictures: props.pictures,
            selected: props.pictures.length > 0 ? 0 : -1,
            openImage: false,
            openImageIndex: 0
        }
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.id === newProps.id ) {
            return null;
        } else {
            newState.id = newProps.id;
            newState.pictures = newProps.pictures;
            newState.selected = newProps.pictures.length > 0 ? 0 : -1;
            newState.openImage = false;
            newState.openImageIndex =0;
            return newState;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    openImage(index) {
        this.setState({openImage: true, openImageIndex: index});
    };

    render() {
        let {pictures, selected, openImage, openImageIndex} = this.state;
        let endpoint = Session.getItem("endpoint");
        if (selected < 0) {
            return (<div/>);
        } else {
            return (
                <div>
                    <Carousel key={this.state.id} className={"product-carousel"} showIndicators={false} useKeyboardArrows  emulateTouch showArrows selectedItem={openImageIndex} showStatus={false} onClickItem={(index)=>this.openImage(index)}>
                        {pictures.map((element, index) => {
                            return (
                        <div key={index}>
                            <img src={endpoint + element} alt={"product_picture"} />
                        </div>)})}

                    </Carousel>
                    
                    {openImage && (
                        <Lightbox
                            mainSrc={Session.getItem("endpoint") + pictures[openImageIndex]}
                            nextSrc={pictures[(openImageIndex + 1) % pictures.length]}
                            prevSrc={pictures[(openImageIndex + pictures.length - 1) % pictures.length]}
                            onCloseRequest={() => this.setState({openImage: false})}
                            onMovePrevRequest={() =>
                                this.setState({
                                    openImageIndex: (openImageIndex + pictures.length - 1) % pictures.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    openImageIndex: (openImageIndex + 1) % pictures.length,
                                })
                            }
                        />)}
                </div>
            );
        }
    }
}