import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from "react-css-loaders";
import {Button, Row} from "react-bootstrap";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import Breadcrumbs from "../Breadcrumbs";
import {ST} from "../../global/SystemText";
import DmsTile from "./DmsTile";
import DmsRow from "./DmsRow";

export default class Dms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:props.match.params.id===undefined ?0:props.match.params.id ,
            loaded: false,
            data: {},
            limit: "",
            sort: "",
            page: "",
            gridType: "grid"
        };

    }
    static getDerivedStateFromProps(newProps, newState) {
        let newId=newProps.match.params.id===undefined ? 0 : newProps.match.params.id;
        if (newState.id === newId) {
            return null;
        } else {
            newState.id = newId;
            newState.loaded = false;
            return newState;
        }
    }
    componentDidMount() {
        this.getDms();
        let defaultGridType = "grid";
        if (Session.getItem("gridType") !== undefined) {
            defaultGridType = Session.getItem("gridTypeDMS")
        }
        this.setState({
            gridType: defaultGridType
        });
    }
    componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getDms();
        }
    }

    getDms() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany:Session.getItem("idCompany"),
                idContact:Session.getItem("idContact"),
                idLanguage:Session.getItem("idLanguage"),
                idCurrency:Session.getItem("idCurrency"),
                priceLevel:Session.getItem("priceLevel"),
                idParent:this.state.id,
                limit: this.state.limit,
                sort: this.state.sort,
                page: this.state.page,
                action: "GET_PORTAL_DMS"
            }
        })
            .then((response) => {
                this.setState({
                    data: response.data,
                    loaded: true
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }


    render() {
        if (this.state.loaded) {
            let tiles = this.state.data.items;
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs default={ST.get("for_download","global")} type={"dms"} active={this.state.data.nameParent} activeId={this.state.id} id={this.state.data.parent}/>
                    <div className="panel-heading no-padding-bottom">
                        <h1 className="panel-title">{this.state.data.nameParent === undefined ? ST.get("for_download","global") : this.state.data.nameParent}</h1>
                    </div>
                    <div className="panel-body">
                        <div className="product-toolbox">
                            <div className="row">
                                <div className="col-lg-4 ">
                                    <div className="btn-group">
                                        <Button onClick={() => {
                                            this.setState({gridType: "list"});
                                            Session.setItem("gridTypeDMS", "list")
                                        }} size={"sm"} variant={"light"} style={this.state.gridType==="list"?{background:window.portalSettings.portal_pcolor,color:PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}:{}}><i className="icon icon-list"/></Button>
                                        <Button onClick={() => {
                                            this.setState({gridType: "grid"});
                                            Session.setItem("gridTypeDMS", "grid")
                                        }} size={"sm"} variant={"light"} style={this.state.gridType==="grid"?{background:window.portalSettings.portal_pcolor,color:PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}:{}}><i className="icon icon-grid"/></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.gridType === "grid" ?
                            <Row>
                                {this.state.id!=="0" && this.state.id!=="undefined" && this.state.id!==0 ?
                                    <DmsTile data={""} parent={this.state.data.parent} key={"dmsTile" + this.state.data.parent}/>
                                    : null
                                }
                                {tiles.map((element, index) => {
                                    return (<DmsTile data={element} key={"dmsTile" + index}/>);
                                })}
                            </Row>
                            :
                            <div className="table-responsive">
                                <table className="table table-lg">
                                    <thead>
                                    <tr>
                                        <th width="80px" className="text-left"/>
                                        <th width="25%" className="text-left">{ST.get("title","global")}</th>
                                        <th width="50%" className="text-left">{ST.get("description","global")}</th>
                                        <th className="text-left">{ST.get("attached","global")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.id!=="0" && this.state.id!=="undefined" && this.state.id!==0 ?
                                        <DmsRow data={""} parent={this.state.data.parent} key={"dmsTile" + this.state.data.parent}/>
                                        : null
                                    }
                                    {tiles.map((element, index) => {
                                        return (<DmsRow data={element} key={"dmsTile" + index}/>);
                                    })}

                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            )

        } else {
            return (<BubbleLoader/>);
        }
    }
}