import React, {Component} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../global/SystemText";
import axios from "axios";
import {Link} from "react-router-dom";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import DashboardProducts from "../components/DashboardProducts";
import Notice from "./Notice";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loaded: false,
            fields: {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: ""
            },
            showChangePassword: false,
            showConfirmChangePassword: false,
            passwordChangeStatus: ""
        }
    }

    componentDidMount() {
        document.getElementById("favicon").href =Session.getItem("endpoint") + "PictureServlet?id=" + Session.idMandant + "&action=GET_MANDANT_LOGO";
        axios.get(Session.getItem("endpoint") + 'PortalServlet',
            {
                params: {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    idCurrency: Session.getItem("idCurrency"),
                    action: "GET_ACCOUNT_DASHBOARD"
                }
            })
            .then((response) => {
                let {data} = response;
                this.setState({data, loaded: true});
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    changeInput = (value, event) => {
        let {fields, error} = this.state;
        fields[value] = event.target.value;
        this.setState({fields});
        if (error) {
            this.setState({error: false});
        }
    };

    changePassword() {
        if ((this.state.fields.oldPassword !== "" && this.state.fields.newPassword !== "" && this.state.fields.confirmNewPassword !== "")) {
            if ((this.state.fields.newPassword === this.state.fields.confirmNewPassword)) {
                if (this.state.fields.newPassword.length >= 4) {
                    axios.post(Session.getItem("endpoint") + 'PortalServlet', {
                        idMandant: Session.idMandant,
                        idContact: Session.getItem("idContact"),
                        oldPass: this.state.fields.oldPassword,
                        newPass: this.state.fields.newPassword,
                        action: "CHANGE_PASSWORD"
                    },{headers: {'Content-type': 'application/json; charset=utf-8',
                        }})
                        .then((response) => {
                            let {pwdconfirmation} = response.data;
                            if (pwdconfirmation === "true") {
                                this.setState({passwordChangeStatus: ST.get("global_change_password_successfull", "global")});
                            } else {
                                this.setState({passwordChangeStatus: ST.get("global_change_password_not_successfull_wrong_old_password", "global")});
                            }
                            this.toggleChange("showConfirmChangePassword");
                        })
                        .catch((error) => {
                            //console.log(error);
                        });
                    this.toggleChange("showChangePassword");

                } else {
                    this.setState({passwordChangeStatus: ST.get("global_change_password_not_successfull_new_password_is_too_short", "global")});
                    this.toggleChange("showConfirmChangePassword");
                }
            } else {
                this.setState({passwordChangeStatus: ST.get("global_change_password_not_successfull_new_passwords_not_equals", "global")});
                this.toggleChange("showConfirmChangePassword");
            }
        } else {
            this.setState({passwordChangeStatus: ST.get("global_mandatory_not_complete", "global")});
            this.toggleChange("showConfirmChangePassword");
        }
    }

    toggleChange(modalName) {
        let object = {};
        object[modalName] = !this.state[modalName];
        this.setState(object)
    }

    render() {
        let settings = window.portalSettings;
        let basename = Session.getItem("basename");
        let {data, loaded} = this.state;
        let {quotations, orderins, unpaidAll, unpaidPastDue} = data;
        return (<div>
            <Notice/>
            <Row>


                {PT.canShowToDealer()?
                <Col sm={12} lg={12}>
                    <div className="panel dashboard-tile product-menu">
                        <div className="panel-heading">
                            <h6 className="panel-title">{ST.get("products", "global")}</h6>
                        </div>
                        <div className="panel-body">
                            <DashboardProducts/>
                        </div>
                    </div>
                </Col>:null}
                {Number(window.portalSettings.quotation_access) > 0 && PT.canShowToDealer() ?
                    <Col sm={6} lg={6}>
                        <div className="panel dashboard-tile quotation-menu">
                            <div className="panel-heading">
                                <h6 className="panel-title">{ST.get("quotations", "global")}</h6>
                            </div>
                            <div className="panel-body">
                                <ul className="media-list">
                                    {loaded ? quotations.map((quotation, index) => {
                                        return (
                                            <li className="link" key={"quotation" + index}><Link
                                                to={basename + "/quotation/" + quotation.id + "/" + quotation.code}>{quotation.number} - {quotation.date}</Link></li>);
                                    }) : (<div/>)}

                                    <li className="link"><Link to={basename + "/documents/quotation"}>{ST.get("quotations_list", "global")}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col> : null}
                {Number(window.portalSettings.orderin_access) > 0 && PT.canShowToDealer() ?
                    <Col sm={6} lg={6}>
                        <div className="panel dashboard-tile order-menu">
                            <div className="panel-heading">
                                <h6 className="panel-title">{ST.get("orderins", "global")}</h6>
                            </div>
                            <div className="panel-body">
                                <ul className="media-list">
                                    {loaded ? orderins.map((orderin, index) => {
                                        return (
                                            <li className="link" key={"orderin" + index}><Link to={basename + "/orderin/" + orderin.id + "/" + orderin.code}>{orderin.number} - {orderin.date}</Link>
                                            </li>);
                                    }) : (<div/>)}
                                    {Number(window.portalSettings.warehouse_access) > 0 ?
                                        <li className="link"><Link to={basename + "/undelivered_products"}>{ST.get("undelivered_products", "global")}</Link></li> : null}
                                    <li className="link"><Link to={basename + "/documents/orderin"}>{ST.get("orderins_list", "global")}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    : null}
                {Number(window.portalSettings.invoice_access) > 0 && PT.canShowToDealer() ?
                    <Col sm={6} lg={6}>
                        <div className="panel dashboard-tile invoice-menu">
                            <div className="panel-heading">
                                <h6 className="panel-title">{ST.get("invoices", "global")}</h6>
                            </div>
                            <div className="panel-body">
                                <ul className="media-list">
                                    {loaded && unpaidAll.count > 0 ? (<li className="link"><Link to={basename + "/documents/invoice/1" +
                                    ""}>{ST.get("unpaid_invoices", "global")}: {unpaidAll.count} / {unpaidAll.amount} {unpaidAll.currencyCode}</Link></li>) : null}
                                    {loaded && unpaidPastDue.count > 0 ? (<li className="link"><Link to={basename + "/documents/invoice/2"}><span
                                        style={{color: "red"}}>{ST.get("search_my_invoices", "global")}: {unpaidPastDue.count} / {unpaidPastDue.amount} {unpaidPastDue.currencyCode}</span></Link>
                                    </li>) : null}
                                    <li className="link"><Link to={basename + "/documents/invoice"}>{ST.get("invoice_list", "global")}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col> : null}
                {Number(window.portalSettings.warehouse_access) > 0 && PT.canShowToDealer() ?
                    <Col sm={6} lg={6}>
                        <div className="panel dashboard-tile warehouse-menu">
                            <div className="panel-heading">
                                <h6 className="panel-title">{ST.get("warehouse_issues", "global")}</h6>
                            </div>
                            <div className="panel-body">
                                <ul className="media-list">
                                    <li className="link"><Link to={basename + "/undelivered_products"}>{ST.get("undelivered_products", "global")}</Link></li>
                                    <li className="link"><Link to={basename + "/documents/warehouse/1"}>{ST.get("uninvoiced_delivery_notes", "global")}</Link></li>
                                    <li className="link"><Link to={basename + "/documents/warehouse"}>{ST.get("delivery_notes_list", "global")}</Link></li>

                                </ul>
                            </div>
                        </div>
                    </Col>
                    : null}

                {Session.getItem("dealerLogin") === "true" ?
                    <Col sm={6} lg={6}>
                        <div className="panel dashboard-tile contact-menu">
                            <div className="panel-heading">
                                <h6 className="panel-title">{ST.get("customers", "global")}</h6>
                            </div>
                            <div className="panel-body">
                                <ul className="media-list">
                                    <li className="link"><Link to={basename + "/customers/company"}>{ST.get("companies", "global")}</Link></li>
                                    <li className="link"><Link to={basename + "/customers/contact"}>{ST.get("people", "global")}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    : null}

                <Col sm={6} lg={6}>
                    <div className="panel dashboard-tile other-menu">
                        <div className="panel-heading">
                            <h6 className="panel-title">{ST.get("system", "global")}</h6>
                        </div>
                        <div className="panel-body">
                            <ul className="media-list">
                                {Session.getItem("dealerLogin") !== "true" ? (
                                    <li className="link"><a href="#" onClick={this.toggleChange.bind(this, "showChangePassword")}>{ST.get("change_password", "global")}</a></li>
                                ) : null}
                                <li className="link"><Link to={basename + "/login?logout=true"}>{ST.get("logout", "global")}</Link></li>
                            </ul>
                        </div>
                    </div>
                </Col>


            </Row>

            <div>
                <Modal size="md"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered show={this.state.showChangePassword} onHide={this.toggleChange.bind(this, "showChangePassword")}>
                    <Modal.Header style={{background: settings.portal_pcolor}}>
                        <Modal.Title><label style={{color: (PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}}
                                            className="text-semibold">{ST.get("change_password", "global")}</label></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div className="panel-body ">
                                    <div>
                                        <span className="text-semibold">{ST.get("enter_old_password", "global")}:</span>
                                    </div>
                                    <div className="has-feedback has-feedback-left">
                                        <input type="password" name="oldPassword" id="oldPassword" className="form-control" placeholder={ST.get("enter_old_password", "global")}
                                               onChange={this.changeInput.bind(this, "oldPassword")}/>
                                    </div>
                                    <div className="mt-15">
                                        <span className="text-semibold">{ST.get("enter_new_password", "global")}:</span>
                                    </div>
                                    <div className="has-feedback has-feedback-left">
                                        <input type="password" name="newPassword" id="newPassword" className="form-control" placeholder={ST.get("enter_new_password", "global")}
                                               onChange={this.changeInput.bind(this, "newPassword")}/>
                                    </div>
                                    <div className="mt-15">
                                        <span className="text-semibold">{ST.get("confirm_new_password", "global")}:</span>
                                    </div>
                                    <div className="has-feedback has-feedback-left">
                                        <input type="password" name="confirmNewPassword" id="confirmNewPassword" className="form-control" placeholder={ST.get("confirm_new_password", "global")}
                                               onChange={this.changeInput.bind(this, "confirmNewPassword")}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{background: settings.portal_pcolor, color:(PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}} onClick={this.changePassword.bind(this)} className={"mt-10 btn-default"}>{ST.get("change_password", "global")}</Button>
                        <Button style={{background: settings.portal_pcolor, color:(PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}} onClick={this.toggleChange.bind(this, "showChangePassword")}
                                className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div>
                <Modal size="md"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered show={this.state.showConfirmChangePassword} onHide={this.toggleChange.bind(this, "showConfirmChangePassword")}>
                    <Modal.Header style={{background: settings.portal_pcolor}}>
                        <Modal.Title><label style={{color: (PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}}
                                            className="text-semibold">{ST.get("change_password", "global")}</label></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div className="panel-body ">
                                    <div>
                                        <span className="text-semibold">{this.state.passwordChangeStatus}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{background: settings.portal_pcolor, color:(PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}} onClick={this.toggleChange.bind(this, "showConfirmChangePassword")}
                                className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
                    </Modal.Footer>
                </Modal>
            </div>


        </div>);
    }
}