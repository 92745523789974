import React, {Component} from 'react';
import {Link} from "react-router-dom";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Col, Row, Overlay, Button} from "react-bootstrap";
import {Scrollbars} from 'react-custom-scrollbars';
import {ST} from "../global/SystemText";
import empty_cart from "../images/empty_cart.png";

export default class TopBasket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update: false,
            showBasket: false
        }
    }

    componentDidMount() {
        window.addEventListener('updateBasket', function (e) {
            this.setState({
                update: !this.state.update
            });
        }.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('updateBasket', function () {
        });
    }

    changeInput = async (index, event) => {
        await PT.changeAmountInBasket(index, event.target.value);
    };

    render() {
        let basketContent = "";
        if (window.basket.items.length > 0) {
            basketContent = (
                <React.Fragment>
                    <div className="basket-pop" style={{position: "fixed"}}>
                        <Scrollbars autoHeight autoHeightMax={300}>
                            {window.basket.items.map((element, index) => {
                                return (
                                    <div key={"basketItem"+index} style={{padding: "10px"}}>
                                        <Row key={"row" + index}>
                                            <Col sm={2}>
                                                <img className="img-responsive"
                                                     src={Session.getItem("endpoint") + "/PictureServlet?action=GET_PICTURE_FOR_PRODUCT&id=" + element.id + "&idType=" + element.idType} alt={element.name}/>
                                            </Col>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Link
                                                            to={Session.getItem("basename") + "/product/" + element.id}><b>{element.name}</b></Link>
                                                    </Col>
                                                </Row>
                                                {Boolean(window.portalSettings.show_prices) ? (
                                                    <Row>
                                                        <Col md={6}>
                                                            {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                ST.get("exclvattotal", "global")
                                                                :
                                                                ST.get("vattotal", "global")
                                                            }
                                                        </Col>
                                                        <Col md={6}>
                                                            <b style={{color: window.portalSettings.portal_pcolor}}>
                                                                {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                    (element.price * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')
                                                                    :
                                                                    (element.priceVat * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')}

                                                                    {window.basket.codeCurrency}
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                ) : null }
                                                {Boolean(window.portalSettings.show_prices) ? (
                                                    <Row>
                                                        <Col md={6}>
                                                            {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                ST.get("vattotal", "global")
                                                                :
                                                                ST.get("exclvattotal", "global")
                                                            }
                                                        </Col>
                                                        <Col md={6}>
                                                            {Boolean(window.portalSettings.main_price_excl_vat) ?
                                                                (element.priceVat * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')
                                                                :
                                                                (element.price * element.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')}

                                                                {window.basket.codeCurrency}
                                                        </Col>
                                                    </Row>
                                                ) : null }
                                            </Col>
                                            <Col sm={2}>
                                                <input min={1} className="form-control" disabled={element.lockAmount}
                                                       type={"number"}
                                                       onChange={this.changeInput.bind(this, index)}
                                                       value={element.amount}/>
                                            </Col>
                                            <Col sm={2}>
                                                <Button onClick={async () => await PT.removeFromBasket(index)} variant={"light"}
                                                        size={"sm"}><i className="icon icon-bin2"/></Button>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </Scrollbars>
                        <div style={{float: "right"}}>
                            <Link to={Session.getItem("basename") + "/basket"} className="btn btn-primary "
                                  style={{background: window.portalSettings.portal_pcolor}}>{ST.get("go_to_basket", "global")}</Link>
                        </div>
                    </div>
                </React.Fragment>);
        } else {
            basketContent = (
                <React.Fragment>
                    <div className="basket-pop" style={{position: "fixed"}}>
                        <div className="panel-body" style={{
                            background: window.portalSettings.portal_pcolor + "4e",
                            fontSize: "17px",
                            fontWeight: "500"
                        }}>
                            <Row>
                                <Col md={2}>
                                    <img src={empty_cart} className="img-responsive" style={{width: "80px"}}
                                         alt={ST.get("basket_is_empty","global")}/>
                                </Col>
                                <Col md={10} style={{padding: "20px"}}>
                                    {ST.get("basket_is_empty", "global")}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </React.Fragment>);
        }

        return (
            <div className={"top-basket"} onMouseEnter={() => (PT.isTouchDevice() ? this.setState({showBasket: false}) : this.setState({showBasket: true}))}
                 onMouseLeave={() => this.setState({showBasket: false})}>

                <Link to={Session.getItem("basename") + "/basket"}
                      style={{color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>
                    <i className="icon icon-cart position-left"/>
                    {Boolean(window.portalSettings.show_prices) ?

                        Boolean(window.portalSettings.main_price_excl_vat) ?
                            window.basket.totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ') + ' ' + window.basket.codeCurrency
                            :
                            window.basket.totalPriceVat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ') + ' ' + window.basket.codeCurrency
                        : ST.get("basket", "global")}
                </Link>
                <Overlay trigger={['hover', 'focus']}
                         show={(PT.isTouchDevice() ? false : this.state.showBasket)}
                         placement="bottom"
                >{basketContent}
                </Overlay>
            </div>
        );
    }
}
