import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {BubbleLoader} from 'react-css-loaders';
import axios from "axios";
import ItemDetails from "./ItemDetails";
import {getTagContent} from "../XMLTools";
import {ST} from "../../global/SystemText";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import {Link} from "react-router-dom";
import ClaimButton from "../../B2B/documents/ClaimButton";

const maxDescriptionHeight = 162;
export default class ProductItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            openImage: false,
            more: false,
            data: {},
            link:"",
            showDescription: false,
            showDescriptionToggle: false
        }

    }

    componentDidMount() {
        this.getProductLink();
        if (this.itemDescription !== undefined) {
            const height = this.itemDescription.clientHeight;

            if (height >= maxDescriptionHeight) {
                this.setState({showDescriptionToggle: true});
            }
        }
    }

    getPrefixByDocument(document) {
        switch (document) {
            default:
            case "quotation":
                return "qi";
            case "orderin":
                return "oi";
            case "orderout":
                return "oi";
            case "invoice":
                return "ii";
            case "warehouse":
                return "wi";
        }
    }

    toggleMore() {
        if (this.state.loaded) {
            this.setState({
                more: !this.state.more
            })
        } else {
            this.getItemDetails();
        }
    }

    getItemDetails() {
        let columnPairs = this.getColumnPairs(this.props.document);
        let id = getTagContent(this.props.item, columnPairs["item_id"]);
        let table = getTagContent(this.props.item, columnPairs["item_is_variant"]) === 'T' || getTagContent(this.props.item, columnPairs["item_is_accessory"]) === 'T' ? this.getPrefixByDocument(this.props.document) + "v" : this.getPrefixByDocument(this.props.document);
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idItem: id,
                table: table,
                typeDocument: this.props.document,
                action: "GET_PORTAL_ITEM_DETAIL"
            }
        })
            .then((response) => {
                this.setState({
                    data: response.data,
                    loaded: true,
                    more: true,


                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }
    getProductLink(){
        let columnPairs = this.getColumnPairs(this.props.document);
        let id = getTagContent(this.props.item, columnPairs["item_id"]);
        let table = getTagContent(this.props.item, columnPairs["item_is_variant"]) === 'T' || getTagContent(this.props.item, columnPairs["item_is_accessory"]) === 'T' ? this.getPrefixByDocument(this.props.document) + "v" : this.getPrefixByDocument(this.props.document);

        if(Number(Session.getItem("portal_type"))>1 && Session.getItem("logged") === "true"){
            axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    idCompany: Session.getItem("idCompany"),
                    idContact: Session.getItem("idContact"),
                    idItem: id,
                    table: table,
                    typeDocument: this.props.document,
                    action: "GET_PORTAL_ITEM_CLICKABLE"
                }
            })
                .then((response) => {
                    if(response.data.id===""||response.data.id===0||response.data.id==="0") {
                        this.setState({link:""});
                    }else{
                        this.setState({link:Session.getItem("basename") + "/product/" + response.data.id});

                    }
                })
                .catch((error) => {
                    PT.handleError(error, this.props.history);
                })

        }
    }
    getColumnPairs(document) {
        let columnPairs;
        switch (document) {
            case "quotation":
                columnPairs = {
                    item_number: "qi_number",
                    item_id: "qi_id",
                    item_name_without_model: "qi_name_without_model",
                    item_amount: "qi_amount",
                    item_country_of_origin: "qi_producer_country",
                    item_delivery_date: "qi_delivery",
                    item_description: "qi_description",
                    item_discount: "qi_discount",
                    item_model: "qi_product_model",
                    item_name_with_model: "qi_name",
                    item_order_our: "qi_internal_order_our",
                    item_picture: "qi_picture",
                    item_price: "qi_price",
                    item_priceafterdiscount: "qi_price_one",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "qi_final_price",
                    item_price_incl_vat: "qi_final_price_vat",
                    item_price_total: "qi_total_price",
                    item_producer: "qi_producer_name",
                    item_vat: "qi_vat",
                    item_warranty: "qi_warranty",
                    item_weight: "qi_weight",
                    item_has_variants: "qi_has_variants",
                    item_is_variant: "qi_is_variant",
                    item_is_accessory: "qi_is_accessory"
                };
                break;

            case "orderin":
                columnPairs = {
                    item_number: "oi_number",
                    item_id: "oi_id",
                    item_name_without_model: "oi_name_without_model",
                    item_amount: "oi_amount",
                    item_rest_deliver: "restDeliver",
                    item_country_of_origin: "oi_producer_country",
                    item_delivery_date: "oi_delivery",
                    item_description: "oi_description",
                    item_discount: "oi_discount",
                    item_model: "oi_product_model",
                    item_name_with_model: "oi_name",
                    item_order_our: "oi_internal_order_our",
                    item_picture: "oi_picture",
                    item_price: "oi_price",
                    item_priceafterdiscount: "oi_price_one",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "oi_final_price",
                    item_price_incl_vat: "oi_final_price_vat",
                    item_price_total: "oi_total_price",
                    item_producer: "oi_producer_name",
                    item_vat: "oi_vat",
                    item_warranty: "oi_warranty",
                    item_weight: "oi_weight",
                    item_has_variants: "oi_has_variants",
                    item_is_variant: "oi_is_variant",
                    item_is_accessory: "oi_is_accessory"
                };
                break;

            case "invoice":
                columnPairs = {
                    item_number: "ii_number",
                    item_id: "ii_id",
                    item_name_without_model: "ii_name_without_model",
                    item_amount: "ii_amount",
                    item_country_of_origin: "ii_producer_country",
                    item_delivery_date: "ii_delivery",
                    item_description: "ii_description",
                    item_discount: "ii_discount",
                    item_model: "ii_product_model",
                    item_name_with_model: "ii_name",
                    item_order_our: "product_order_our",
                    item_picture: "none", //nema obrazok
                    item_price: "ii_price",
                    item_priceafterdiscount: "ii_price_mj_after_discount",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "ii_final_price",
                    item_price_incl_vat: "ii_final_price_inclvat",
                    item_price_total: "ii_total_price",
                    item_producer: "ii_producer_name",
                    item_vat: "ii_vat",
                    item_warranty: "ii_warranty",
                    item_weight: "ii_weight",
                    item_has_variants: "ii_has_variants",
                    item_is_variant: "ii_is_variant",
                    item_is_accessory: "ii_is_accessory"
                };
                break;

            case "orderout":
                columnPairs = {
                    item_number: "oi_number",
                    item_id: "oi_id",
                    item_name_without_model: "oi_name_without_model",
                    item_amount: "oi_amount",
                    item_country_of_origin: "oi_producer_country",
                    item_delivery_date: "oi_delivery",
                    item_description: "oi_description",
                    item_discount: "oi_discount",
                    item_model: "oi_product_model",
                    item_name_with_model: "oi_name",
                    item_order_our: "product_order_our",
                    item_picture: "oi_picture",
                    item_price: "oi_price",
                    item_priceafterdiscount: "oi_price_mj_after_discount",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "oi_final_price",
                    item_price_incl_vat: "oi_final_price_inclvat",
                    item_price_total: "oi_total_price",
                    item_producer: "oi_producer_name",
                    item_vat: "oi_vat",
                    item_warranty: "oi_warranty",
                    item_weight: "oi_weight",
                    item_has_variants: "oi_has_variants",
                    item_is_variant: "oi_is_variant",
                    item_is_accessory: "oi_is_accessory"
                };
                break;

            case "warehouse":
                columnPairs = {
                    item_number: "wi_number",
                    item_id: "wi_id",
                    item_name_without_model: "wi_name_without_model",
                    item_amount: "wi_amount",
                    item_country_of_origin: "wi_producer_country",
                    item_delivery_date: "wi_delivery",
                    item_description: "wi_description",
                    item_discount: "wi_discount",
                    item_model: "wi_product_model",
                    item_name_with_model: "wi_name",
                    item_order_our: "wi_order_our",
                    item_picture: "wi_picture",
                    item_price: "wi_price",
                    item_priceafterdiscount: "wi_price_mj_after_discount",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "wi_final_price",
                    item_price_incl_vat: "wi_final_price_inclvat",
                    item_price_total: "totalPriceSell",
                    item_producer: "wi_producer_name",
                    item_vat: "wi_vat",
                    item_warranty: "wi_warranty",
                    item_weight: "wi_weight",
                    item_has_variants: "wi_has_variants",
                    item_is_variant: "wi_is_variant",
                    item_is_accessory: "wi_is_accessory"
                };
                break;

            default:
                columnPairs = {};
                break;
        }
        return columnPairs;
    }

    render() {

        let {columns, allColumns, document, item, settings} = this.props;
        let columnPairs = this.getColumnPairs(document);
        let print_description = allColumns.includes("item_description");
        let print_picture = allColumns.includes("item_picture") && item.getElementsByTagName(columnPairs["item_picture"]).length > 0;

        let images = [];
        if (getTagContent(item, columnPairs["item_picture"]) !== "") {
            images.push(getTagContent(item, columnPairs["item_picture"], (value) => {
                if(Session.getItem("endpoint").includes("http")){
                    return String(value).replace("http://localhost:8080", "https://" + Session.instance + ".offeris.com");
                }
            }));
        }
        return (
            <tbody className={getTagContent(item, columnPairs["item_is_variant"]) === 'T' || getTagContent(item, columnPairs["item_is_accessory"]) === 'T' ? "dotted" : ""}>
            <tr>
                {columns.map((element, index) => {
                    if (element === "item_name_without_model" || element === "item_name_with_model") {
                        if(this.state.link===""){
                            return (<td key={"col_" + index} className={"text-semibold"}>
                                {getTagContent(item, columnPairs[element])}
                            </td>);
                        }else {
                            return (<td key={"col_" + index} className={"text-semibold"}>
                                <Link to={this.state.link}>{getTagContent(item, columnPairs[element])}</Link>
                            </td>);
                        }
                    }
                    if (element === "item_vat") {
                        return (<td className="text-right" key={"col_" + index}>
                            {getTagContent(item, columnPairs[element], (value) => {
                                return value.replace(/,00/g, "") + "\u00a0%"
                            })}
                        </td>);
                    }
                    if (element === "item_price_incl_vat") {
                        return (<td key={"col_" + index} className="text-semibold text-right">
                            {getTagContent(item, columnPairs[element], (value) => {
                                return value.replace(/ /ig, '\u00a0') + "\u00a0" + getTagContent(item, "currencyCode").replace(/eur/ig, "€")
                            })}
                        </td>);
                    }
                    if (String(element).includes("price")) {
                        return (<td key={"col_" + index} className="text-right">
                            {getTagContent(item, columnPairs[element], (value) => {
                                return value.replace(/ /ig, '\u00a0') + "\u00a0" + getTagContent(item, "currencyCode").replace(/eur/ig, "€")
                            })}
                        </td>);
                    }
                    if (String(element).includes("amount")) {
                        return (<td className="text-right" key={"col_" + index}>{getTagContent(item, columnPairs[element], (value) => {
                            return value.replace(/ /ig, '\u00a0')
                        })}</td>);
                    }
                    if (String(element).includes("item_rest_deliver")) {
                        return (<td className="text-right" key={"col_" + index}>{getTagContent(item, columnPairs[element], (value) => {
                            return value.replace(/ /ig, '\u00a0')
                        })}</td>);
                    }
                    if (String(element).includes("discount")) {
                        return (<td className="text-right" key={"col_" + index}>{getTagContent(item, columnPairs[element], (value) => {
                            return value.replace(/ /ig, '\u00a0')
                        })}</td>);
                    }
                    if (String(element).includes("delivery")) {
                        return (<td className="text-right" key={"col_" + index}>{getTagContent(item, columnPairs[element], (value) => {
                            return value.replace(/ /ig, '\u00a0')
                        })}</td>);
                    }

                    return <td className={(getTagContent(item, columnPairs["item_is_variant"]) === 'T' || getTagContent(item, columnPairs["item_is_accessory"]) === 'T') && index === 0 ? "pl-20" : ""}
                               key={"col_" + index}>{getTagContent(item, columnPairs[element])}</td>;
                })}
                {document == "invoice" && window.portalSettings.allow_claim?
                    <td key={"head-claim"}><ClaimButton history={this.props.history} id={getTagContent(item, columnPairs["item_id"])} name={getTagContent(item, columnPairs["item_name_without_model"])}/></td>
                    :null}
            </tr>
            {print_description || print_picture ?
                (<tr>
                    <td colSpan={columns.length}>
                        <Row>
                            {print_picture ? (
                                <Col sm={2}>
                                    <img className="cursor-pointer img-responsive" onClick={() => this.setState({openImage: true})} src={getTagContent(item, columnPairs["item_picture"], (value) => {
                                        if(!Session.getItem("endpoint").includes("localhost")){
                                            return String(value).replace("http://localhost:8080", "https://" + Session.instance + ".offeris.com");
                                        }
                                    })}
                                         alt={"product_picture"}/>
                                    {this.state.openImage && (
                                        <Lightbox
                                            mainSrc={images[0]}
                                            onCloseRequest={() => this.setState({openImage: false})}
                                        />
                                    )}
                                </Col>

                            ) : null}

                            {print_description ? (
                                <Col className="valign-top text-justify" sm={10}>
                                    <div ref={input => this.itemDescription = input} style={this.state.showDescription ? {} : {maxHeight: maxDescriptionHeight + "px"}}
                                         className="text-muted overflow-hidden" dangerouslySetInnerHTML={{__html: getTagContent(item, columnPairs["item_description"])}}/>
                                    {this.state.showDescriptionToggle ?
                                        <div className="cursor-pointer text-italic" onClick={() => this.setState({showDescription: !this.state.showDescription})}>{this.state.showDescription ? ST.get("hide_description","global") : ST.get("show_description","global")}</div> : ""}
                                </Col>
                            ) : null}
                        </Row>
                    </td>
                </tr>) : null}
            {settings[document + "_show_item_details"] && getTagContent(item, columnPairs["item_has_variants"]) !== 'T' ?
                <tr>

                    <td colSpan={columns.length}
                        className={getTagContent(item, columnPairs["item_is_variant"]) === 'T' || getTagContent(item, columnPairs["item_is_accessory"]) === 'T' ? "pl-20" : ""}>
                        <h6 style={{background: settings.portal_pcolor + "2e", margin: "0 auto", width: "150px"}} onClick={this.toggleMore.bind(this)} className="center cursor-pointer"><i
                            className={"icon icon-chevron-" + (this.state.more ? "up" : "down") + " position-left"}/>{this.state.more ? ST.get("config_less","global") : ST.get("config_more","global")}</h6>
                        <div className={"collapse" + (this.state.more ? "in" : "")}>
                            <div className="panel-body">
                                {this.state.loaded ?
                                    <ItemDetails data={this.state.data}/>
                                    :
                                    <BubbleLoader color={window.portalSettings.portal_pcolor}/>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
                : null}
            </tbody>
        );
    }
}
