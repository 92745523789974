import React, {Component} from 'react';
import axios from "axios";
import {Button, Col, Row} from "react-bootstrap";
import {BubbleLoader} from "react-css-loaders";
import ProductTile from "../components/ProductTile";
import ProductRow from "../components/ProductRow";
import {Link} from "react-router-dom";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import Breadcrumbs from "./Breadcrumbs";
import {ST} from "../global/SystemText";

export default class Producers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            producers: [],

        };

    }

    componentDidMount() {
        if(!window.portalSettings.producers_allow){
            this.props.history.replace(Session.getItem("basename"));
        }
      this.getProducers()
    }



    getProducers() {

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany:Session.getItem("idCompany"),
                idContact:Session.getItem("idContact"),
                action: "GET_PORTAL_PRODUCERS"
            }
        })
            .then((response) => {
                let result= response.data;
                this.setState({
                    producers: result.producers,
                    loaded: true
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }



    render() {


        if (this.state.loaded) {
            let subs = this.state.producers;
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs type={"other"} active={ST.get("producers","portal")} />
                    <div className="panel-heading no-padding-bottom">
                        <h3 className="panel-title">{ST.get("producers","portal")}</h3>
                    </div>
                    <div className="panel-body">
                        <div className="sub-catalogues">
                            <Row>
                                {subs.map((element, index) => {
                                    return(
                                        <Col key={index} md={3} lg={2}>
                                            <Link to={Session.getItem("basename")+"/producer/"+element.id}>
                                                <div className="panel catalogue">
                                                    <div className="panel-body p-15">
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="thumb thumb-fixed product-thumb" style={{height:"160px"}}>
                                                                    <img className={"img-responsive"} src={Session.getItem("endpoint")+"ProducerLogoServlet?id="+element.id} alt={element.name} />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="center">
                                                                    {element.name}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="clearfix"/>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>)
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
            )

        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}