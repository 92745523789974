import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Dealer from "../document/Dealer";
import CatalogueMenu from "./CatalogueMenu";
import BiznisMenu from "./BiznisMenu";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Scrollbars} from "react-custom-scrollbars";
import axios from "axios";

export default class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            data:{}
        }
    }

    componentDidMount() {
        this.getMenuTexts();
    }

    getMenuTexts(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
                idMandant: Session.idMandant,
                idLanguage:Session.getItem("idLanguage"),
                action: "GET_PORTAL_MENU_TEXT"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    handleMobileLeftMenu() {
        var evt = new CustomEvent('handleMobileLeftMenu');
        window.dispatchEvent(evt);
    }

    render() {
        let basename = Session.getItem("basename");

        return (
            <div className="sidebar sidebar-main sidebar-default z-index999">
                <div className=" sidebar-fix sidebar-content">
                    <Scrollbars style={{padding: "5px"}}>
                        <Link to={basename}><img alt={"logo"}
                                                 src={Session.getItem("endpoint") + "PictureServlet?id=" + Session.idMandant + "&action=GET_MANDANT_LOGO"}
                                                 className="img-responsive hidden-xs p-20"/></Link>

                        <Dealer id={Session.getItem("idDealer")} {...this.props}/>
                        {PT.canShowToDealer() ? (
                            <BiznisMenu/>
                        ) : null}
                        {PT.canShowToDealer() ? (
                            <CatalogueMenu/>
                        ) : null}
                        <div className="sidebar-category">
                            <div className="category-title"
                                 style={{background: window.portalSettings.portal_pcolor + "0d"}}>
                                <span>{ST.get("information", "global")}</span>
                            </div>
                            {this.state.loaded?
                            <ul className="navigation navigation-alt navigation-accordion navigation-sm no-padding-top">
                                <li onClick={() => this.handleMobileLeftMenu()}>
                                    {window.portalSettings.portal_cms_contact ?
                                        <Link to={Session.getItem("basename") + "/contact"}>
                                            {ST.get("contact", "global")}
                                        </Link>
                                        : null}

                                    {window.portalSettings.portal_cms_terms && this.state.data.terms!=="" ?
                                        <Link to={Session.getItem("basename") + "/terms"}>
                                            {this.state.data.terms}
                                        </Link>
                                        : null}
                                    {window.portalSettings.portal_cms_reference && this.state.data.reference!=="" ?
                                        <Link to={Session.getItem("basename") + "/reference"}>
                                            {this.state.data.reference}
                                        </Link>
                                        : null}
                                </li>
                            </ul>:null}
                        </div>
                        <div className="sidebar-category">
                            <div className="category-title"
                                 style={{background: window.portalSettings.portal_pcolor + "0d"}}>
                                <span>{ST.get("myaccount", "global")}</span>
                            </div>
                            <ul className="navigation navigation-alt navigation-accordion navigation-sm no-padding-top">
                                <li onClick={() => this.handleMobileLeftMenu()}>
                                    <Link to={Session.getItem("basename") + "/"}>
                                        {ST.get("dashboard", "global")}
                                    </Link>
                                    <Link to={Session.getItem("basename") + "/login?logout=true"}>
                                        {ST.get("logout", "global")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        );
    }
}
