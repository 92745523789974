import React, { Component } from 'react';
export default class E403 extends Component {


    render() {

        return (
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="text-center content-group">
                                <h1 className="error-title">403</h1>
                                <h5>Forbidden</h5>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3">
                                    <div className="form-group has-feedback">
                                        <input type="text" className="form-control input-lg" placeholder="Search..."/>
                                        <div className="form-control-feedback">
                                            <i className="icon-search4 text-size-large text-muted"/>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <i className="icon-circle-left2 position-left"/> Back to dashboard
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
                
                </div>
                );

    }
}