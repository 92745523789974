import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from "react-css-loaders";
import ProductTile from "../components/ProductTile";
import {Button, Col, Row} from "react-bootstrap";
import ProductRow from "../components/ProductRow";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import Breadcrumbs from "../B2B/Breadcrumbs";
import {ST} from "../global/SystemText";

export default class MyProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            limit: "",
            sort: "",
            page: 1,
            paginationLimit: 5,
            gridType: ""
        };

    }

    componentDidMount() {
        let defaultSort = "myCount DESC";
        let defaultPage = 1;
        let defaultLimit = 24;
        let defaultGridType = "grid";
        if (Session.getItem("gridType") !== undefined) {
            defaultGridType = Session.getItem("gridType")
        }
        this.setState({
            limit: defaultLimit,
            sort: defaultSort,
            page: defaultPage,
            gridType: defaultGridType
        }, this.getMyProducts());


    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getMyProducts();
        }
    }

    getMyProducts() {

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany:Session.getItem("idCompany"),
                idContact:Session.getItem("idContact"),
                idLanguage:Session.getItem("idLanguage"),
                idCurrency:Session.getItem("idCurrency"),
                priceLevel:Session.getItem("priceLevel"),
                limit: this.state.limit,
                sort: this.state.sort,
                page: this.state.page,
                action: "GET_PORTAL_MY_PRODUCTS"
            }
        })
            .then((response) => {
                this.setState({
                    data: response.data.products,
                    countProducts:response.data.countProducts,
                    loaded: true
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    changeLimit(event) {
        this.setState({limit: event.target.value, loaded: false})
    }

    changeSort(event) {
        this.setState({sort: event.target.value, loaded: false})
    }

    render() {

        let pages=Math.ceil(this.state.countProducts/this.state.limit);
        let paginations=[];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }
        if (this.state.loaded) {
            let tiles = this.state.data;
            if (tiles.length > 0) {
                return (
                    <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                        <Breadcrumbs active={ST.get("my_products", "global")}/>
                        <div className="panel-heading no-padding-bottom">
                            <h3 className="panel-title">{ST.get("my_products", "global")}</h3>
                        </div>
                        <div className="panel-body">
                            <div className="product-toolbox">
                                <div className="row">
                                    <div className="col-lg-4 ">
                                        <div className="btn-group">
                                            <Button onClick={() => {
                                                this.setState({gridType: "list"});
                                                Session.setItem("gridType", "list")
                                            }} size={"sm"} variant={"light"} style={this.state.gridType === "list" ? {
                                                background: window.portalSettings.portal_pcolor,
                                                color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                            } : {}}><i className="icon icon-list"/></Button>
                                            <Button onClick={() => {
                                                this.setState({gridType: "grid"});
                                                Session.setItem("gridType", "grid")
                                            }} size={"sm"} variant={"light"} style={this.state.gridType === "grid" ? {
                                                background: window.portalSettings.portal_pcolor,
                                                color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                            } : {}}><i className="icon icon-grid"/></Button>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 text-right">
                                        <div className="input-group inline">
                                            <label className="pt-15">{ST.get("show", "global")}:</label>
                                            <select value={Number(this.state.limit)} className="form-control"
                                                    onChange={this.changeLimit.bind(this)}>
                                                <option value={24}>24</option>
                                                <option value={48}>48</option>
                                            </select>
                                        </div>
                                        <div className="input-group inline">
                                            <label className="pt-15">{ST.get("order_by", "global")}:</label>
                                            <select className="form-control" value={this.state.sort}
                                                    onChange={this.changeSort.bind(this)}>
                                                <option
                                                    value={"myCount DESC"}>{ST.get("bestsellers", "global")}</option>
                                                <option value={"product_name ASC"}>A-Z</option>
                                                <option value={"product_name DESC"}>Z-A</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.gridType === "grid" ?
                                <Row>
                                    {tiles.map((element, index) => {
                                        return (<ProductTile data={element} key={"productTile" + index}/>);
                                    })}
                                </Row>
                                :
                                <div className="table-responsive">
                                    <table className="table table-lg">
                                        <thead>
                                        <tr>
                                            <th className="text-left">{ST.get("product_name", "product")}</th>
                                            <th className="text-left">{ST.get("product_order_our", "product")}</th>
                                            <th className="text-left">{ST.get("catalogue", "global")}</th>
                                            <th className="text-left">{ST.get("product_availability", "product")}</th>
                                            {Boolean(window.portalSettings.show_prices) ?
                                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                                    <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                                    :
                                                    <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                                : null }
                                            {Boolean(window.portalSettings.show_prices) ?
                                                Boolean(window.portalSettings.main_price_excl_vat) ?
                                                    <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                                    :
                                                    <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                                : null }
                                            <th className="text-right">&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tiles.map((element, index) => {
                                            return (<ProductRow data={element} key={"productTile" + index}/>);
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className="text-center content-group-lg pt-20">
                                <ul className="pagination">
                                    <li className={this.state.page === 1 ? "disabled" : ""}><Button
                                        disabled={this.state.page === 1} onClick={() => {
                                        this.setState({page: (this.state.page - 1), loaded: false})
                                    }} size={"sm"} variant={"light"}><i className="icon-arrow-small-left"/></Button>
                                    </li>
                                    {paginations.map((element, index) => {
                                        return (element);
                                    })}
                                    <li className={this.state.page === pages ? "disabled" : ""}>
                                        <Button disabled={this.state.page === pages} onClick={() => {
                                            this.setState({page: (this.state.page + 1), loaded: false})
                                        }} size={"sm"} variant={"light"}><i
                                            className="icon-arrow-small-right"/></Button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }else {
                return (
                    <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                        <Breadcrumbs active={ST.get("my_products", "global")}/>
                        <div className="panel-heading no-padding-bottom">
                            <h3 className="panel-title">{ST.get("my_products", "global")}</h3>
                        </div>
                        <div className="panel-body" style={{
                            background: window.portalSettings.portal_pcolor + "4e",
                            fontSize: "17px",
                            fontWeight: "500"
                        }}>
                            <Row>
                                <Col md={12} style={{padding: "25px"}}>
                                    {ST.get("no_records_found", "global")}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }

        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}