import React, {Component} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import QuickOrderText from "./QuickOrderText";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import QuickOrderFile from "./QuickOrderFile";

export default class QuickOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,

        }

    }

    selectTab = (tabIndex) => {

        this.setState({tabIndex});
    };
    getTabContent = () => {

        switch (this.state.tabIndex) {
            default:
            case 0:
                return (<QuickOrderText hide={this.props.hide}/>)
            case 1:
                return (<QuickOrderFile hide={this.props.hide}/>)
        }
    };

    render() {
        return (
            <Modal size="xl" dialogClassName={"modal-lg"}
                   aria-labelledby="contained-modal-title-vcenter"
                   show={this.props.show} onHide={this.props.hide}>
                <Modal.Header  style={{background:window.portalSettings.portal_pcolor,color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}>
                    <Modal.Title>{ST.get("quick_order","portal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                                <Row>
                                    <Col md={12}>
                                        <ul className="nav nav-tabs product-tabs">
                                            <li className={this.state.tabIndex === 0 ? "active" : ""}><a onClick={this.selectTab.bind(this, 0)}>{ST.get("text_input", "portal")}</a></li>
                                            <li className={this.state.tabIndex === 1 ? "active" : ""}><a onClick={this.selectTab.bind(this, 1)}>{ST.get("file_input", "portal")}</a></li>
                                        </ul>
                                        <div>
                                            {this.getTabContent()}
                                        </div>
                                    </Col>
                                </Row>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}


