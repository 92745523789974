import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import HeadType1 from "./HeadType1";
import HeadType2 from "./HeadType2";
import {getTagContent} from "./XMLTools";
import Breadcrumbs from "../B2B/Breadcrumbs";
import {ST} from "../global/SystemText";

import {Session} from "../global/PortalTools";

export default class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            documentXml:props.documentXml,
            document: props.document,
            documentDOM : new DOMParser().parseFromString(props.documentXml, "text/xml"),
            isB2C:props.isB2C?props.isB2C:false
        }
    }
    getColumnPairs(document){
        let columnPairs;
        switch (document) {
            case "quotation":
                columnPairs = {
                    title_document: "quotationTitle",
                    number_document: "quotation_number",
                    date_create_document: "quotation_date",
                    date_delivery_document: "date_delivery_document",
                    date_maturity_document: "date_maturity_document",
                    title_supplier: "supplier",
                    title_customer: "customer",
                    title_date_valid: "date_valid_to3",
                    title_email: "email",
                    title_phone: "phone",
                    title_fax: "fax",
                    title_ico: "ico",
                    title_dic: "dic",
                    title_icdph: "icdph",
                    title_customer_address: "quotation_customer_address1_text",
                    title_form_of_payment: "form_of_payment",
                    title_date_create_document: "date",
                    title_date_delivery_document: "title_date_delivery_document",
                    title_date_maturity_document: "title_date_maturity_document",
                    title_variable_symbol: "title_variable_symbol",
                    title_constant_symbol: "title_constant_symbol",
                    title_registration_number: "title_registration_number",
                    title_web: "web",
                    title_demand_number:"quotation_demand_number_text",
                    supplier_name: "mandant_name",
                    supplier_street: "mandant_street1",
                    supplier_city: "mandant_city1",
                    supplier_zip: "mandant_zip1",
                    supplier_country: "mandant_country1",
                    supplier_phone: "mandant_phone",
                    supplier_web: "mandant_web",
                    supplier_email: "mandant_email",
                    supplier_ico: "mandant_ico",
                    supplier_dic: "mandant_dic",
                    supplier_icdph: "mandant_icdph",
                    customer_name: "company_name",
                    customer_street: "company_street1",
                    customer_city: "company_city1",
                    customer_zip: "company_zip1",
                    customer_country: "company_country1",
                    customer_phone: "company_phone",
                    customer_web: "company_web",
                    customer_email: "company_email",
                    customer_ico: "company_ico",
                    customer_dic: "company_dic",
                    customer_icdph: "company_icdph",
                    delivery_name:"quotation_delivery_name",
                    delivery_city:"quotation_delivery_city",
                    delivery_zip:"quotation_delivery_zip",
                    delivery_street:"quotation_delivery_street",
                    delivery_country:"quotation_delivery_lcountry_name",
                    form_of_payment: "quotation_payment",
                    variable_symbol: "variable_symbol",
                    constant_symbol: "constant_symbol",
                    registration_number: "registration_number",
                    date_valid: "quotation_valid_date",
                    address_delivery:"address_delivery",
                    demand_number:"quotation_demand_number",
                    contact_name:"quotation_contact_name",
                    mandant_bank_account:"mandant_bank_account"

                };
                break;

            case "orderin":
                columnPairs = {
                    title_document: "orderin_title",
                    number_document: "orderin_number",
                    date_create_document: "orderin_date",
                    date_delivery_document: "date_delivery_document",
                    date_maturity_document: "date_maturity_document",
                    title_supplier: "supplier",
                    title_customer: "customer",
                    title_date_valid: "date_valid_to3",
                    title_email: "email",
                    title_phone: "phone",
                    title_fax: "fax",
                    title_ico: "ico",
                    title_dic: "dic",
                    title_icdph: "icdph",
                    title_customer_address: "address",
                    title_form_of_payment: "form_of_payment",
                    title_date_create_document: "date",
                    title_date_delivery_document: "title_date_delivery_document",
                    title_date_maturity_document: "title_date_maturity_document",
                    title_date_confirm: "orderin_date_confirm_text",
                    title_variable_symbol: "title_variable_symbol",
                    title_constant_symbol: "title_constant_symbol",
                    title_registration_number: "title_registration_number",
                    title_web: "web",
                    title_customer_order_number: "orderin_customer_order_number_report",
                    title_equip_up_report: "orderin_equip_up_report",
                    title_quotation_number: "titleQuotationNumber",
                    title_customer_number: "titleCustomerNumber",
                    supplier_name: "mandant_name",
                    supplier_street: "mandant_street1",
                    supplier_city: "mandant_city1",
                    supplier_zip: "mandant_zip1",
                    supplier_country: "mandant_country1",
                    supplier_phone: "mandant_phone",
                    supplier_web: "mandant_web",
                    supplier_email: "mandant_email",
                    supplier_ico: "mandant_ico",
                    supplier_dic: "mandant_dic",
                    supplier_icdph: "mandant_icdph",
                    customer_name: "company_name",
                    customer_street: "company_street1",
                    customer_city: "company_city1",
                    customer_zip: "company_zip1",
                    customer_country: "company_country1",
                    customer_phone: "company_phone",
                    customer_web: "company_web",
                    customer_email: "company_email",
                    customer_ico: "company_ico",
                    customer_dic: "company_dic",
                    customer_icdph: "company_icdph",
                    form_of_payment: "orderin_payment",
                    variable_symbol: "variable_symbol",
                    constant_symbol: "constant_symbol",
                    registration_number: "registration_number",
                    date_valid: "orderin_valid_date",
                    date_confirm: "orderin_date_confirm",
                    customer_order_number: "orderin_customer_number",
                    address_delivery:"address_delivery",
                    delivery_name:"orderin_delivery_name",
                    delivery_city:"orderin_delivery_city",
                    delivery_zip:"orderin_delivery_zip",
                    delivery_street:"orderin_delivery_street",
                    delivery_country:"orderin_delivery_lcountry_name",
                    print_summary:"orderin_print_summary",
                    quotation_number:"orderin_quotation_number",
                    customer_number:"orderin_customer_number",
                    mandant_bank_account:"mandant_bank_account"

                };
                break;

            case "invoice":
                columnPairs = {
                    title_document: "invoice_title",
                    number_document: "invoice_number",
                    date_create_document: "invoice_date",
                    date_delivery_document: "invoice_date_vat",
                    date_maturity_document: "invoice_date_payment",
                    title_supplier: "supplier",
                    title_customer: "customer",
                    title_date_valid: "date_valid_to3",
                    title_email: "email",
                    title_phone: "phone",
                    title_fax: "fax",
                    title_ico: "ico",
                    title_dic: "dic",
                    title_icdph: "icdph",
                    title_customer_address: "address",
                    title_form_of_payment: "form_of_payment",
                    title_date_create_document: "date_of_issue",
                    title_date_delivery_document: "invoice_delivery_date",
                    title_date_maturity_document: "title_invoice_date_payment",
                    title_variable_symbol: "variableSymbol",
                    title_constant_symbol: "constantSymbol",
                    title_registration_number: "titleRegistrationNumber",
                    title_web: "web",
                    title_customer_number: "titleOrderinCustomerNumber",
                    title_orderin_number: "titleOrderinNumber",
                    title_quotation_number: "titleQuotationNumber",
                    supplier_name: "mandant_name",
                    supplier_street: "mandant_street1",
                    supplier_city: "mandant_city1",
                    supplier_zip: "mandant_zip1",
                    supplier_country: "mandant_country1",
                    supplier_phone: "mandant_phone",
                    supplier_web: "mandant_web",
                    supplier_email: "mandant_email",
                    supplier_ico: "mandant_ico",
                    supplier_dic: "mandant_dic",
                    supplier_icdph: "mandant_icdph",
                    customer_name: "company_name",
                    customer_street: "company_street1",
                    customer_city: "company_city1",
                    customer_zip: "company_zip1",
                    customer_country: "company_country1",
                    customer_phone: "company_phone",
                    customer_web: "company_web",
                    customer_email: "company_email",
                    customer_ico: "company_ico",
                    customer_dic: "company_dic",
                    customer_icdph: "company_icdph",
                    form_of_payment: "invoice_payment",
                    variable_symbol: "invoice_vs",
                    constant_symbol: "invoice_ks",
                    registration_number: "invoice_registration_number",
                    date_valid: "invoice_date",
                    address_delivery:"address_delivery",
                    delivery_name:"invoice_delivery_name",
                    delivery_city:"invoice_delivery_city",
                    delivery_zip:"invoice_delivery_zip",
                    delivery_street:"invoice_delivery_street",
                    delivery_country:"invoice_delivery_lcountry_name",
                    quotation_number: "invoice_quotation_number",
                    orderin_number: "invoice_orderin_number",
                    customer_number: "invoice_orderin_customer_number",
                    mandant_bank_account: "mandant_account"

                };
                break;

            case "orderout":
                columnPairs = {
                    title_document: "orderout_title",
                    number_document: "orderout_number",
                    date_create_document: "orderout_date",
                    date_delivery_document: "orderout_date_vat",
                    date_maturity_document: "orderout_date_payment",
                    title_supplier: "supplier",
                    title_customer: "customer",
                    title_date_valid: "date_valid_to3",
                    title_email: "email",
                    title_phone: "phone",
                    title_fax: "fax",
                    title_ico: "ico",
                    title_dic: "dic",
                    title_icdph: "icdph",
                    title_customer_address: "address",
                    title_form_of_payment: "form_of_payment",
                    title_date_create_document: "date",
                    title_date_delivery_document: "orderout_delivery_date",
                    title_date_maturity_document: "title_orderout_date_payment",
                    title_variable_symbol: "variableSymbol",
                    title_constant_symbol: "constantSymbol",
                    title_registration_number: "titleRegistrationNumber",
                    title_web: "web",
                    customer_name: "mandant_name",
                    customer_street: "mandant_street1",
                    customer_city: "mandant_city1",
                    customer_zip: "mandant_zip1",
                    customer_country: "mandant_country1",
                    customer_phone: "mandant_phone",
                    customer_web: "mandant_web",
                    customer_email: "mandant_email",
                    customer_ico: "mandant_ico",
                    customer_dic: "mandant_dic",
                    customer_icdph: "mandant_icdph",

                    supplier_name: "company_name",
                    supplier_street: "company_street1",
                    supplier_city: "company_city1",
                    supplier_zip: "company_zip1",
                    supplier_country: "company_country1",
                    supplier_phone: "company_phone",
                    supplier_web: "company_web",
                    supplier_email: "company_email",
                    supplier_ico: "company_ico",
                    supplier_dic: "company_dic",
                    supplier_icdph: "company_icdph",

                    form_of_payment: "orderout_payment",
                    variable_symbol: "orderout_vs",
                    constant_symbol: "orderout_ks",
                    registration_number: "orderout_registration_number",
                    date_valid: "orderout_date",

                    address_delivery:"address_delivery",
                    delivery_name:"orderin_delivery_name",
                    delivery_city:"orderin_delivery_city",
                    delivery_zip:"orderin_delivery_zip",
                    delivery_street:"orderin_delivery_street",
                    delivery_country:"orderin_delivery_lcountry_name",
                    mandant_bank_account:"mandant_bank_account"

                };
                break;

            case "warehouse":
                columnPairs = {
                    title_document: "issue_text",
                    number_document: "warehouse_number",
                    date_create_document: "warehouse_date",
                    date_delivery_document: "date_delivery_document",
                    date_maturity_document: "date_maturity_document",
                    title_supplier: "supplier",
                    title_customer: "customer",
                    title_date_valid: "date_valid_to3",
                    title_email: "email",
                    title_phone: "phone",
                    title_fax: "fax",
                    title_ico: "ico",
                    title_dic: "dic",
                    title_icdph: "icdph",
                    title_customer_address: "address",
                    title_form_of_payment: "form_of_payment",
                    title_date_create_document: "date",
                    title_date_delivery_document: "title_date_delivery_document",
                    title_date_maturity_document: "title_date_maturity_document",
                    title_variable_symbol: "title_variable_symbol",
                    title_constant_symbol: "title_constant_symbol",
                    title_registration_number: "title_registration_number",
                    title_customer_number: "orderin_customer_number",
                    title_web: "web",
                    supplier_name: "mandant_name",
                    supplier_street: "mandant_street1",
                    supplier_city: "mandant_city1",
                    supplier_zip: "mandant_zip1",
                    supplier_country: "mandant_country1",
                    supplier_phone: "mandant_phone",
                    supplier_web: "mandant_web",
                    supplier_email: "mandant_email",
                    supplier_ico: "mandant_ico",
                    supplier_dic: "mandant_dic",
                    supplier_icdph: "mandant_icdph",
                    customer_name: "company_name",
                    customer_street: "company_street1",
                    customer_city: "company_city1",
                    customer_zip: "company_zip1",
                    customer_country: "company_country1",
                    customer_phone: "company_phone",
                    customer_web: "company_web",
                    customer_email: "company_email",
                    customer_ico: "company_ico",
                    customer_dic: "company_dic",
                    customer_icdph: "company_icdph",
                    form_of_payment: "warehouse_payment",
                    variable_symbol: "variable_symbol",
                    constant_symbol: "constant_symbol",
                    registration_number: "registration_number",
                    date_valid: "invoice_valid_date",
                    address_delivery:"address_delivery",
                    delivery_name:"warehouse_delivery_name",
                    delivery_city:"warehouse_delivery_city",
                    delivery_zip:"warehouse_delivery_zip",
                    delivery_street:"warehouse_delivery_street",
                    delivery_country:"warehouse_delivery_lcountry_name",
                    customer_number:"warehouse_orderin_customer_number",
                    mandant_bank_account:"mandant_bank_account"

                };
                break;

            default:
                columnPairs = {};
                break;
        }
        return columnPairs;
    }
    checkInvoiceDatePaid(){
        let {documentDOM, document} = this.state;
        if(document==="invoice") {
            let rest = parseFloat(getTagContent(documentDOM,"invoice_part_rest").replace(",",".").replace(" ",""));
            let daysAfterPayment = parseFloat(getTagContent(documentDOM, "invoice_days_after_payment"));

            if (daysAfterPayment>0 && rest >0)
                return (
                    <div className="alert bg-danger alert-styled-left">
                        <span className="text-semibold">{ST.get("global_warning","global")}!</span> {ST.get("invoice_is_overdue","global")} {daysAfterPayment} {ST.get("global_days","global")}. {ST.get("invoice_payment_parts_rest","global")} {getTagContent(documentDOM, "invoice_part_rest")}
                    </div>)
        }else{
            return(<div/>);
        }
    }
    render() {
        let {documentDOM, document} = this.state;
        let settings = window.portalSettings;
        let columnPairs = this.getColumnPairs(document);
        let head = Number(settings[document + "_header_type"]);
        return (
            <div className="panel border-top-xlg" style={{borderColor: settings.portal_pcolor}}>
                {(Number(Session.getItem("portal_type"))>1 && !this.state.isB2C) ?
                <Breadcrumbs type={document} active={getTagContent(documentDOM, document+"_number")}/>
                : null}
                <div className="panel-heading">
                    <h1 className="panel-title text-semibold">
                        {getTagContent(documentDOM, columnPairs["title_document"])}
                        <span className="pull-right"> {getTagContent(documentDOM, document + "_number")}</span>
                    </h1>
                </div>

                <div className="panel-body ">
                    {this.checkInvoiceDatePaid()}
                    {settings[document + "_print_document_logo"] ?
                        (<Row>
                            <Col md={12}>
                                <img alt="document banner" src={getTagContent(documentDOM, document + "_logo")} style={{width: "100%", paddingBottom: "10px"}}/>
                            </Col>
                        </Row>) : ""
                    }
                    {settings[document + "_show_header"] ? (
                        <Row>
                            <Col md={12}>
                                {head === 1 ?
                                    <HeadType1 documentDOM={documentDOM} document={document} columnPairs={columnPairs} settings={settings}/>
                                    :
                                    <HeadType2 documentDOM={documentDOM} document={document} columnPairs={columnPairs} settings={settings}/>
                                }
                            </Col>
                        </Row>
                    ) : ""}

                    <Row>
                        <Col md={12}>
                            <div className="mb-10" dangerouslySetInnerHTML={{__html: getTagContent(documentDOM, document + "_fortext")}}/>
                            <div className="mb-10" dangerouslySetInnerHTML={{__html: getTagContent(documentDOM, document + "_description")}}/>
                        </Col>
                    </Row>

                </div>


            </div>
        );
    }
}