import React, {Component} from 'react';
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import Breadcrumbs from "../Breadcrumbs";
import {getTagContent} from "../../document/XMLTools";
import {ST} from "../../global/SystemText";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Lightbox from "react-image-lightbox";


export default class Claim extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: this.props.match.params.id,
            data: {},
            openImage:false,
            openImageIndex:0
        };
    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                id: this.state.id,
                action: "GET_CLAIM_DETAIL",

            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({data: response.data, loaded: true})
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if (this.state.loaded) {
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs type={"claim"} idInvoice={this.state.data.invoice_id}
                                 numberInvoice={this.state.data.invoice_number} active={this.state.data.number}/>
                    <div className="panel-heading">
                        <h1 className="panel-title text-semibold">
                            {ST.get("claim", "document")}
                            <span className="pull-right"> {this.state.data.number}</span>
                        </h1>
                    </div>

                    <div className="panel-body ">
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("invoice", "document")}: </Col>
                                    <Col xs={6} sm={8}><b><Link
                                        to={Session.getItem("basename") + "/invoice/" + this.state.data.invoice_id}>{this.state.data.invoice_number}</Link></b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("item", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.ii_name}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("creation_date", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.date}</b></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_type", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.type}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_cover", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.cover}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_state", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.state}</b></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col xs={12} sm={12}>{ST.get("claim_description", "document")}: </Col>
                                    <Col xs={12} sm={12}><b>{this.state.data.description}</b></Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_serno", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.serno}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_catno", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.catno}</b></Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_maxprice", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.maxprice}</b></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Row>
                                    <Col xs={6} sm={4}>{ST.get("claim_code", "document")}: </Col>
                                    <Col xs={6} sm={8}><b>{this.state.data.code}</b></Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col xs={12} sm={12}>{ST.get("pictures", "product")}: </Col>
                                    <Col xs={12} sm={12}>
                                        <Row>
                                            {this.state.data.documents.map((element,index)=>{
                                               return( element.isImage?
                                                    <Col md={3}>
                                                        <img src={Session.getItem("endpoint") + element.url} className={"img-responsive"} onClick={() => this.setState({openImage: true,openImageIndex:index})}/>
                                                    </Col>:null)
                                            })}
                                            {this.state.openImage && (
                                                <Lightbox
                                                    mainSrc={Session.getItem("endpoint") +this.state.data.documents[this.state.openImageIndex].url}
                                                    onCloseRequest={() => this.setState({openImage: false})}
                                                />
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col xs={12} sm={12}>{ST.get("documents", "document")}: </Col>
                                    <Col xs={12} sm={12}>
                                        <Row>
                                        {this.state.data.documents.map((element, index) => (
                                            element.isImage?null:
                                            <Col md={3} key={index}>
                                                <a target="_blank" rel="noopener noreferrer" href={Session.getItem("endpoint") + element.url}>
                                                    <Row className="mt-10">
                                                        <Col md={12} className="">
                                                            <i className={"oicon24 oicon-" + element.ext}/> {element.name}
                                                        </Col>

                                                    </Row>
                                                </a>
                                            </Col>
                                        ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </div>

            )
        } else {
            return (<div></div>);
        }
    }
}




