import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {Button, Col, FormControl, FormGroup, Row} from "react-bootstrap";
import DocumentsHead from "./DocumentsHead";
import DocumentRow from "./DocumentRow";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import PaymentModal from "./PaymentModal";
import Breadcrumbs from "../Breadcrumbs";
import {ST} from "../../global/SystemText";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as moment from "moment";
import DocumentListSummary from "./DocumentListSummary";

export default class DocumentList extends Component {
    constructor(props) {
        super(props);
        let showOnly = '';
        if (this.props.match.params.document === 'invoice') {
            if (this.props.match.params.type === '1') {
                showOnly = 'nopaid';
            } else if (this.props.match.params.type === '2') {
                showOnly = 'nopaid_exp_date';
            }
        }

        if (this.props.match.params.document === 'warehouse') {
            if (this.props.match.params.type === '1') {
                showOnly = 'uninvoiced';
            }
        }

        let filter = PT.Session.getItem(this.props.match.params.document + "Filter");

        this.state = {
            loaded: false,
            summaryLoaded: false,
            document: this.props.match.params.document,
            page: 1,
            paginationLimit: 5,
            settings: {},
            data: {},
            summaryData: {},
            payment_data: {},
            show_count: 50,
            columnsAmount: this.getColumnsAmount(),
            showPayment: false,
            showOnly: showOnly,
            loadedDocument: "",
            addresses: [],
            filter: filter ? JSON.parse(filter) : DocumentList.getDefaultFilter()
        };
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.document === newProps.match.params.document) {
            return null;
        } else {
            let filter = PT.Session.getItem(newProps.match.params.document + "Filter");
            newState.document = newProps.match.params.document;
            newState.loaded = false;
            newState.summaryLoaded = false;
            newState.page = 1;
            newState.filter = filter ? JSON.parse(filter) : DocumentList.getDefaultFilter();
            return newState;
        }
    }

    getColumnsAmount() {
        let document = this.props.match.params.document;
        let amount = document === 'warehouse' ? 7 : 9;
        if (document === 'warehouse') {
            if (!Number(window.portalSettings.invoice_access) > 0) {
                amount -= 1;
            }
            if (!Number(window.portalSettings.orderin_access) > 0) {
                amount -= 1;
            }
            if(!Boolean(window.portalSettings.show_prices)){
                amount -= 2;
            }
        }
        if (document === 'orderin') {
            if (!Number(window.portalSettings.warehouse_access) > 0) {
                amount -= 1;
            }
            if (!Number(window.portalSettings.invoice_access) > 0) {
                amount -= 1;
            }
            if(!Boolean(window.portalSettings.show_prices)){
                amount -= 2;
            }
        }
        if (document === 'invoice') {
            if (!Number(window.portalSettings.warehouse_access) > 0) {
                amount -= 1;
            }
            if(!Boolean(window.portalSettings.show_prices)){
                amount -= 3;
            }
        }
        if (document === 'quotation') {
            if (!Number(window.portalSettings.orderin_access) > 0) {
                amount -= 1;
            }
            if(!Boolean(window.portalSettings.show_prices)){
                amount -= 2;
            }
        }

        return amount;
    }

    async componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getDocumentList();
            if (this.document !== 'warehouse' && this.document !== 'claim') {
                this.getDocumentSummary();
            }
            window.scrollTo(0, 0)
        }
    }

    getDocumentList() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                showOnly: this.state.showOnly,
                document: this.state.document,
                page: this.state.page,
                filter: JSON.stringify(this.state.filter),
                action: "GET_DOCUMENT_LIST"
            }
        })
            .then((response) => {
                this.setState({
                    loaded: response.data.document === this.state.document,
                    data: response.data,
                    show_count: response.data.show_count
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    getDocumentSummary() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                showOnly: this.state.showOnly,
                document: this.state.document,
                filter: JSON.stringify(this.state.filter),
                action: "GET_DOCUMENT_SUMMARY"
            }
        })
            .then((response) => {
                this.setState({
                    summaryLoaded: true,
                    summaryData: response.data
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    async componentDidMount() {
        this.getDocumentList();

        if (this.document !== 'warehouse' && this.document !== 'claim') {
            this.getDocumentSummary();
        }

        let response = await axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                action: "GET_ADDRESSES_FOR_PORTAL"
            }
        }).catch(error => PT.handleError(error, this.props.history));

        if (response) {
            let {data} = response;
            let addresses = [];
            if (data.limited === "false") {
                addresses.push(data.invoice);
            }
            data.delivery.forEach((element) => addresses.push(element));
            this.setState({addresses: addresses});
        }
    }

    togglePayment(index, type) {
        if (index !== undefined) {

            if (type === 'nopaid_exp_date') {
                let data = this.state.data.unpaid_after_expiration_date[index].payment_data;
                data.invoice_id= this.state.data.unpaid_after_expiration_date[index].id;
                this.setState({
                    payment_data: data
                });
            } else if (type === 'nopaid') {
                let data = this.state.data.unpaid[index].payment_data;
                data.invoice_id= this.state.data.unpaid[index].id;
                this.setState({
                    payment_data: data
                });
            } else {
                let data = this.state.data.list[index].payment_data;
                data.invoice_id= this.state.data.list[index].id;
                this.setState({
                    payment_data: data
                });
            }

        }

        this.setState({
            showPayment: !this.state.showPayment
        })
    }

    getTitleByDocument() {
        if (this.state.document === 'invoice') {
            return ST.get("invoice_list", "global");
        } else if (this.state.document === 'orderin') {
            return ST.get("orderins_list", "global");
        } else if (this.state.document === 'claim') {
            return ST.get("claim_list", "global");
        }else if (this.state.document === 'warehouse') {
            if (this.state.showOnly === 'uninvoiced') return ST.get("uninvoiced_delivery_notes", "global");
            return ST.get("delivery_notes_list", "global");
        } else if (this.state.document === 'quotation') {
            return ST.get("quotations_list", "global");
        }
    }

    canSeeList(document) {
        if (document !== this.state.document) return false;

        if (document === 'invoice') {
            return !(this.state.showOnly === 'nopaid' || this.state.showOnly === 'nopaid_exp_date');
        }

        if (document === 'warehouse') {
            return this.state.showOnly !== 'uninvoiced'
        }

        return true;
    }

    canSeeNoPaid(afterExpDate) {
        if (this.state.document === 'invoice') {
            if (afterExpDate) {
                return (this.state.data.unpaid_after_expiration_date.length > 0 && this.state.showOnly !== 'nopaid') || this.state.showOnly === 'nopaid_exp_date';
            } else {
                return (this.state.data.unpaid.length > 0 && this.state.showOnly !== 'nopaid_exp_date') || this.state.showOnly === 'nopaid';
            }
        }

        return false;
    }

    documentHasData() {
        if (this.state.document === 'warehouse') {
            if (this.state.data.list.length > 0 || this.state.data.no_invoice.length > 0) {
                return true
            }
        } else if (this.state.document === 'invoice') {
            if (this.state.data.list.length > 0 || this.state.data.unpaid_after_expiration_date.length > 0 || this.state.data.unpaid.length > 0) {
                return true
            }
        } else if (this.state.data.list.length > 0) {
            return true;
        }
        return false;
    }

    getDocumentFilter() {
        return (
            <div>
            <Row className={"p-5"}>
                <Col sm={1}>
                    <Row>
                        <Col sm={12}>
                            {ST.get("number", "global")}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup controlId={"number"}>
                                <FormControl
                                    className={"form-control-bordered"}
                                    type={"text"}
                                    onChange={(event) => this.setFilterValue('number', event.target.value)}
                                    value={this.state.filter.number}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row>
                        <Col sm={12}>
                            {ST.get("creation_date", "global")}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Datetime
                                inputProps={{placeholder:ST.get("global_from","global"),className:"form-control form-control-bordered"}}
                                timeFormat={false}
                                value={moment(this.state.filter.dateFrom)}
                                dateFormat="DD. MM. YYYY"
                                disabled={!!window.cordova}
                                onChange={this.handleDateChange.bind(this, "dateFrom")}/>

                        </Col>
                        <Col xs={6}>
                            <Datetime
                                inputProps={{placeholder:ST.get("to","global"),className:"form-control form-control-bordered"}}
                                timeFormat={false}
                                value={moment(this.state.filter.dateTo)}
                                dateFormat="DD. MM. YYYY"
                                disabled={!!window.cordova}
                                onChange={this.handleDateChange.bind(this, "dateTo")}/>

                        </Col>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row>
                        <Col sm={12}>
                            {ST.get("address", "global")}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup controlId={"address"}>
                                <FormControl className={"form-control-bordered"} componentClass="select" value={this.state.filter.address} onChange={(event) => this.setFilterValue('address', event.target.value)}>
                                    <option value={-1}>---</option>
                                    {this.state.addresses.map((element, index) => {
                                        return (<option key={index}
                                                        value={Number(index)}>{element.name}, {element.street}, {element.zip} {element.city}, {element.nameCountry}</option>);
                                    })}
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                {this.state.document === 'invoice' ? (
                    <Col sm={1}>
                        <Row>
                            <Col sm={12}>
                                {ST.get("variable_symbol", "global")}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup controlId={"vs"}>
                                    <FormControl
                                        className={"form-control-bordered"}
                                        type={"text"}
                                        onChange={(event) => this.setFilterValue('vs', event.target.value)}
                                        value={this.state.filter.vs}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                ) : (
                    <Col sm={1}>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col sm={3}>
                    <Row>
                        <Col>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}>&nbsp;</Col>
                        <Col xs={5}>
                            <Button onClick={this.submitFilter}>{ST.get("filter", "global")}</Button>
                        </Col>
                        <Col xs={5}>
                            <Button onClick={this.cancelFilter}>{ST.get("cancel", "global")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </div>
        );
    }

    static getDefaultFilter = () => {
        return {number: '', dateFrom: '', dateTo: '', address: '-1', addressText: '', vs: ''};
    };

    isFilterSet = (filter) => {
        let defaultFilter = DocumentList.getDefaultFilter();
        let result = false;
        let keys = Object.keys(defaultFilter);
        keys.forEach((key) => {
            result = result || defaultFilter[key] !== filter[key]
        });
        return result;
    };

    submitFilter = () => {
        let {filter, document} = this.state;
        PT.Session.setItem(document + "Filter", JSON.stringify(filter));
        this.getDocumentList();
    };

    cancelFilter = () => {
        let {document} = this.state;
        let filter = DocumentList.getDefaultFilter();
        PT.Session.setItem(document + "Filter", JSON.stringify(filter));
        this.setState({filter}, () => this.getDocumentList());
    };

    setFilterValue(key, value) {
        let {filter} = this.state;
        filter[key] = value;
        if (key === 'address') {
            if (value > -1) {
                filter.addressText = this.state.addresses[value];
            } else {
                filter.addressText = '';
            }
        }
        this.setState({filter});
    }

    handleDateChange = (key, event) => {
        let newState = "";
        if (event instanceof moment) {
            newState = event.format("YYYY-MM-DD");
        } else {
            newState = event;
        }
        this.setFilterValue(key, newState);
    };

    render() {
        let pages = Math.ceil(this.state.data.documents_count / this.state.show_count);

        let paginations = [];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }

        if (this.state.loaded) {
            if (this.documentHasData()) {
                return (
                    <div>
                        <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                            <Breadcrumbs active={this.getTitleByDocument()}/>
                            <div className="panel-heading">
                                <h1 className="panel-title">
                                    {this.getTitleByDocument()}
                                </h1>
                            </div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    {this.getDocumentFilter()}
                                    <table className="table table-lg">
                                        <DocumentsHead document={this.state.document}/>
                                        {this.canSeeNoPaid(true) ?
                                            <tbody>
                                            <tr className="active border-double">
                                                <td className="text-semibold" colSpan={this.getColumnsAmount() - 1}>
                                                    {ST.get("unpaid_after_expiration_date", "global")}
                                                </td>
                                                <td className="text-right">
                                                    <span
                                                        className="badge badge-default">{this.state.data.unpaid_after_expiration_date_documents_count}</span>
                                                </td>
                                            </tr>
                                            {this.state.data.unpaid_after_expiration_date.map((row, index) => {
                                                return (<DocumentRow dataIndex={index} type={"nopaid_exp_date"}
                                                                     togglePayment={this.togglePayment.bind(this)}
                                                                     key={"rowupd" + index} document={this.state.document}
                                                                     documentData={row}/>)
                                            })}
                                            </tbody>
                                            : null
                                        }
                                        {this.canSeeNoPaid(false) ?
                                            <tbody>
                                            <tr className="active border-double">
                                                <td className="text-semibold" colSpan={this.getColumnsAmount() - 1}>
                                                    {ST.get("unpaid_invoices", "global")}
                                                </td>
                                                <td className="text-right">
                                                    <span
                                                        className="badge badge-default">{this.state.data.unpaid_documents_count}</span>
                                                </td>
                                            </tr>
                                            {this.state.data.unpaid.map((row, index) => {
                                                return (<DocumentRow dataIndex={index}
                                                                     togglePayment={this.togglePayment.bind(this)}
                                                                     type={"unpaid"} key={"rowup" + index}
                                                                     document={this.state.document}
                                                                     documentData={row}/>)
                                            })}
                                            </tbody>
                                            : null
                                        }
                                        {this.canSeeList('invoice') ?
                                            <tbody>
                                            <tr className="active border-double">
                                                <td className="text-semibold" colSpan={this.getColumnsAmount() - 1}>
                                                    {ST.get("all_invoices", "global")}
                                                </td>
                                                <td className="text-right">
                                                    <span
                                                        className="badge badge-default">{this.state.data.documents_count}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : null
                                        }
                                        {this.state.document === 'warehouse' ?
                                            <tbody>
                                            <tr className="active border-double">
                                                <td className="text-semibold" colSpan={this.getColumnsAmount() - 1}>
                                                    {ST.get("uninvoiced", "global")}
                                                </td>
                                                <td className="text-right">
                                                    <span
                                                        className="badge badge-default">{this.state.data.no_invoice.length}
                                                    </span>
                                                </td>
                                            </tr>
                                            {this.state.data.no_invoice.map((row, index) => {
                                                return (<DocumentRow key={"rowup" + index} document={this.state.document}
                                                                     documentData={row}/>)
                                            })}
                                            </tbody>
                                            : null
                                        }
                                        {this.canSeeList('warehouse') ?
                                            <tbody>
                                            <tr className="active border-double">
                                                <td className="text-semibold" colSpan={this.getColumnsAmount() - 1}>
                                                    {ST.get("all", "global")}
                                                </td>
                                                <td className="text-right">
                                                    <span
                                                        className="badge badge-default">{this.state.data.documents_count}
                                                    </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            : null
                                        }

                                        {this.canSeeList(this.state.document) ?
                                            <tbody>
                                            {this.state.data.list.map((row, index) => {
                                                return (
                                                    <DocumentRow dataIndex={index}
                                                                 togglePayment={this.togglePayment.bind(this)}
                                                                 type={"all"} key={"row" + index}
                                                                 document={this.state.document}
                                                                 documentData={row}/>)
                                            })}
                                            </tbody> : null
                                        }
                                    </table>
                                    <hr/>
                                </div>
                                {this.state.document !== 'warehouse' && this.state.document !== 'claim' && this.isFilterSet(this.state.filter)?
                                <DocumentListSummary summaryData={this.state.summaryData} loaded={this.state.summaryLoaded}/>
                                : null }
                                <div className="text-center content-group-lg pt-20">
                                    <ul className="pagination">
                                        <li className={this.state.page === 1 ? "disabled" : ""}><Button
                                            disabled={this.state.page === 1} onClick={() => {
                                            this.setState({page: (this.state.page - 1), loaded: false})
                                        }} size={"sm"} variant={"light"}><i className="icon-arrow-left15"/></Button>
                                        </li>
                                        {paginations.map((element, index) => {
                                            return (element);
                                        })}
                                        <li className={this.state.page === pages ? "disabled" : ""}>
                                            <Button disabled={this.state.page === pages} onClick={() => {
                                                this.setState({page: (this.state.page + 1), loaded: false})
                                            }} size={"sm"} variant={"light"}><i
                                                className="icon-arrow-right15"/></Button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <PaymentModal showPayment={this.state.showPayment} togglePayment={this.togglePayment.bind(this)}
                                      data={this.state.payment_data}/>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                            <Breadcrumbs active={this.getTitleByDocument()}/>
                            <div className="panel-heading">
                                <h1 className="panel-title">
                                    {this.getTitleByDocument()}
                                </h1>
                            </div>
                            <div className="panel-body">
                                {this.getDocumentFilter()}
                            </div>
                            <div className="panel-body" style={{
                                background: window.portalSettings.portal_pcolor + "4e",
                                fontSize: "17px",
                                fontWeight: "500"
                            }}>
                                <Row>
                                    <Col md={12} style={{padding: "25px"}}>
                                        {ST.get("no_records_found", "global")}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
