import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from "axios/index";
import { BubbleLoader } from 'react-css-loaders';
import Marker from './map/Marker'
const MY_API_KEY = "AIzaSyBI5uG9VpjlNrih0PhvbEnwMSeXAY99g2k";


export default class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            lat: props.address.lat ? Number(props.address.lat) : 0,
            lng: props.address.lng ? Number(props.address.lng) : 0,
            addresses: props.addresses ? props.addresses : [],
            error: false
        };
    }

    componentDidMount() {
        if(this.state.lat === 0 && this.state.lng === 0) {
            axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.props.address.street + "" + this.props.address.city + " " + this.props.address.zip + "&key=" + MY_API_KEY)
                .then(response => {
                    if (response.data.results.length > 0) {
                        let lat = response.data.results[0].geometry.location.lat;
                        let lng = response.data.results[0].geometry.location.lng;
                        this.setState({lat: lat, lng: lng, loaded: true})
                    } else {
                        this.setState({error: true});
                    }
                })
                .catch(error => {
                    this.setState({error: true});
                });
        } else {
            this.setState({loaded:true})
        }
    }


    render() {
        let Map;
        if (this.state.loaded) {
            Map = <GoogleMapReact
                bootstrapURLKeys={{ key: MY_API_KEY }}
                defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
                defaultZoom={13}
                options={{ mapTypeControl: true, StreetViewStatus: true }}
            >
                <Marker lat={this.state.lat} lng={this.state.lng} address={this.props.address} />
                {this.state.addresses.map((value, index) => {
                    return(<Marker lat={value.lat} lng={value.lng} key={"marker" + index} address={value} />)
                })}

            </GoogleMapReact>
        } else {
                if(this.state.error){
                    // TODO - upravit
                    Map = <div>Failed to load map</div>
                } else {
                    Map = <BubbleLoader color={window.portalSettings.portal_pcolor}/>
                }
        }
        return (
<div style={{height:"500px"}}>
             {Map}
</div>
        );


    }
}




