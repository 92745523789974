import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import {getTagContent} from "./XMLTools";

import {Session} from "../global/PortalTools";

export default class HeadType1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    showDelivery(documentDOM, columnPairs) {
        return (
            getTagContent(documentDOM, columnPairs["delivery_name"]) !== '' ||
            getTagContent(documentDOM, columnPairs["contact_name"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_street"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_zip"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_country"]) !== '')
    }

    getCityZip(zip, city) {
        let cityZip = "";

        if (zip !== '') {
            cityZip += zip;
        }

        if (city !== '') {
            if (cityZip !== '') {
                cityZip += " " + city
            } else {
                cityZip = city;
            }
        }

        return cityZip;
    }

    render() {
        let {documentDOM, document,columnPairs} = this.props;
        return(
            <div>
            <Row>
                <Col xs={12}  sm={7} md={6} className="pr-30" >
                    <Row>
                        <Col sm={12}>
                            <h5 className="border-bottom" style={{borderColor:this.props.settings.portal_pcolor}}>{getTagContent(documentDOM,columnPairs["title_supplier"])}</h5>

                        </Col>
                        <Col sm={9} xs={9}>
                            <div className="text-semibold text-size-large">{getTagContent(documentDOM,columnPairs["supplier_name"])}</div>
                            <div className="">{getTagContent(documentDOM,columnPairs["supplier_street"])}</div>
                            <div className="">{getTagContent(documentDOM,columnPairs["supplier_zip"])} {getTagContent(documentDOM,columnPairs["supplier_city"])}</div>
                            <div className="">{getTagContent(documentDOM,columnPairs["supplier_country"])}</div>
                        </Col>
                        <Col sm={3} xs={3}>
                            <img className="img-responsive" src={Session.getItem("endpoint")+"PictureServlet?id="+Session.idMandant+"&action=GET_mandant_LOGO"} alt=""/>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={12} className="pt-15">
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_ico"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_ico"])}</Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_dic"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_dic"])}</Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_icdph"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_icdph"])}</Col>
                            </Row>
                        </Col>
                        <Col sm={12} className="pt-15">
                            {getTagContent(documentDOM,columnPairs["supplier_phone"])!=="" ?
                                <Row>
                                    <Col xs={5} sm={4}>{getTagContent(documentDOM,columnPairs["title_phone"])}: </Col>
                                    <Col xs={7} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_phone"])} </Col>
                                </Row>
                                :""}
                            {getTagContent(documentDOM,columnPairs["supplier_email"])!=="" ?
                                <Row>
                                    <Col xs={5} sm={4}>{getTagContent(documentDOM,columnPairs["title_email"])}: </Col>
                                    <Col xs={7} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_email"])} </Col>
                                </Row>
                                :""} 
                            {getTagContent(documentDOM,columnPairs["supplier_web"])!=="" ?
                                <Row>
                                    <Col xs={5} sm={4}>Web: </Col>
                                    <Col xs={7} sm={8}>{getTagContent(documentDOM,columnPairs["supplier_web"])}</Col>
                                </Row>
                                :""}
                        </Col>
                    </Row>

                </Col>
                <Col xs={12}  sm={5} md={6}>
                    <Row>
                        <Col sm={12}>
                            <h5 className="border-bottom" style={{borderColor:this.props.settings.portal_pcolor}}>{getTagContent(documentDOM,columnPairs["title_customer"])}</h5>
                            <div className="text-semibold text-size-large">{getTagContent(documentDOM,columnPairs["customer_name"])}</div>
                        </Col>
                        <Col sm={6}>
                            <div className="">{getTagContent(documentDOM,columnPairs["customer_street"])}</div>
                            <div className="">{getTagContent(documentDOM,columnPairs["customer_zip"])} {getTagContent(documentDOM,columnPairs["customer_city"])}</div>
                            <div className="">{getTagContent(documentDOM,columnPairs["customer_country"])}</div>
                        </Col>

                    </Row>

                    <Row>
                        <Col sm={6} className="pt-15">
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_ico"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["customer_ico"])}</Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_dic"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["customer_dic"])}</Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_icdph"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["customer_icdph"])}</Col>
                            </Row>
                        </Col>
                        <Col sm={6} className="pt-15">
                            {getTagContent(documentDOM,columnPairs["customer_phone"])!=="" ?
                                <Row>
                                    <Col xs={4}  sm={4}>{getTagContent(documentDOM,columnPairs["title_phone"])}: </Col>
                                    <Col xs={8}  sm={8}>{getTagContent(documentDOM,columnPairs["customer_phone"])} </Col>
                                </Row>
                                :""}
                            {getTagContent(documentDOM,columnPairs["customer_email"])!=="" ?
                                <Row>
                                    <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_email"])}: </Col>
                                    <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["customer_email"])} </Col>
                                </Row>
                                :""}
                            {getTagContent(documentDOM,columnPairs["customer_web"])!=="" ?
                                <Row>
                                    <Col xs={6} sm={4}>Web: </Col>
                                    <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["customer_web"])}</Col>
                                </Row>
                                :""}
                        </Col>
                    </Row>


                </Col>
            </Row>
            <Row>
                {document==="quotation" ?
                <Col xs={12} sm={7} md={6}>
                    <hr/>
                    {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                    <Row>
                        <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_date_create_document"])}: </Col>
                        <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["date_create_document"])}</Col>
                    </Row>:""}
                    {getTagContent(documentDOM, columnPairs["date_valid"]) !== '' ?
                    <Row>
                        <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_date_valid"])}: </Col>
                        <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["date_valid"])}</Col>
                    </Row>:""}
                    {getTagContent(documentDOM, columnPairs["demand_number"]) !== '' ?
                        <Row>
                            <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_demand_number"])}: </Col>
                            <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["demand_number"])}</Col>
                        </Row>:""}
                    {getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                        <Row>
                            <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_form_of_payment"])}: </Col>
                            <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["form_of_payment"])}</Col>
                        </Row>:""}
                </Col>
                :null}
                {document==="orderin" ?
                    <Col sm={7} md={6}>
                        <hr/>
                        {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                        <Row>
                            <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_date_create_document"])}: </Col>
                            <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["date_create_document"])}</Col>
                        </Row>:""}
                        {getTagContent(documentDOM, columnPairs["date_delivery_document"]) !== '' ?
                        <Row>
                            <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["title_date_delivery_document"])}:</Col>
                            <Col className="align-right" xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["date_delivery_document"])}</Col>
                        </Row>:""}
                        {getTagContent(documentDOM, columnPairs["date_maturity_document"]) !== '' ?
                        <Row>
                            <Col xs={6} sm={8}>
                                {getTagContent(documentDOM, columnPairs["title_date_maturity_document"])}:
                            </Col>
                            <Col className="align-right" xs={6} sm={4}>
                                {getTagContent(documentDOM, columnPairs["date_maturity_document"])}
                            </Col>
                        </Row>:""}
                        {getTagContent(documentDOM, columnPairs["date_confirm"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_date_confirm"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["date_confirm"])}</Col>
                            </Row>:""}
                        {getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_form_of_payment"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["form_of_payment"])}</Col>
                            </Row>:""}

                        {getTagContent(documentDOM, columnPairs["date_valid"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_date_valid"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["date_valid"])}</Col>
                            </Row>:""}

                        {getTagContent(documentDOM, columnPairs["quotation_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>
                                    {getTagContent(documentDOM, columnPairs["title_quotation_number"])}:
                                </Col>
                                <Col xs={6} sm={8}>
                                    {getTagContent(documentDOM, columnPairs["quotation_number"])}
                                </Col>
                            </Row>:""}

                        {getTagContent(documentDOM, columnPairs["customer_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>
                                    {getTagContent(documentDOM, columnPairs["title_customer_number"])}:
                                </Col>
                                <Col xs={6} sm={8}>
                                    {getTagContent(documentDOM, columnPairs["customer_number"])}
                                </Col>
                            </Row>:""}
                    </Col>
                    :null}
                {document==="invoice" ?
                    <Col sm={7} md={6}>
                        <hr/>
                        {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM,columnPairs["title_date_create_document"])}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM,columnPairs["date_create_document"])}</Col>
                            </Row>:""}

                        {getTagContent(documentDOM, columnPairs["date_maturity_document"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_date_maturity_document"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["date_maturity_document"])}</Col>
                            </Row>:""}

                        {getTagContent(documentDOM, 'invoice_date_vat') !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, 'invoice_date_vat_title')}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, 'invoice_date_vat')}</Col>
                            </Row>:""}

                        {getTagContent(documentDOM, columnPairs["customer_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_customer_number"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["customer_number"])}</Col>
                            </Row>:""}
                        {getTagContent(documentDOM, columnPairs["orderin_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_orderin_number"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["orderin_number"])}</Col>
                            </Row>:""}
                        {getTagContent(documentDOM, columnPairs["quotation_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, columnPairs["title_quotation_number"])}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["quotation_number"])}</Col>
                            </Row>:""}

                        {document === 'invoice' && getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>
                                    {getTagContent(documentDOM, columnPairs["title_form_of_payment"])}
                                </Col>
                                <Col xs={6} sm={8}>
                                    {getTagContent(documentDOM, columnPairs["form_of_payment"])}
                                </Col>
                            </Row> : ("")}
                        {getTagContent(documentDOM, columnPairs["mandant_bank_account"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "account_number")}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, columnPairs["mandant_bank_account"])}</Col>
                            </Row>
                            : null}
                        {getTagContent(documentDOM, "mandant_iban") !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "iban")}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, "mandant_iban")}</Col>
                            </Row>
                            : null}
                        {getTagContent(documentDOM, "mandant_swift") !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "swift")}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, "mandant_swift")}</Col>
                            </Row>
                            : null}
                        {getTagContent(documentDOM, "invoice_vs") !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "variableSymbol")}:</Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, "invoice_vs")}</Col>
                            </Row>
                            : null}
                        {getTagContent(documentDOM, "invoice_ks") !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "constantSymbol")}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, "invoice_ks")}</Col>
                            </Row>
                            : null}
                        {getTagContent(documentDOM, "invoice_ss") !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>{getTagContent(documentDOM, "specificSymbol")}: </Col>
                                <Col xs={6} sm={8}>{getTagContent(documentDOM, "invoice_ss")}</Col>
                            </Row>
                            : null}
                    </Col>
                    :null}
                {document==="warehouse" ?
                    <Col sm={7} md={6}>
                        <hr/>
                        {getTagContent(documentDOM, columnPairs["customer_number"]) !== '' ?
                            <Row>
                                <Col xs={6} sm={4}>
                                    {getTagContent(documentDOM, columnPairs["title_customer_number"])}:
                                </Col>
                                <Col xs={6} sm={8}>
                                    {getTagContent(documentDOM, columnPairs["customer_number"])}
                                </Col>
                            </Row>:""}
                    </Col>
                    :null}

                {this.showDelivery(documentDOM, columnPairs) ?
                <Col sm={5} md={6}>
                        <h5 className="no-margin-bottom">{getTagContent(documentDOM,columnPairs["address_delivery"])}</h5>
                        {getTagContent(documentDOM, columnPairs["delivery_name"]) !== '' ?
                            <div>{getTagContent(documentDOM, columnPairs["delivery_name"])}</div> : ("")}

                        {getTagContent(documentDOM, columnPairs["contact_name"]) !== '' ?
                            <div>{getTagContent(documentDOM, columnPairs["contact_name"])}</div> : ("")}

                        {getTagContent(documentDOM, columnPairs["delivery_street"]) !== '' ?
                            <div>{getTagContent(documentDOM, columnPairs["delivery_street"])}</div> : ("")}

                        {getTagContent(documentDOM, columnPairs["delivery_zip"]) !== '' || getTagContent(documentDOM, columnPairs["delivery_city"]) !== '' ?
                            <div>{this.getCityZip(getTagContent(documentDOM, columnPairs["delivery_zip"]), getTagContent(documentDOM, columnPairs["delivery_city"]))}</div> : ("")}

                        {getTagContent(documentDOM, columnPairs["delivery_country"]) !== '' ?
                            <div>{getTagContent(documentDOM, columnPairs["delivery_country"])}</div> : ("")}
                </Col>
                    : null}
                <Col sm={12}><hr/></Col>
            </Row></div>
                );
    }
}
