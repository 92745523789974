import React, {Component} from 'react';
import {BubbleLoader} from 'react-css-loaders';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../../global/SystemText";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import PaymentGateway from "./PaymentGateway";

export default class PaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            data: props.data,
            showPayment: props.showPayment,
            pbsVisible: true
        };

    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.showPayment === newProps.showPayment) {
            return null;
        } else {
            newState.showPayment = newProps.showPayment;
            newState.data = newProps.data;
            return newState;
        }
    }

    hidePayBySquarePanel() {
        this.setState({
            pbsVisible: false
        });
    }


    render() {

        let settings = window.portalSettings;
        if (this.state.showPayment) {
            if (this.state.loaded) {
                return (
                    <Modal size="lg" className={"payment-modal"}
                           aria-labelledby="contained-modal-title-vcenter"
                           centered show={this.state.showPayment} onHide={() => this.props.togglePayment(undefined)}>
                        <Modal.Header style={{background: settings.portal_pcolor}}>
                            <Modal.Title><label
                                style={{color: (PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}}
                                className="text-semibold">{ST.get("invoice_payment", "global")}</label></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={this.state.pbsVisible && this.state.data.invoice_paybysquare !== '' ? 8 : 12}>
                                    <div className="panel">
                                        <div className="panel-heading no-padding-bottom">
                                            <h6 className="panel-title">{ST.get("transfer_to_account", "global")}<a
                                                className="heading-elements-toggle"/></h6>
                                        </div>
                                        <div className="panel-body">
                                            {this.state.data.invoice_bank_account !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("account_number", "global")}:</Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_bank_account}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_iban !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("iban", "global")}:</Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_iban}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_swift !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("swift", "global")}:</Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_swift}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_vs !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("variable_symbol", "global")}:</Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_vs}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_ks !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("constant_symbol", "global")}: </Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_ks}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_ss !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("specific_symbol", "global")}: </Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_ss}</Col>
                                                </Row>
                                                : null}
                                            {this.state.data.invoice_to_pay !== '' ?
                                                <Row>
                                                    <Col md={5}>{ST.get("total_to_pay", "global")}:</Col>
                                                    <Col md={7}
                                                         className={"text-semibold"}>{this.state.data.invoice_to_pay}</Col>
                                                </Row>
                                                : null}
                                        </div>
                                    </div>
                                    <PaymentGateway id={this.state.data.invoice_id} />
                                </Col>

                                {this.state.data.invoice_paybysquare !== '' && this.state.pbsVisible ?
                                    <Col md={4}>
                                        <div className="panel">
                                            <div className="panel-heading no-padding-bottom">
                                                <h6 className="panel-title">Pay By Square<a
                                                    className="heading-elements-toggle"/></h6>
                                            </div>
                                            <div className="panel-body">
                                                <img className={"img-responsive"}
                                                     src={Session.getItem("endpoint") + this.state.data.invoice_paybysquare}
                                                     alt={"pay by square"}
                                                     onError={this.hidePayBySquarePanel.bind(this)}/>
                                            </div>

                                        </div>
                                    </Col>
                                    : null
                                }
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}}
                                    onClick={() => this.props.togglePayment(undefined)}
                                    className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
                        </Modal.Footer>
                    </Modal>
                );
            } else {
                return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
            }
        } else {
            return "";
        }
    }
}
