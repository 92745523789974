import React, { Component } from 'react';
import {Button, Col} from "react-bootstrap";
import Row from "react-bootstrap/lib/Row";
import QRCodeGuide from "./mobile/QRCodeGuide";


export default class MobileHomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: window.localStorage.getItem("mandants") ? [...JSON.parse(window.localStorage.getItem("mandants"))] : [],
        }
    }

    _scanBarCode = () => {
        window.cordova.plugins.barcodeScanner.scan(
            (result) => {
                let data = JSON.parse(result.text);

                let mandants = window.localStorage.getItem("mandants")
                if (mandants) {
                    mandants = JSON.parse(mandants);
                } else {
                    mandants = [];
                }
                if (!this._containsMandant(data)) {
                    mandants.push(data);
                }

                window.localStorage.setItem("mandants", JSON.stringify(mandants));

                this.setState({
                    data: mandants
                })
            },
            (error) => {
                navigator.notification.alert(
                    "Scanning failed: " + error,  // message
                    () => {
                    },         // callback
                    'Error',            // title
                    'Done'                  // buttonName
                );
            },
            {
                preferFrontCamera : false, // iOS and Android
                showFlipCameraButton : true, // iOS and Android
                showTorchButton : true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                saveHistory: false, // Android, save scan history (default false)
                prompt : "Place a barcode inside the scan area", // Android
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                disableAnimations : true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
        );
    }

    _containsMandant = (data) => {
        let mandants = window.localStorage.getItem("mandants");
        if (mandants) {
            mandants = JSON.parse(mandants);
            for (let i = 0; i < mandants.length; i++) {
                if (mandants[i].idMandant === data.idMandant && mandants[i].instance === data.instance) {
                    return true;
                }
            }
        }

        return false;
    }

    _redirect = (instance, idMandant) => {
        this.props.history.replace("/" + instance + "/" + idMandant);
    }

    render() {
        return (
            <div style={{width: "100%", textAlign: "center", padding: "5px"}}>
                <Button className="mobile-qr-button" onClick={this._scanBarCode}>
                    <img src="assets/css/icons/svg/barcode.svg" style={{width: "200px"}}/><br/>
                    <i className="icon icon-camera" style={{fontSize: "30px"}}></i>
                </Button>
                {this.state.data.length === 0 ?
                    <QRCodeGuide/>
                    :
                    <div>
                        {this.state.data.map((entry, index) => {
                            return (
                                <Button key={index} className="mobile-homepage-card-button" onClick={() => this._redirect(entry.instance, entry.idMandant)}>
                                    <Row style={{display: "flex", width: "100%", height: "40px"}}>
                                        <Col style={{top: 0, bottom: 0, marginTop: "auto", marginBottom: "auto"}} md={4}>
                                            <img style={{maxWidth: "120px", maxHeight: "35px"}}
                                                 src={"https://" + entry.instance + ".offeris.com/" + entry.instance + "/" + "PictureServlet?id=" + entry.idMandant + "&action=GET_MANDANT_LOGO"}
                                                 className="img-responsive center-block" alt="mandant_logo"/>
                                        </Col>
                                        <Col style={{top: 0, bottom: 0, marginTop: "auto", marginBottom: "auto"}} md={8}>
                                            {entry.mandantName}
                                        </Col>
                                    </Row>
                                </Button>
                            )
                        })}
                    </div>
                }
            </div>
        );

    }
}