import React, {Component} from 'react';
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import Breadcrumbs from "../Breadcrumbs";
import {ST} from "../../global/SystemText";
import {Link} from "react-router-dom";
import {BubbleLoader} from "react-css-loaders";

export default class Wishlists extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {}
        };
    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                idContact: Session.getItem("idContact"),
                idCompany: Session.getItem("idCompany"),
                action:"GET_WISHLISTS"

            }
        })
            .then((response) => {
                console.log(response.data);
                this.setState({data: response.data, loaded: true})
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if (this.state.loaded) {
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs type={"custom"}  active={ST.get("wishlists_list","global")}/>
                    <div className="panel-heading">
                        <h1 className="panel-title text-semibold">
                            {ST.get("wishlists_list", "global")}
                        </h1>
                    </div>
                    <div className="panel-body ">
                        <div className="table-responsive">
                            <table className="table table-lg">
                                <thead>
                                <tr>
                                    <th>{ST.get("number")}</th>
                                    <th>{ST.get("creation_date")}</th>
                                    <th>{ST.get("customer")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map((element,index)=>{
                                    return(
                                        <tr key={index} style={{background:element.displayed===0?window.portalSettings.portal_pcolor+"0d":"transparent"}}>
                                            <td><b><Link to={Session.getItem("basename")+"/wishlist/"+element.id}>{element.number}</Link></b></td>
                                            <td>{element.date}</td>
                                            <td>{element.name}, {element.phone}, {element.email} - {element.city}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            )
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}




