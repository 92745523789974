import React, {Component} from 'react';
import {BubbleLoader} from 'react-css-loaders';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../../global/SystemText";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import axios from "axios";
import comgate from "../../images/comgate.png";

export default class PaymentGateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:props.id,
            allowed: false,
            hasLink:false,
            link:"",
            gatewayName:"",
            gatewayType:''
        };

    }

    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                id: this.state.id,
                idContact: Session.getItem("idContact"),
                action: "GET_PAYMENT_GATEWAYS",

            }
        })
            .then((response) => {
                console.log(response.data);
                if(response.data.allowed) {
                    this.setState({allowed: response.data.allowed,hasLink: response.data.hasLink,link: response.data.link,gatewayName:response.data.name,gatewayType:response.data.type})
                }
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    createLink(){
        /*
        console.log(Session.idMandant);
        console.log(this.state.id);
        console.log(this.state.gatewayType);
        console.log(Session.getItem("idContact"));

         */
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                id: this.state.id,
                type: this.state.gatewayType,
                idContact: Session.getItem("idContact"),
                action: "GET_CREATE_PAYMENT_URL"

            }
        })
            .then((response) => {
                console.log(response.data);
                let url=response.data.redirect;
                window.location=url;
            })
            .catch((error) => {
                console.log(error.data);
                //PT.handleError(error, this.props.history);
            });


    }

    render(){
        let img=comgate;
        if(this.state.gatewayType==="COMGATE"){
            img=comgate;
        }
        if(this.state.allowed) {
            return (
                <div className="panel">
                    <div className="panel-heading no-padding-bottom">
                        <h6 className="panel-title">Online<a className="heading-elements-toggle"/>
                        </h6>
                    </div>
                    <div className="panel-body">
                        <Row>
                            <Col md={12}>
                                <img src={img} className={"img-responsive"}/>
                            </Col>
                            <Col className={"center-block"} md={12}>
                        {this.state.hasLink?
                            <Button className="btn-primary" onClick={() => window.location=this.state.link}>{ST.get("pay", "global")}</Button>
                            :
                            <Button className="btn-primary" onClick={() =>this.createLink() }>{ST.get("pay", "global")}</Button>
                        }
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }else {
            return (<div></div>);
        }
    }
}