import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {Button, Col, FormControl, FormGroup, Row} from "react-bootstrap";
import * as PT from "../../global/PortalTools";
import {Session} from "../../global/PortalTools";
import Breadcrumbs from "../Breadcrumbs";
import {ST} from "../../global/SystemText";

import "react-datetime/css/react-datetime.css";
import CustomerHead from "./CustomerHead";
import CustomerRow from "./CustomerRow";
import ContactModal from "./ContactModal";

export default class CustomerList extends Component {
    constructor(props) {
        super(props);
        let showOnly = '';

        let filter = PT.Session.getItem(this.props.match.params.type + "Filter");

        this.state = {
            loaded: false,
            type: this.props.match.params.type,
            page: 1,
            paginationLimit: 5,
            settings: {},
            data: {},
            show_count: 50,
            columnsAmount: 6,
            showOnly: showOnly,
            loadedDocument: "",
            filter: filter ? JSON.parse(filter) : CustomerList.getDefaultFilter()
        };
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.document === newProps.match.params.document) {
            return null;
        } else {
            let filter = PT.Session.getItem(newProps.match.params.type + "Filter");
            newState.type = newProps.match.params.type;
            newState.loaded = false;
            newState.page = 1;
            newState.filter = filter ? JSON.parse(filter) : CustomerList.getDefaultFilter();
            return newState;
        }
    }

    async componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getCustomerList();
            window.scrollTo(0, 0)
        }
    }

    onContactAdd = () => {
        this.getCustomerList();
    }

    getCustomerList() {
        let action = "";
        if(this.state.type === 'company') {
            action = "GET_COMPANIES";
        } else if(this.state.type === 'contact'){
            action = "GET_CONTACTS";
        } else {
            //TODO - presmerovanie na 404 pokial vojdem sem
            return;
        }
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idDealer: Session.getItem("idDealer"),
                showOnly: this.state.show_count,
                page: this.state.page,
                filter: JSON.stringify(this.state.filter),
                action: action
            }
        })
            .then((response) => {
                this.setState({
                    loaded: response.data.type === this.state.type,
                    data: response.data,
                    page: response.data.page,
                    show_count: Number(response.data.show_count)
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    async componentDidMount() {
        this.getCustomerList();
    }

    getTitleByDocument() {
        if (this.state.type === 'company') {
            return ST.get("companies", "global");
        } else if (this.state.type === 'contact') {
            return ST.get("people", "global");
        }
    }

    canSeeList(document) {
        return document === this.state.type;

    }

    documentHasData() {
        return this.state.data.customers.length > 0;
    }

    submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            this.submitFilter();
        }
    }

    getDocumentFilter() {
        let name_title = ST.get("title", "global");
        if(this.state.type === "company") {
            name_title = ST.get("company_name", "company");
        } else if (this.state.type === 'contact') {
            name_title = ST.get("name", "person");
        }
        return (
            <div className={"p-5"}>
                <Row>
                    <Col sm={2}>
                        <Row>
                            <Col sm={12}>
                                {name_title}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup controlId={"name"}>
                                    <FormControl
                                        type={"text"}
                                        onChange={(event) => this.setFilterValue('name', event.target.value)}
                                        onKeyUp={this.submitOnEnter}
                                        value={this.state.filter.name}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={2}>
                        <Row>
                            <Col sm={12}>
                                {ST.get("city", "person")}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup controlId={"city"}>
                                    <FormControl
                                        type={"text"}
                                        onChange={(event) => this.setFilterValue('city', event.target.value)}
                                        onKeyUp={this.submitOnEnter}
                                        value={this.state.filter.city}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {this.state.type === 'company' ? (
                        <Col sm={2}>
                            <Row>
                                <Col sm={12}>
                                    {ST.get("ico", "company")}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup controlId={"ico"}>
                                        <FormControl
                                            type={"text"}
                                            onChange={(event) => this.setFilterValue('ico', event.target.value)}
                                            onKeyUp={this.submitOnEnter}
                                            value={this.state.filter.ico}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    ) : null }
                    {this.state.type === 'contact' ? (
                        <Col sm={2}>
                            <Row>
                                <Col sm={12}>
                                    {ST.get("company_name", "company")}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup controlId={"company_name"}>
                                        <FormControl
                                            type={"text"}
                                            onChange={(event) => this.setFilterValue('company_name', event.target.value)}
                                            onKeyUp={this.submitOnEnter}
                                            value={this.state.filter.company_name}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    ) : null }
                    <Col sm={3}>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2}>&nbsp;</Col>
                            <Col xs={5}>
                                <Button onClick={this.submitFilter}>{ST.get("filter", "global")}</Button>
                            </Col>
                            <Col xs={5}>
                                <Button onClick={this.cancelFilter}>{ST.get("cancel", "global")}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.type === 'contact' ? (
                <Row>
                    <Col>
                        <ContactModal history={this.props.history} callback={this.onContactAdd}>
                            <Button className={"ml-10 mt-10"} bsStyle={"success"}>{ST.get("add_person", "global")}</Button>
                        </ContactModal>
                    </Col>
                </Row>
                    ) : null }
            </div>
        );
    }

    static getDefaultFilter = () => {
        return {name: '', ico: '', company_name: '', city: ''};
    };

    isFilterSet = (filter) => {
        let defaultFilter = CustomerList.getDefaultFilter();
        let result = false;
        let keys = Object.keys(defaultFilter);
        keys.forEach((key) => {
            result = result || defaultFilter[key] !== filter[key]
        });
        return result;
    };

    submitFilter = () => {
        let {filter, type} = this.state;
        PT.Session.setItem(type + "Filter", JSON.stringify(filter));
        this.getCustomerList();
    };

    cancelFilter = () => {
        let {type} = this.state;
        let filter = CustomerList.getDefaultFilter();
        let page = 1;
        PT.Session.setItem(type + "Filter", JSON.stringify(filter));
        this.setState({filter, page}, () => this.getCustomerList());
    };

    setFilterValue(key, value) {
        let {filter} = this.state;
        filter[key] = value;
        this.setState({filter});
    }

    render() {
        let pages = Math.ceil(Number(this.state.data.customer_count) / this.state.show_count);
        //FIXME - prerobit tieto strankovania aby nevypisovali vsetky strany, ale len par, napr. 5 pred a za stranou co teraz vidim
        let paginations = [];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }

        if (this.state.loaded) {
            if (this.documentHasData()) {
                return (
                    <div>
                        <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                            <Breadcrumbs active={this.getTitleByDocument()}/>
                            <div className="panel-heading">
                                <h1 className="panel-title">
                                    {this.getTitleByDocument()}
                                </h1>
                            </div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    {this.getDocumentFilter()}
                                    <table className="table table-lg table-striped table-hover">
                                        <CustomerHead type={this.state.type}/>
                                        {this.canSeeList(this.state.type) ?
                                            <tbody>
                                            {this.state.data.customers.map((row, index) => {
                                                return (
                                                    <CustomerRow dataIndex={index}
                                                                 key={"row" + index}
                                                                 type={this.state.type}
                                                                 history={this.props.history}
                                                                 data={row}/>)
                                            })}
                                            </tbody> : null
                                        }
                                    </table>
                                    <hr/>
                                </div>
                                <div className="text-center content-group-lg pt-20">
                                    <ul className="pagination">
                                        <li className={this.state.page === 1 ? "disabled" : ""}><Button
                                            disabled={this.state.page === 1} onClick={() => {
                                            this.setState({page: (this.state.page - 1), loaded: false})
                                        }} size={"sm"} variant={"light"}><i className="icon-arrow-left15"/></Button>
                                        </li>
                                        {paginations.map((element, index) => {
                                            return (element);
                                        })}
                                        <li className={this.state.page === pages ? "disabled" : ""}>
                                            <Button disabled={this.state.page === pages} onClick={() => {
                                                this.setState({page: (this.state.page + 1), loaded: false})
                                            }} size={"sm"} variant={"light"}><i
                                                className="icon-arrow-right15"/></Button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                            <Breadcrumbs active={this.getTitleByDocument()}/>
                            <div className="panel-heading">
                                <h1 className="panel-title">
                                    {this.getTitleByDocument()}
                                </h1>
                            </div>
                            <div className="panel-body">
                                {this.getDocumentFilter()}
                            </div>
                            <div className="panel-body" style={{
                                background: window.portalSettings.portal_pcolor + "4e",
                                fontSize: "17px",
                                fontWeight: "500"
                            }}>
                                <Row>
                                    <Col md={12} style={{padding: "25px"}}>
                                        {ST.get("no_records_found", "global")}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
