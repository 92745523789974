import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';

import * as PT from "./global/PortalTools";
import {Session} from "./global/PortalTools";

export default class MandantText extends Component {
    constructor(props) {
        super(props);
        this.state={
            loaded:false,
            data:{},
            type: props.type
        }
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newProps.type === newState.type) {
            return null;
        } else {
            newState.type = newProps.type;
            newState.loaded = false;
            return newState;
        }
    }

    componentDidMount() {
        this.getMandantText();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.type !== prevState.type) {
            this.getMandantText();
        }
    }

    getMandantText(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
            idMandant: Session.idMandant,
                type: this.props.type,
                idLanguage:this.props.settings.idLanguage===undefined || this.props.settings.idLanguage===0 ?Session.getItem("idLanguage"):this.props.settings.idLanguage,
                action: "GET_PORTAL_MANDANT_TEXT"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if(this.state.loaded) {
            if (this.state.data.text !== "") {
                return (
                    <div className="panel border-top-xlg" style={{borderColor: this.props.settings.portal_pcolor}}>
                        <div className="panel-heading">
                            <h1 className="panel-title">
                                {this.state.data.name}
                            </h1>
                        </div>

                        <div className="panel-body">
                            <Row>
                                <Col md={12}>
                                    <div className="pl-10 text-justify" dangerouslySetInnerHTML={{__html:this.state.data.text}}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
            }else{
                return null
            }

        }else{
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>)
        }
    }
}
