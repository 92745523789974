import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import avatar from "../images/avatar.png";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class RelatedPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {}
        }

    }

    componentDidMount() {
        this.getRelatedDocuments();
    }


    getRelatedDocuments() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params:
                {
                    idMandant: Session.idMandant,
                    idContact: Session.getItem("idContact"),
                    idCompany: Session.getItem("idCompany"),
                    document: this.props.source,
                    id: this.props.id,
                    action: "GET_RELATED_DOCUMENTS"
                }
        })
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }


    render() {
        if (this.state.loaded) {
            return (
                <div className="sidebar-category">
                    <div className="category-title" style={{background: window.portalSettings.portal_pcolor + "0d"}}>
                        <span>Suvisiace dokumenty</span>
                    </div>
                    <Row>
                        <Col md={12}>
                            <ul className="media-list">
                                {this.state.data.quotations && this.state.data.quotations.length > 0 ?
                                    this.state.data.quotations.map((element, index) => {
                                        return (<li className="media no-margin-top pt-5" key={index}>
                                            <div className="media-body pl-20">
                                                <div className="media-heading">
                                                    <div className="text-semibold">
                                                        <Link
                                                            to={Session.getItem("basename") + "/quotation/" + element.id}><i className="icon-bubbles position-left"/> {element.number}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)
                                    })
                                    : null
                                }

                                {this.state.data.orderins && this.state.data.orderins.length > 0 ?
                                    this.state.data.orderins.map((element, index) => {
                                        return (<li className="media no-margin-top pt-5" key={index}>
                                            <div className="media-body pl-20">
                                                <div className="media-heading">
                                                    <div className="text-semibold">
                                                        <Link
                                                            to={Session.getItem("basename") + "/orderin/" + element.id}><i className="icon-cart position-left"/> {element.number}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)
                                    })
                                    : null
                                }
                                {this.state.data.invoices && this.state.data.invoices.length > 0 ?
                                    this.state.data.invoices.map((element, index) => {
                                        return (<li className="media no-margin-top pt-5" key={index}>
                                            <div className="media-body pl-20">
                                                <div className="media-heading">
                                                    <div className="text-semibold">
                                                        <Link
                                                            to={Session.getItem("basename") + "/invoice/" + element.id}><i className="icon-wallet position-left"/> {element.number}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)
                                    })
                                    : null
                                }
                                {this.state.data.warehouses && this.state.data.warehouses.length > 0 ?
                                    this.state.data.warehouses.map((element, index) => {
                                        return (<li className="media no-margin-top pt-5" key={index}>
                                            <div className="media-body pl-20">
                                                <div className="media-heading">
                                                    <div className="text-semibold">
                                                        <Link
                                                            to={Session.getItem("basename") + "/warehouse/" + element.id}><i className="icon-box position-left"/> {element.number}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)
                                    })
                                    : null
                                }
                            </ul>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
