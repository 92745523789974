import React, {Component} from 'react';
import {Button, Col, FormControl, Row} from "react-bootstrap";
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import avatar from '../images/avatar.png'
import avatarMan from '../images/businessman.png'
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Scrollbars} from "react-custom-scrollbars";
import avatarWoman from "../images/businesswoman.png";

export default class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            expand: true,
            newMessage: "",
            newName: "",
            error: false
        }

    }

    componentDidMount() {
        this.interval = setInterval(() => this.getChat(), 30000);
        this.getChat();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getChat() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                id: this.props.id,
                document: this.props.document,
                action: "GET_DOCUMENT_CHAT"
            }
        })
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    handleChangeMessage(event) {
        this.setState({
            newMessage: event.target.value
        });
    }

    handleChangeName(event) {
        this.setState({
            newName: event.target.value
        });
    }

    sendChat() {
        let username = this.state.newName;
        if (Session.getItem("portal_type") === "2") {
            username = Session.getItem("contactName");
        }
        if (this.state.newMessage !== "" && username !== "") {
            axios.post(Session.getItem("endpoint") + 'PortalServlet', {
                idMandant: Session.idMandant,
                idDocument: this.props.id,
                typeDocument: this.props.document,
                message: this.state.newMessage,
                name: username,
                idContact: Session.getItem("portal_type") === "2"?Session.getItem("idContact"):"0",
                action: "SEND_DOCUMENT_CHAT"
            },{headers: {'Content-type': 'application/json; charset=utf-8',
                }})
                .then((response) => {
                    this.getChat();
                    this.setState({
                        newMessage: "",
                        error: false
                    })
                })
                .catch((error) => {
                    PT.handleError(error, this.props.history);
                })
        } else {
            this.setState({
                error: true
            });
        }
    }

    render() {
        let avatarContact;
        if (Session.getItem("contactGender") === "2" || Session.getItem("contactGender") === "3"){
            avatarContact = avatarWoman;
        } else { avatarContact = avatarMan; }
        if (this.state.loaded) {
            return (

                <div className="sidebar-category mt-20">

                    <div className="category-title">
                        <span>{ST.get("chat", "global")}</span>
                        <ul className="icons-list">
                            <li><i onClick={() => this.setState({expand: !this.state.expand})}
                                   className={"icon icon-chevron-" + (this.state.expand ? "up" : "down") + " cursor-pointer"}/>
                            </li>
                        </ul>
                    </div>

                    <ul className="chat media-list media-list-linked p-10"
                        style={!this.state.expand ? {display: "none"} : {}}>
                        <Scrollbars autoHeight autoHeightMax={800} >
                            <div className="p-5" >
                                {this.state.data.map((element, index) => (
                                    <li className="media" key={index}>
                                        {(String(element.document_chat_contact_id) === Session.getItem("idContact"))||String(element.document_chat_person)!=="" ?

                                            <div>
                                                <Row>
                                                    <Col md={6}>
                                                        <span className="media-annotation  pull-left text-size-small">{element.formatedDate}</span>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="media-heading pull-right pr-5">
                                                            {element.source === 0 ?
                                                                <span
                                                                    className="text-semibold text-size-large">{element.oIntrauser.intrauser_name}</span>
                                                                : element.source === 1 ?
                                                                    <span
                                                                        className="text-semibold text-size-large">{element.oContact.contact_name}</span>
                                                                    : <span
                                                                        className="text-semibold text-size-large">{element.document_chat_person}</span>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} className="pl-20">
                                                        <div className="pull-right speech-bubble-right" style={{borderColor:this.props.settings.portal_pcolor,background:this.props.settings.portal_pcolor,color: PT.getColorBrightness(this.props.settings.portal_pcolor)< 135 ? "white" : "#333"}} >
                                                            {element.document_chat_text}
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="media-right pull-right">
                                                            {element.source === 0 ?
                                                                element.oIntrauser.hasAvatar ?
                                                                    <img
                                                                        src={Session.getItem("endpoint") + "PictureServlet?id=" + element.oIntrauser.intrauser_id + "&action=GET_INTRAUSER_LOGO"}
                                                                        className="chat-logo" alt="avatar"/>
                                                                    : <img src={avatar} className="chat-logo" alt="avatar"/>
                                                                : <img src={avatarContact} className="chat-logo" alt="avatar"/>}
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                            :
                                            <div>

                                                <div className="media-heading">
                                                    {element.source === 0 ?
                                                        <span
                                                            className="text-semibold text-size-large">{element.oIntrauser.intrauser_name}</span>
                                                        : element.source === 1 ?
                                                            <span
                                                                className="text-semibold text-size-large">{element.oContact.contact_name}</span>
                                                            : <span
                                                                className="text-semibold text-size-large">{element.document_chat_person}</span>}
                                                    <span
                                                        className="media-annotation pull-right text-size-small">{element.formatedDate}</span>
                                                </div>

                                                <div className="media-left">
                                                    {element.source === 0 ?
                                                        element.oIntrauser.hasAvatar ?
                                                            <img
                                                                src={Session.getItem("endpoint") + "PictureServlet?id=" + element.oIntrauser.intrauser_id + "&action=GET_INTRAUSER_LOGO"}
                                                                className="chat-logo" alt="avatar"/>
                                                            : <img src={avatar} className="chat-logo" alt="avatar"/>
                                                        : <img src={avatar} className="chat-logo" alt="avatar"/>}
                                                </div>
                                                <div className="media-body pr-20">
                                                    <div className="speech-bubble-left" style={{borderColor:this.props.settings.portal_pcolor+"4e",background:this.props.settings.portal_pcolor+"4e"}}>
                                                        {element.document_chat_text}
                                                    </div>
                                                </div>

                                            </div>



                                        }

                                    </li>
                                ))}
                            </div>

                        </Scrollbars>
                        <li className="media new-message">
                            <Row>
                                <Col md={12}
                                     className={this.state.error && this.state.newMessage === "" ? "has-error" : ""}>
                                    <FormControl as="textarea" value={this.state.newMessage}
                                                 placeholder={ST.get("global_message", "global")}
                                                 onChange={this.handleChangeMessage.bind(this)}/>
                                </Col>
                                {Session.getItem("portal_type") === "2" ?
                                    <Col md={7}>
                                        <FormControl value={Session.getItem("contactName") || ""} disabled/>
                                    </Col>
                                    :
                                    <Col md={7}
                                         className={this.state.error && this.state.newName === "" ? "has-error" : ""}>
                                        <FormControl placeholder={ST.get("name", "person")} value={this.state.newName}
                                                     onChange={this.handleChangeName.bind(this)}/>
                                    </Col>
                                }

                                <Col md={5}>
                                    <Button className="mt-5 pull-right" onClick={this.sendChat.bind(this)} size="sm"
                                            variant="primary">{ST.get("mail_send", "global")}</Button>
                                </Col>
                            </Row>
                        </li>

                    </ul>

                </div>
            );
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
