import React, {Component} from 'react';
import axios from "axios";
import {ST} from "./global/SystemText";
import * as PT from "./global/PortalTools";
import {Session} from "./global/PortalTools";

export default class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state={
            title:""
        }
    }
    componentDidMount() {
        this.getDocumentTitle();
    }

    getDocumentTitle(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {idMandant:Session.idMandant,id:this.props.id,document:this.props.document, action: "GET_PORTAL_DOCUMENT_TITLE"}})
            .then((response) => {
                this.setState({title: response.data.title},()=>{
                    document.title=response.data.title+" - "+response.data.mandantName;

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    render() {
        return (
            <div className="navbar bg-white mb-20" >
                <div className="container">
                <div className="navbar-header">
                    <span className="navbar-brand">
                        <img className="portal-logo" src={Session.getItem("endpoint")+"PictureServlet?id="+Session.idMandant+"&action=GET_MANDANT_LOGO"} alt="portal logo"/>
                    </span>

                </div>
                <div className="navbar-collapse collapse" id="navbar-mobile">
                    <ul className="nav navbar-nav navbar-right portal-topmenu">
                        <li style={this.props.active==="document" ? {borderBottom:"2px solid "+this.props.settings.portal_pcolor}:{}}><a onClick={()=>this.props.changePanel("document")}>{this.state.title}</a></li>
                        {this.props.settings.portal_cms_terms===undefined || this.props.settings.portal_cms_terms ?
                            <li style={this.props.active==="terms" ? {borderBottom:"2px solid "+this.props.settings.portal_pcolor}:{}}>
                                <a onClick={()=>this.props.changePanel("terms")}>Obchod</a>

                            </li>
                            : ""}
                        {this.props.settings.portal_cms_reference===undefined || this.props.settings.portal_cms_reference ? <li style={this.props.active==="reference" ? {borderBottom:"2px solid "+this.props.settings.portal_pcolor}:{}}><a onClick={()=>this.props.changePanel("reference")}>{ST.get("reference","global")}</a></li> : ""}
                        {this.props.settings.portal_cms_contact===undefined || this.props.settings.portal_cms_contact ? <li style={this.props.active==="contact" ? {borderBottom:"2px solid "+this.props.settings.portal_pcolor}:{}}><a onClick={()=>this.props.changePanel("contact")}>{ST.get("contact","global")}</a></li> : ""}

                    </ul>
                </div>
                </div>
            </div>
        );
    }
}
