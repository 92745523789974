import React, {Component} from 'react';
import {ST} from "../global/SystemText";

import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import axios from "axios";

export default class ProductWarehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            idType: props.idType,
            loaded: false,
            data: {}
        }
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.id === newProps.id && newState.idType === newProps.idType) {
            return null;
        } else {
            newState.id = newProps.id;
            newState.idType = newProps.idType;
            newState.loaded = false;
            newState.data = {};
            return newState;
        }
    }

    componentDidMount() {
        this.getProductWarehouse();
    }

    componentDidUpdate() {
        if (!this.state.loaded) {
            this.getProductWarehouse();
        }
    }

    getProductWarehouse() {
        if(this.props.data===undefined) {
            //console.log("citam warehouse")
            axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    id: this.state.id,
                    idType: this.state.idType,
                    idLanguage: Session.getItem("idLanguage"),
                    action: "GET_PORTAL_PRODUCTWAREHOUSE"
                }
            })
                .then((response) => {
                    this.setState({
                        loaded: true,
                        data: response.data

                    });
                })
                .catch((error) => {
                    PT.handleError(error, this.props.history);
                })
        }else{
            this.setState({
                loaded: true,
                data: this.props.data

            });
        }
    }

    render() {
        if (this.state.loaded) {
            return (
                Number(window.portalSettings.warehouse_status_see) > 0 ?
                    <span>
                        {this.state.data.available > 0 ?
                            <span>
                                {this.props.noLabel?null:
                                    <span>{ST.get("product_availability", "product")} :&nbsp;</span>}
                                <strong className={"product-warehouse-available"} style={{color: "green"}}>
                                    <i className={"icon icon-truck text-success position-left"}></i>{ST.get("in_stock", "portal")}&nbsp;{Number(window.portalSettings.warehouse_status_see) ===1? "("+this.state.data.warehouseText+")":""}
                                </strong>
                            </span>
                            :
                            <span>
                                 {this.props.noLabel?null:
                                     <span>
                                {ST.get("delivery_date", "product")} :&nbsp;</span>}
                                <strong className={"product-warehouse-unavailable"}>
                                        <span>
                                        <i className={"icon icon-truck position-left"}></i>{this.state.data.warehouseText !== "" ? this.state.data.warehouseText : ST.get("contact_dealer", "portal")}
                                        </span>
                                </strong>
                            </span>
                        }
                    </span>
                    :
                    <span></span>

            );
        } else {
            return (<strong>&nbsp;</strong>)
        }
    }
}
