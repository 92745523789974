import React, {Component} from "react";
import {ST} from "../../global/SystemText";

export default class DocumentsHead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            document: props.document
        };
    }


    render() {
        let {document} = this.state;

        let header;

        switch (document) {
            case "quotation":
                header = [
                    "number",
                    "creation_date",
                    "valid_date_to",
                    "address",
                    "state",
                    "price_incl_vat",
                    "price_excl_vat",
                    "orderins"
                ];
                break;

            case "orderin":
                header = [
                    "number",
                    "creation_date",
                    "address",
                    "state",
                    "price_incl_vat",
                    "price_excl_vat",
                    "invoices",
                    "warehouses"
                ];
                break;

            case "invoice":
                header = [
                    "number",
                    "creation_date",
                    "address",
                    "variable_symbol",
                    "invoice_payment_parts_rest",
                    "price_incl_vat",
                    "price_excl_vat",
                    "",
                    "warehouses"
                ];
                break;

            case "orderout":
                header = [
                    "number",
                    "creation_date",
                    "valid_date_to",
                    "address",
                    "state",
                    "price_incl_vat",
                    "price_excl_vat",
                    "orderins"
                ];
                break;

            case "warehouse":
                header = [
                    "number",
                    "creation_date",
                    "address",
                    "price_incl_vat",
                    "price_excl_vat",
                    "invoices",
                    "orderins"
                ];
                break;
            case "claim":
                header = [
                    "number",
                    "creation_date",
                    "invoice",
                    "item",
                    "state"
                ];
                break;

            default:
                header = [];
                break;
        }


        if( !Boolean(window.portalSettings.show_prices)){
            let newColumns = [];
            header.forEach((col, index, array) => {
                if(col.includes("price") || col === 'invoice_payment_parts_rest'){
                    return;
                }
                newColumns.push(col);
            });
            header = newColumns;
        }

        return (
            <thead>
            <tr>
                {header.map((col) => {
                    if (col === 'invoices' && !Number(window.portalSettings.invoice_access) > 0) return null;
                    if (col === 'orderins' && !Number(window.portalSettings.orderin_access) > 0) return null;
                    if (col === 'warehouses' && !Number(window.portalSettings.warehouse_access) > 0) return null;

                    if (col === "number" || col === "number" || col==="address") {
                        return (<th className={"text-left"} key={"head" + col} style={{minWidth: "100px"}}>{ST.get(col, "global")}</th>);
                    }
                    if (col === 'state' || col.includes("date")){
                        return (<th className={"text-center"} key={"head" + col} style={{maxWidth: "150px", minWidth: "80px"}}>{col !== "" ? ST.get(col, "global") : ""}</th>);
                    }
                    return (<th className={"text-right"} key={"head" + col} style={{maxWidth: "150px", minWidth: "80px"}}>{col !== "" ? ST.get(col, "global") : ""}</th>);
                })}
            </tr>
            </thead>
        );
    }
}