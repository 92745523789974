import React, { Component } from 'react';
import {BubbleLoader} from 'react-css-loaders';
export default class E404 extends Component {
    constructor() {
        super();
        let instance = window.localStorage.getItem("instance");
        let idMandant = window.localStorage.getItem("idMandant");
        if(instance!==null && idMandant!==null){
            //window.location="/"+instance+"/"+idMandant;
        }else{
            window.location="https://b2b.offeris.com";
        }
        this.state = {
            loaded: false
        }
    }

    render() {

            return (
                <div>404</div>
            );
        }
}