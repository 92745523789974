import React, {Component} from "react";
import {getTagContent} from "../XMLTools";
import {ST} from "../../global/SystemText";

export default class SubcountItem extends Component {
    render() {
        let {document, columns, item} = this.props;
        let cols;

        switch(document) {
            case "quotation":
                cols = {
                    item_number: "qi_number",
                    item_id: "qi_id",
                    item_name_without_model: "qi_name_without_model",
                    item_amount: "qi_amount",
                    item_country_of_origin: "qi_producer_country",
                    item_delivery_date: "qi_delivery",
                    item_description: "qi_description",
                    item_discount: "qi_discount",
                    item_model: "qi_product_model",
                    item_name_with_model: "qi_name",
                    item_order_our: "qi_internal_order_our",
                    item_picture: "qi_picture",
                    item_price: "qi_price",
                    item_priceafterdiscount: "qi_price_one",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "qi_final_price",
                    item_price_incl_vat: "qi_final_price_vat",
                    item_price_total: "qi_total_price",
                    item_producer: "qi_producer_name",
                    item_vat: "qi_vat",
                    item_warranty: "qi_warranty",
                    item_weight: "qi_weight"
                };break;

            case "orderin":
                cols = {
                    item_number: "oi_number",
                    item_id: "oi_id",
                    item_name_without_model: "oi_name_without_model",
                    item_amount: "oi_amount",
                    item_country_of_origin: "oi_producer_country",
                    item_delivery_date: "oi_delivery",
                    item_description: "oi_description",
                    item_discount: "oi_discount",
                    item_model: "oi_product_model",
                    item_name_with_model: "oi_name",
                    item_order_our: "oi_internal_order_our",
                    item_picture: "oi_picture",
                    item_price: "oi_price",
                    item_priceafterdiscount: "oi_price_one",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "oi_final_price",
                    item_price_incl_vat: "oi_final_price_vat",
                    item_price_total: "oi_total_price",
                    item_producer: "oi_producer_name",
                    item_vat: "oi_vat",
                    item_warranty: "oi_warranty",
                    item_weight: "oi_weight",
                };break;

            case "invoice":
                cols = {
                    item_number: "ii_number",
                    item_id: "ii_id",
                    item_name_without_model: "ii_name_without_model",
                    item_amount: "ii_amount",
                    item_country_of_origin: "ii_producer_country",
                    item_delivery_date: "ii_delivery",
                    item_description: "ii_description",
                    item_discount: "ii_discount",
                    item_model: "ii_product_model",
                    item_name_with_model: "ii_name",
                    item_order_our: "product_order_our",
                    item_picture: "none", //nema obrazok
                    item_price: "ii_price",
                    item_priceafterdiscount: "ii_price_mj_after_discount",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "ii_final_price",
                    item_price_incl_vat: "ii_final_price_inclvat",
                    item_price_total: "ii_total_price",
                    item_producer: "ii_producer_name",
                    item_vat: "ii_vat",
                    item_warranty: "ii_warranty",
                    item_weight: "ii_weight",
                };break;

            case "orderout":
                cols = {
                    item_number: "oi_number",
                    item_id: "oi_id",
                    item_name_without_model: "oi_name_without_model",
                    item_amount: "oi_amount",
                    item_country_of_origin: "oi_producer_country",
                    item_delivery_date: "oi_delivery",
                    item_description: "oi_description",
                    item_discount: "oi_discount",
                    item_model: "oi_product_model",
                    item_name_with_model: "oi_name",
                    item_order_our: "product_order_our",
                    item_picture: "oi_picture",
                    item_price: "oi_price",
                    item_priceafterdiscount: "oi_price_mj_after_discount",
                    item_pricediscount: "descriptionTitle",//----
                    item_price_final: "oi_final_price",
                    item_price_incl_vat: "oi_final_price_inclvat",
                    item_price_total: "oi_total_price",
                    item_producer: "oi_producer_name",
                    item_vat: "oi_vat",
                    item_warranty: "oi_warranty",
                    item_weight: "oi_weight",
                };break;

            default:
                cols = {}; break;
        }
        if(getTagContent(item, "hide_portal")==="T"){
            return null;
        }
        return (
            <tbody>
            <tr className={"subcount"}>
                {/*<td colSpan={this.props.columns.length}>medzisucet</td>*/}
                {columns.map((element, index) => {
                    if (element === "item_name_without_model" || element === "item_name_with_model") {
                        return (<td key={"col_" + index} className="text-semibold">
                            {item.getElementsByTagName(cols[element])[0].textContent}
                        </td>);
                    }
                    if (element === "item_price_incl_vat") {
                        return (<td key={"col_" + index} className="text-semibold text-right">
                            {item.getElementsByTagName(cols[element])[0].textContent}&nbsp;{item.getElementsByTagName("currencyCode")[0].textContent.replace(/eur/ig, "€")}
                        </td>);
                    }
                    if (String(element).includes("price") && element !== 'item_price' && element !== 'item_priceafterdiscount') {
                        return (<td key={"col_" + index} className="text-right" >
                            {item.getElementsByTagName(cols[element])[0].textContent.replace(/ /ig,'\u00a0')}&nbsp;{item.getElementsByTagName("currencyCode")[0].textContent.replace(/eur/ig, "€")}
                        </td>);
                    }
                    return <td className="" key={"col_" + index}>&nbsp;</td>;
                })}

            </tr>
            </tbody>
        )
    }
}