import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import avatar from "../images/avatar.png";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Button} from "react-bootstrap";

export default class Dealer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            expand: true
        }

    }

    componentDidMount() {

        this.getDealer();
    }


    getDealer() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {idMandant: Session.idMandant, id: this.props.id, action: "GET_PORTAL_DEALER"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    logoutCustomer = () => {
        Session.setItem("idContact", "");
        Session.setItem("idCompany", "");
        Session.setItem("contactName", "");
        Session.setItem("contactGender", "");
        Session.setItem("companyName", "");

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idContact: "0",
                idCompany: "0",
                action: "GET_PORTAL_SETTINGS"
            }
        }).then((response) => {
            window.portalSettings = response.data;
            document.title=response.data.portal_name;
            document.documentElement.lang="";
            //PT.resetBasket();
            PT.initBasket();

            this.props.history.replace(Session.getItem("basename") + "/"+window.portalSettings.portal_dealer_home);

        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });

        this.props.history.replace(Session.getItem("basename") + "/");
    }

    render() {
        if (this.state.loaded) {
            return (

                <div className="sidebar-category">
                    <div onClick={()=>this.setState({expand:!this.state.expand})} className="category-title" style={{background:window.portalSettings.portal_pcolor+"0d"}}>
                        <span>{ST.get("your_dealer","global")}</span>
                        <ul className="icons-list">
                            <li><i onClick={()=>this.setState({expand:!this.state.expand})} className={"icon icon-chevron-"+ (this.state.expand?"up":"down") +" cursor-pointer"}/></li>
                        </ul>
                    </div>
                    <ul className="media-list" style={!this.state.expand ? {display:"none"} : {}}>
                        <li className="media">
                            <div className="media-left">
                                {this.state.data.intrauser_has_image?
                                <img src={Session.getItem("endpoint") + "PictureServlet?id=" + this.props.id + "&action=GET_INTRAUSER_LOGO"} className="media-logo" alt="dealer avatar"/>
                                :
                                    <img src={avatar} className="media-logo" alt="dealer avatar"/>
                                }
                            </div>
                            <div className="media-body">
                                <div className="media-heading">
                                    <div className="text-semibold" >
                                        {this.state.data.intrauser_name}
                                    </div>
                                    {this.state.data.intrauser_phone && this.state.data.intrauser_phone!==""?
                                    <div className="text-muted">
                                        <a href={"tel:"+this.state.data.intrauser_phone}>
                                        <i className="icon-phone2 position-left"/>{this.state.data.intrauser_phone}
                                        </a>
                                    </div>:""}
                                    {this.state.data.intrauser_email && this.state.data.intrauser_email!==""?
                                    <div className="text-muted">
                                        <a href={"mailto:"+this.state.data.intrauser_email}>
                                        <i className="icon-mail5 position-left"/>{this.state.data.intrauser_email}
                                        </a>
                                    </div>:""}
                                </div>
                            </div>
                        </li>
                        {Session.getItem("dealerLogin") === "true" && Session.getItem("idContact") !== "" && Session.getItem("idDealer") === this.props.id ? (
                            <li>
                                <Button className={"ml-10"} style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this.logoutCustomer}>{ST.get("logout_from_customer", "portal")}</Button>
                            </li>
                        ) : null}
                    </ul>


                </div>
            );
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
