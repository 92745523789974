import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import {getTagContent} from "./XMLTools";

import {Session} from "../global/PortalTools";

export default class HeadType2 extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    showDelivery(documentDOM, columnPairs) {
        return (
            getTagContent(documentDOM, columnPairs["delivery_name"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_street"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_zip"]) !== '' ||
            getTagContent(documentDOM, columnPairs["delivery_country"]) !== '')
    }

    getCityZip(zip, city) {
        let cityzip = "";
        if (zip !== '') {
            cityzip += zip;
        }

        if (city !== '') {
            if (cityzip !== '') {
                cityzip += " " + city
            } else {
                cityzip = city;
            }
        }

        return cityzip;
    }

    render() {
        let {documentDOM, document, columnPairs} = this.props;
        return (
            <Row>
                <Col>
                    <Row className={" flexCol"}>
                        <Col xs={12} sm={6} className="pb-10 pt-10">
                            <Row>
                                <Col xs={6} sm={9}>
                                    <div
                                        className="head-title">{getTagContent(documentDOM, columnPairs["title_supplier"])}</div>
                                    {getTagContent(documentDOM, columnPairs["supplier_name"]) !== '' ?
                                        <div>{getTagContent(documentDOM, columnPairs["supplier_name"])}</div> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_street"]) !== '' ?
                                        <div>{getTagContent(documentDOM, columnPairs["supplier_street"])}</div> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_city"]) !== '' || getTagContent(documentDOM, columnPairs["supplier_zip"]) !== '' ?
                                        <div>{this.getCityZip(getTagContent(documentDOM, columnPairs["supplier_city"]), getTagContent(documentDOM, columnPairs["supplier_zip"]))}</div> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_country"]) !== '' ?
                                        <div>{getTagContent(documentDOM, columnPairs["supplier_country"])}</div> : ("")}
                                </Col>
                                <Col className="align-right pull-right" xs={6} sm={3}>
                                    <img
                                        src={Session.getItem("endpoint") + "PictureServlet?id=" + Session.idMandant + "&action=GET_mandant_LOGO"}
                                        alt="mandant logo"
                                        className="img-responsive pull-right"/>
                                </Col>
                                <Col xs={12}>
                                    {getTagContent(documentDOM, columnPairs["supplier_phone"]) !== '' ?
                                        <Row>
                                            <Col xs={5}>
                                                {getTagContent(documentDOM, columnPairs["title_phone"])}:
                                            </Col>
                                            <Col xs={7}>
                                                {getTagContent(documentDOM, columnPairs["supplier_phone"])}
                                            </Col>
                                        </Row> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_ico"]) !== '' ?
                                        <Row>
                                            <Col xs={5}>
                                                {getTagContent(documentDOM, columnPairs["title_ico"])}:
                                            </Col>
                                            <Col xs={7}>
                                                {getTagContent(documentDOM, columnPairs["supplier_ico"])}
                                            </Col>
                                        </Row> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_dic"]) !== '' ?
                                        <Row>
                                            <Col xs={5}>
                                                {getTagContent(documentDOM, columnPairs["title_dic"])}:
                                            </Col>
                                            <Col xs={7}>
                                                {getTagContent(documentDOM, columnPairs["supplier_dic"])}
                                            </Col>
                                        </Row> : ("")}
                                    {getTagContent(documentDOM, columnPairs["supplier_icdph"]) !== '' ?
                                        <Row>
                                            <Col xs={5}>
                                                {getTagContent(documentDOM, columnPairs["title_icdph"])}:
                                            </Col>
                                            <Col xs={7}>
                                                {getTagContent(documentDOM, columnPairs["supplier_icdph"])}
                                            </Col>
                                        </Row> : ("")}
                                </Col>
                            </Row>
                        </Col>
                        <Col className="head-border-left-dashed pb-10" xs={12} sm={6}>
                            <Row>
                                {getTagContent(documentDOM, columnPairs["number_document"]) !== '' ?

                                    <Col xs={12}>
                                        <Row>
                                            <Col sm={8} className="head-title pb-10" xsHidden>
                                                {getTagContent(documentDOM, columnPairs["title_document"])}
                                            </Col>
                                            <Col className="head-title align-right pb-10" sm={4} xsHidden>
                                                {getTagContent(documentDOM, columnPairs["number_document"])}
                                            </Col>
                                        </Row>
                                    </Col>
                                    : null}
                                {this.props.document === 'invoice' ? (
                                    <Col xs={12}>
                                        <Row>

                                            <Col sm={12} style={{paddingRight: "0px", paddingLeft: "0px", paddingBottom: "10px"}}>
                                                <div className="head-border-dashed"/>
                                            </Col>
                                            <Col sm={12} className="pb-10">
                                                <Row>
                                                    {getTagContent(documentDOM, columnPairs["variable_symbol"]) !== '' ?
                                                        <Col sm={6}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    {getTagContent(documentDOM, columnPairs["title_variable_symbol"])}:&nbsp;{getTagContent(documentDOM, columnPairs["variable_symbol"])}
                                                                </Col>
                                                            </Row>
                                                        </Col> : ("")}
                                                    {getTagContent(documentDOM, columnPairs["constant_symbol"]) !== '' ?
                                                        <Col className="cs-align-right" sm={6}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    {getTagContent(documentDOM, columnPairs["title_constant_symbol"])}:&nbsp;{getTagContent(documentDOM, columnPairs["constant_symbol"])}
                                                                </Col>
                                                            </Row>
                                                        </Col> : ("")}
                                                </Row>
                                                {getTagContent(documentDOM, columnPairs["registration_number"]) !== '' ?
                                                    <div>
                                                        {getTagContent(documentDOM, columnPairs["title_registration_number"])}:&nbsp;{getTagContent(documentDOM, columnPairs["registration_number"])}
                                                    </div>
                                                    : ("")}
                                                {getTagContent(documentDOM, columnPairs["customer_number"]) !== '' ?
                                                    <div>
                                                        {getTagContent(documentDOM, columnPairs["title_customer_number"])}:&nbsp;{getTagContent(documentDOM, columnPairs["customer_number"])}
                                                    </div>
                                                    : ("")}
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : null}

                                {this.props.document !== 'invoice' && getTagContent(documentDOM, columnPairs["customer_number"]) !== '' ?
                                    <Col xs={12}>
                                        <Row>
                                            <div style={{paddingRight: "0px"}}>
                                                <div className="head-border-dashed"/>
                                            </div>
                                            <Col sm={12} className="pt-10 pb-10">
                                                <div>
                                                    {getTagContent(documentDOM, columnPairs["title_customer_number"])}:&nbsp;{getTagContent(documentDOM, columnPairs["customer_number"])}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : null}

                                <Col xs={12}>
                                    <Row>
                                        <div style={{paddingRight: "0px"}}>
                                            <div className="head-border-dashed"/>
                                        </div>
                                        <Col sm={12} className="pt-10 pb-10">
                                            {getTagContent(documentDOM, columnPairs["title_customer"]) !== '' ? <div
                                                className="head-title">{getTagContent(documentDOM, columnPairs["title_customer"])}</div> : ("")}
                                            {getTagContent(documentDOM, columnPairs["customer_name"]) !== '' ?
                                                <div>{getTagContent(documentDOM, columnPairs["customer_name"])}</div> : ("")}
                                            {getTagContent(documentDOM, columnPairs["customer_street"]) !== '' ?
                                                <div>{getTagContent(documentDOM, columnPairs["customer_street"])}</div> : ("")}
                                            {getTagContent(documentDOM, columnPairs["customer_zip"]) !== '' && getTagContent(documentDOM, columnPairs["customer_city"]) === '' ?
                                                <div>{getTagContent(documentDOM, columnPairs["customer_zip"])}&nbsp;{getTagContent(documentDOM, columnPairs["customer_city"])}</div> : ("")}
                                            {getTagContent(documentDOM, columnPairs["customer_country"]) !== '' ?
                                                <div>{getTagContent(documentDOM, columnPairs["customer_country"])}</div> : ("")}
                                            {getTagContent(documentDOM, columnPairs["customer_ico"]) !== '' ?
                                                <div>{getTagContent(documentDOM, columnPairs["title_ico"])}:&nbsp;{getTagContent(documentDOM, columnPairs["customer_ico"])}</div> : ("")}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"flexCol"}>
                        {document === "quotation" ?
                            <Col sm={6} className="head-border-dashed" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                                {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_date_create_document"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["date_create_document"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["date_valid"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_date_valid"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["date_valid"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["demand_number"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_demand_number"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["demand_number"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_form_of_payment"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["form_of_payment"])}</Col>
                                    </Row> : ""}
                            </Col>
                            : null}
                        {document === "orderin" ?
                            <Col sm={6} className="head-border-dashed" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                                {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_date_create_document"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["date_create_document"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["date_delivery_document"]) !== '' ?
                                    <Row>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["title_date_delivery_document"])}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["date_delivery_document"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["date_maturity_document"]) !== '' ?
                                    <Row>
                                        <Col className="align-right" xs={5}>
                                            {getTagContent(documentDOM, columnPairs["title_date_maturity_document"])}:
                                        </Col>
                                        <Col className="align-right" xs={7}>
                                            {getTagContent(documentDOM, columnPairs["date_maturity_document"])}
                                        </Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_form_of_payment"])}: </Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["form_of_payment"])}</Col>
                                    </Row> : ""}

                                {getTagContent(documentDOM, columnPairs["date_valid"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>{getTagContent(documentDOM, columnPairs["title_date_valid"])}:</Col>
                                        <Col className="align-right" xs={5}>{getTagContent(documentDOM, columnPairs["date_valid"])}</Col>
                                    </Row> : ""}

                                {getTagContent(documentDOM, columnPairs["quotation_number"]) !== '' ?
                                    <Row>
                                        <Col xs={7}>
                                            {getTagContent(documentDOM, columnPairs["title_quotation_number"])}:
                                        </Col>
                                        <Col className="align-right" xs={5}>
                                            {getTagContent(documentDOM, columnPairs["quotation_number"])}
                                        </Col>
                                    </Row> : ""}
                            </Col>
                            : null}
                        {document === "invoice" ?
                            <Col sm={6} className="head-border-dashed" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                                {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, columnPairs["title_date_create_document"])}: </Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["date_create_document"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["date_maturity_document"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, columnPairs["title_date_maturity_document"])}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["date_maturity_document"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, 'invoice_date_vat') !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, 'invoice_date_vat_title')}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, 'invoice_date_vat')}</Col>
                                    </Row> : ""}

                                {getTagContent(documentDOM, columnPairs["orderin_number"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, columnPairs["title_orderin_number"])}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["orderin_number"])}</Col>
                                    </Row> : ""}
                                {getTagContent(documentDOM, columnPairs["quotation_number"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, columnPairs["title_quotation_number"])}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["quotation_number"])}</Col>
                                    </Row> : ""}

                                {document === 'invoice' && getTagContent(documentDOM, columnPairs["form_of_payment"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>
                                            {getTagContent(documentDOM, columnPairs["title_form_of_payment"])}
                                        </Col>
                                        <Col className="align-right" xs={7}>
                                            {getTagContent(documentDOM, columnPairs["form_of_payment"])}
                                        </Col>
                                    </Row> : ("")}
                                {getTagContent(documentDOM, columnPairs["mandant_bank_account"]) !== '' ?
                                    <Row>
                                        <Col
                                            xs={5}>{getTagContent(documentDOM, "account_number")}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["mandant_bank_account"])}</Col>
                                    </Row>
                                    : null}
                                {getTagContent(documentDOM, "mandant_iban") !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, "iban")}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, "mandant_iban")}</Col>
                                    </Row>
                                    : null}
                                {getTagContent(documentDOM, "mandant_swift") !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, "swift")}:</Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, "mandant_swift")}</Col>
                                    </Row>
                                    : null}
                                {getTagContent(documentDOM, "invoice_ss") !== '' ?
                                    <Row>
                                        <Col
                                            xs={5}>{getTagContent(documentDOM, "specificSymbol")}: </Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, "invoice_ss")}</Col>
                                    </Row>
                                    : null}
                            </Col>
                            : null}
                        {document === "warehouse" ?
                            <Col sm={6} className="head-border-dashed" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                                {getTagContent(documentDOM, columnPairs["date_create_document"]) !== '' ?
                                    <Row>
                                        <Col xs={5}>{getTagContent(documentDOM, columnPairs["title_date_create_document"])}: </Col>
                                        <Col className="align-right" xs={7}>{getTagContent(documentDOM, columnPairs["date_create_document"])}</Col>
                                    </Row> : ""}
                            </Col>
                            : null}
                        <Col className="head-border-left-dashed head-border-dashed pt-10 pb-10" sm={6}>
                            {getTagContent(documentDOM, columnPairs["address_delivery"]) !== '' && this.showDelivery(documentDOM, columnPairs) ? <div
                                className="head-title">{getTagContent(documentDOM, columnPairs["address_delivery"])}</div> : ("")}

                            {getTagContent(documentDOM, columnPairs["delivery_name"]) !== '' ?
                                <div>{getTagContent(documentDOM, columnPairs["delivery_name"])}</div> : ("")}

                            {getTagContent(documentDOM, columnPairs["contact_name"]) !== '' ?
                                <div>{getTagContent(documentDOM, columnPairs["contact_name"])}</div> : ("")}

                            {getTagContent(documentDOM, columnPairs["delivery_street"]) !== '' ?
                                <div>{getTagContent(documentDOM, columnPairs["delivery_street"])}</div> : ("")}

                            {getTagContent(documentDOM, columnPairs["delivery_zip"]) !== '' || getTagContent(documentDOM, columnPairs["delivery_city"]) !== '' ?
                                <div>{this.getCityZip(getTagContent(documentDOM, columnPairs["delivery_zip"]), getTagContent(documentDOM, columnPairs["delivery_city"]))}</div> : null}

                            {getTagContent(documentDOM, columnPairs["delivery_country"]) !== '' ?
                                <div>{getTagContent(documentDOM, columnPairs["delivery_country"])}</div> : ("")}
                        </Col>
                    </Row>
                    <Row className="pb-15">
                        <div style={{paddingLeft: "0px", paddingRight: "0px"}}>
                            <div className="head-border-dashed"/>
                        </div>
                    </Row>
                </Col>
            </Row>
        );
    }
}
