import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {ST} from "../../global/SystemText";

import {Session} from "../../global/PortalTools";

export default class ItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage:false,
           openImageIndex:0
        }

    }
    openImage(index){
        this.setState({openImage:true,openImageIndex:index});
    }
    render() {
            let images=this.props.data.images;
            let documents=this.props.data.documents;
            let videos=this.props.data.videos;
            return (
                <Row>
                    <Col md={8}>
                        <h6 className=" text-semibold">{ST.get("pictures_and_videos","global")}</h6>
                        <Row>
                            {videos.length > 0 || images.length > 0 ?
                                <div>
                                    {videos.map((element, index) => (
                                        <Col md={6} key={index}>
                                            <iframe title={"video"+index} width="100%" height="auto" src={element} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                                        </Col>
                                    ))}

                                    {images.map((element, index) => (
                                        <Col md={3} key={index}>
                                            <img onClick={()=>this.openImage(index)} src={Session.getItem("endpoint")+element} className="img-responsive cursor-pointer" alt={"product" + index}/>
                                        </Col>
                                    ))}
                                    {this.state.openImage && (
                                        <Lightbox
                                            mainSrc={Session.getItem("endpoint")+images[this.state.openImageIndex]}
                                            nextSrc={images[(this.state.openImageIndex + 1) % images.length]}
                                            prevSrc={images[(this.state.openImageIndex + images.length - 1) % images.length]}
                                            onCloseRequest={() => this.setState({ openImage: false })}
                                            onMovePrevRequest={() =>
                                                this.setState({
                                                    openImageIndex: (this.state.openImageIndex + images.length - 1) % images.length,
                                                })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    openImageIndex: (this.state.openImageIndex + 1) % images.length,
                                                })
                                            }
                                        /> )}
                                </div>
                            :
                                ST.get("no_images_for_item", "global")
                            }

                        </Row>
                    </Col>
                    <Col md={4}>
                        <h6 className=" text-semibold">{ST.get("documents","global")}</h6>
                        {documents.length > 0 ?
                            <div>
                                {documents.map((element, index) => (
                                    <div key={index}>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href={Session.getItem("endpoint") + element.url}>
                                            <Row className="mt-10">
                                                <Col md={2} className="">
                                                    <i className={"oicon24 oicon-" + element.ext}/>
                                                </Col>
                                                <Col md={10}>
                                                    {element.name}
                                                </Col>
                                            </Row>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            :
                            ST.get("no_document_for_item", "global")
                        }

                    </Col>
                </Row>

            );

    }
}
