import React, {Component} from "react";
import {ST} from "../../global/SystemText";
import Breadcrumbs from "../Breadcrumbs";
import {Button, Col, Row} from "react-bootstrap";
import axios from "axios";
import {Session} from "../../global/PortalTools";
import * as PT from "../../global/PortalTools";
import Map from "../../Map";
import {BubbleLoader} from 'react-css-loaders';
import {Link} from "react-router-dom";
import ContactModal from "./ContactModal";

export default class ContactDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaded: false
        }
    }

    componentDidMount() {
        this.getContact();
    }

    getContact = () => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idDealer: Session.getItem("idDealer"),
                id: this.props.match.params.id,
                action: 'GET_CONTACT_DETAIL'
            }
        })
            .then((response) => {
                //console.log(response.data);
                this.setState({
                    data: response.data,
                    loaded: true
                })

            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    loginCustomer = () => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            idContact: String(this.props.match.params.id),
            idCompany: String(this.state.data.company_id),
            action: "PORTAL_LOGIN_CUSTOMER"
        })
            .then((response) => {
                let {idContact, idCompany, idLanguage, idDealer, idCurrency, codeCurrency, priceLevel, contactName, companyName, contactGender} = response.data;
                //console.log(response.data);
                if (idContact !== "") {
                    Session.setItem("logged", "true");
                    Session.setItem("idContact", idContact);
                    Session.setItem("idCompany", idCompany);
                    // pokial sa toto prepise, tak portal bude v jazyku zakaznika ak kym sa nevyberie zakaznik s inym jazykom
                    // Session.setItem("idLanguage", idLanguage);
                    Session.setItem("idDealer", idDealer);
                    Session.setItem("idCurrency", idCurrency);
                    Session.setItem("codeCurrency", codeCurrency.replace(/eur/ig, "€"));
                    Session.setItem("priceLevel", priceLevel);
                    Session.setItem("contactName", contactName);
                    Session.setItem("contactGender", contactGender);
                    Session.setItem("companyName", companyName);
                    if (window.cordova) {
                        //2 tyzdne
                        Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
                    } else {
                        Session.setItem("logoutTimer", Date.now() + 30 * 60000);
                    }

                    axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                        params: {
                            idMandant: Session.idMandant,
                            idContact: Session.getItem("idContact"),
                            idCompany: Session.getItem("idCompany"),
                            action: "GET_PORTAL_SETTINGS"
                        }
                    }).then((response) => {
                        window.portalSettings = response.data;
                        document.title=response.data.portal_name;
                        document.documentElement.lang="";
                        //PT.resetBasket();
                        PT.initBasket();

                        this.props.history.replace(Session.getItem("basename") + "/");

                    }).catch((error) => {
                        PT.handleError(error, this.props.history);
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    }

    onContactChange = (data) => {
        this.setState({data});
    }

    render(){
        let basename = Session.getItem("basename");
        if(this.state.loaded) {
            let addresses = [];
            if(!this.state.data.same_address){
                addresses = [{city: this.state.data.city2, street: this.state.data.street2, zip: this.state.data.zip2, lat: this.state.data.lat2, lng: this.state.data.lng2}];
            }
            return (
                <div>
                    <div className="panel border-top-xlg" style={{borderColor: this.props.settings.portal_pcolor}}>
                        <Breadcrumbs type={"contact"} active={this.state.data.name}/>
                        <div className="panel-heading">
                            <h1 className="panel-title" dangerouslySetInnerHTML={{__html:this.state.data.name}}/>
                        </div>

                        <div className="panel-body">
                            <Row className="pt-15">
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <ContactModal history={this.props.history} person={this.state.data} callback={this.onContactChange} title={ST.get("edit", "global")}>
                                                <Button className={"pull-right"}>{ST.get("edit", "global")}</Button>
                                            </ContactModal>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                            <h4 className="no-margin-top"><u>{ST.get("address","global")}:</u></h4>
                                            <p className="text-size-large">
                                                {this.state.data.street}<br/>
                                                {this.state.data.zip} {this.state.data.city}<br/>
                                                {this.state.data.country_name}
                                            </p>
                                        </Col>
                                        {this.state.data.same_address ? null :
                                            (<Col md={5}>
                                                <h4 className="no-margin-top"><u>{ST.get("delivery_address", "person")}:</u></h4>
                                                <p className="text-size-large">
                                                    {this.state.data.street2}<br/>
                                                    {this.state.data.zip2} {this.state.data.city2}<br/>
                                                    {this.state.data.country_name2}
                                                </p>
                                            </Col>)
                                        }
                                    </Row>
                                    <Row className={"mt-10"}>
                                        <Col md={4}><strong>{ST.get("mobile","person")}:</strong></Col>
                                        <Col md={8}><a href={"tel://" + this.state.data.mobile.replace(/ /gi, "")}>{this.state.data.mobile}</a></Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("email","person")}:</strong></Col>
                                        <Col md={8}><a href={"mailto://" + this.state.data.email}>{this.state.data.email}</a></Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}><strong>{ST.get("company_one","company")}:</strong></Col>
                                        <Col md={8}><Link to={basename + "/company/" + this.state.data.company_id}><span style={{color:"#1E88E5"}} dangerouslySetInnerHTML={{__html:this.state.data['company_name']}}/></Link></Col>
                                    </Row>
                                    <Row>
                                        <Col className={"mt-20"} md={12}><Button className={"btn-primary mb-20"}  style={{background: window.portalSettings.portal_pcolor, color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"}} onClick={this.loginCustomer}>{ST.get("login_as_customer", "portal")}</Button></Col>
                                    </Row>
                                </Col>

                                <Col md={8}>
                                    {this.state.data.city === "" || this.state.data.city === undefined || this.state.data.city === null
                                        ? null
                                        : (<Map address={{city: this.state.data.city, street: this.state.data.street, zip: this.state.data.zip, lat: this.state.data.lat, lng: this.state.data.lng}}
                                                addresses={addresses}/>)}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            );
        }else{
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>)
        }
    }
}