import React, {Component} from 'react';
import axios from "axios";
import {Button, Col, Row} from "react-bootstrap";
import {BubbleLoader} from "react-css-loaders";
import ProductTile from "../components/ProductTile";
import ProductRow from "../components/ProductRow";
import {Link} from "react-router-dom";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import Breadcrumbs from "./Breadcrumbs";
import {ST} from "../global/SystemText";
import DmsTile from "./dms/DmsTile";

export default class Catalogue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            loaded: false,
            products: [],
            data: {},
            subs: [],
            documents: [],
            documentsLoaded: false,
            limit: "",
            sort: "",
            page: 1,
            paginationLimit: 5,
            gridType: ""
        };

    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newState.id === newProps.match.params.id) {
            return null;
        } else {
            newState.id = newProps.match.params.id;
            Session.setItem("activeCatalogue", newProps.match.params.id);
            newState.loaded = false;
            newState.documentsLoaded = false;
            newState.page = 1;
            Session.setItem("activeCataloguePage", 1);
            return newState;
        }
    }

    componentDidMount() {
        let defaultSort = "catalogue_order";
        let defaultPage = 1;
        let defaultLimit = 24;
        let defaultGridType = "grid";
        //console.log("GridType: "+Session.getItem("gridType"));
        if (Session.getItem("gridType") != undefined || Session.getItem("gridType") != null) {

            defaultGridType = Session.getItem("gridType")
        }
        if(Session.getItem("activeCatalogue") !== undefined && Session.getItem("activeCataloguePage") !== undefined && Number(Session.getItem("activeCatalogue")) === Number(this.state.id)){
            defaultPage = Number(Session.getItem("activeCataloguePage"));
        } else {
            //console.log(Session.getItem("activeCatalogue"), Session.getItem("activeCataloguePage"));
        }
        //console.log(defaultGridType);
        this.setState({
            limit: defaultLimit,
            sort: defaultSort,
            page: defaultPage,
            gridType: defaultGridType
        });


    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (!this.state.loaded) {
            this.getCatalogue();

        }
        /*
        if (!this.state.documentsLoaded) {
            this.getDocuments();
        }

         */
    }

    getCatalogue() {

        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idLanguage: Session.getItem("idLanguage"),
                idCurrency: Session.getItem("idCurrency"),
                idCatalogue: this.state.id,
                priceLevel: Session.getItem("priceLevel"),
                limit: this.state.limit,
                sort: this.state.sort,
                page: this.state.page,
                action: "GET_PORTAL_CATALOGUE"
            }
        })
            .then((response) => {
                let result = response.data;
                //console.log(result);
                let newState = {
                    products: result.products,
                    subs: result.subs,
                    data: result.data,
                    countProducts: result.countProducts,
                    loaded: true
                };
                if (Session.getItem("gridType") != undefined || Session.getItem("gridType") != null) {
                    newState['gridType'] = Session.getItem("gridType");
                }
                Session.setItem("activeCatalogue", this.state.id);

                this.getDocuments(); //TODO premysliet aby sa nemuseli citat stale dokola

                this.setState(newState);
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    getDocuments = () => {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idLanguage: Session.getItem("idLanguage"),
                id: this.state.id,
                action: "GET_CATALOGUE_DOCUMENTS"
            }
        })
            .then((response) => {
                let result = response.data;
                let newState = {
                    documents: result.items,
                    documentsLoaded: true
                };
                if (this.state.loaded && this.state.products.length === 0 && result.items.length > 0) {
                    newState['gridType'] = 'download';
                }
                this.setState(newState)
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    };

    changeLimit(event) {
        this.setState({limit: event.target.value, loaded: false})
    }

    changeSort(event) {
        this.setState({sort: event.target.value, loaded: false})
    }

    getCatalogueGrid = () => {
        let {gridType, products} = this.state;

        switch (gridType) {
            case 'grid':
                return (
                    <Row>
                        {products.map((element, index) => {
                            return (<ProductTile data={element} key={"productTile" + index}/>);
                        })}
                    </Row>
                );

            default:
            case 'list':
                return (
                    <div className="table-responsive" style={{display:this.state.countProducts>0?"":"none"}}>
                        <table className="table table-lg">
                            <thead>
                            <tr>
                                <th className="text-left">{ST.get("product_name", "product")}</th>
                                <th className="text-left">{ST.get("product_order_our", "product")}</th>
                                <th className="text-left">{ST.get("catalogue", "global")}</th>
                                <th className="text-left">{ST.get("product_availability", "product")}</th>
                                {Boolean(window.portalSettings.show_prices) ?
                                    Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                        :
                                        <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                    : null }
                                {Boolean(window.portalSettings.show_prices) ?
                                    Boolean(window.portalSettings.main_price_excl_vat) ?
                                        <th className="text-right">{ST.get("price_incl_vat", "global")}</th>
                                        :
                                        <th className="text-right">{ST.get("price_excl_vat", "global")}</th>
                                    : null }
                                <th className="text-right">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {products.map((element, index) => {
                                return (<ProductRow data={element} key={"productTile" + index}/>);
                            })}

                            </tbody>
                        </table>
                    </div>
                );

            case 'download':
                return (
                    <Row>
                        {this.state.documents.map((element, index) => {
                            return (<DmsTile data={element} key={"dmsTile" + index}/>);
                        })}
                    </Row>
                );

        }

    };

    render() {

        let pages = Math.ceil(this.state.countProducts / this.state.limit);

        let paginations = [];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }
        if (this.state.loaded && this.state.documentsLoaded) {
            let subs = this.state.subs;
            return (
                <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                    <Breadcrumbs type={"catalogue"} id={this.state.data.langParent} active={this.state.data.name}/>
                    <div className="panel-heading no-padding-bottom">
                        <h3 className="panel-title">{this.state.data.name}</h3>
                    </div>
                    <div className="panel-body">
                        <div className="sub-catalogues">
                            <div className="cat-description" dangerouslySetInnerHTML={{__html: this.state.data.description}}>
                            </div>
                            <Row>
                                {subs.map((element, index) => {
                                    return (
                                        <Col key={index} md={4} lg={4}>
                                            <Link title={element.name} to={Session.getItem("basename") + "/catalogue/" + element.id}>
                                                <div className="panel catalogue">
                                                    <div className="panel-body p-15">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <div className="thumb thumb-fixed cat-thumb">
                                                                    <img src={Session.getItem("endpoint") + element.picture} alt={element.name}/>
                                                                </div>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <div className="cat-title">
                                                                    {element.name}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="clearfix"/>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>)
                                })}
                            </Row>
                        </div>
                        <div className="product-toolbox" style={{display:this.state.countProducts>0?"":"none"}}>
                            <div className="row">
                                <div className="col-lg-4 ">
                                    <div className="btn-group">
                                        <Button onClick={() => {
                                            this.setState({gridType: "list"});
                                            Session.setItem("gridType", "list")
                                        }} size={"sm"} variant={"light"} style={this.state.gridType === "list" ? {
                                            background: window.portalSettings.portal_pcolor,
                                            color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                        } : {}}><i className="icon icon-list"/></Button>
                                        <Button onClick={() => {
                                            this.setState({gridType: "grid"});
                                            Session.setItem("gridType", "grid")
                                        }} size={"sm"} variant={"light"} style={this.state.gridType === "grid" ? {
                                            background: window.portalSettings.portal_pcolor,
                                            color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                        } : {}}><i className="icon icon-grid"/></Button>
                                        {this.state.documents.length > 0 || false ? (
                                            <Button onClick={() => {
                                                this.setState({gridType: "download"});
                                            }} size={"sm"} variant={"light"} style={this.state.gridType === "download" ? {
                                                background: window.portalSettings.portal_pcolor,
                                                color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                                            } : {}}>{ST.get("documents", "global")}&nbsp;{ST.get("for_download", "global")}</Button>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-8 text-right">
                                    <div className="input-group inline">
                                        <label className="pt-15">{ST.get("show", "global")}:</label>
                                        <select value={Number(this.state.limit)} className="form-control" onChange={this.changeLimit.bind(this)}>
                                            <option value={24}>24</option>
                                            <option value={48}>48</option>
                                        </select>
                                    </div>
                                    <div className="input-group inline">
                                        <label className="pt-15">{ST.get("order_by", "global")}:</label>
                                        <select className="form-control" value={this.state.sort} onChange={this.changeSort.bind(this)}>
                                            <option value={"catalogue_order"}>{ST.get("lib_quotation_text_default", "global")}</option>
                                            <option value={"product_name ASC"}>A-Z</option>
                                            <option value={"product_name DESC"}>Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.getCatalogueGrid()}
                        {this.state.gridType !== 'download' ? (
                            <div className="text-center content-group-lg pt-20" style={{display:this.state.countProducts>0?"":"none"}}>
                                <ul className="pagination">
                                    <li className={this.state.page === 1 ? "disabled" : ""}><Button disabled={this.state.page === 1} onClick={() => {
                                        Session.setItem("activeCataloguePage", (this.state.page + 1));
                                        this.setState({page: (this.state.page - 1), loaded: false})
                                    }} size={"sm"} variant={"light"}><i className="icon-arrow-small-left"/></Button></li>
                                    {paginations.map((element, index) => {
                                        return (element);
                                    })}
                                    <li className={this.state.page === pages ? "disabled" : ""}>
                                        <Button disabled={this.state.page === pages} onClick={() => {
                                            Session.setItem("activeCataloguePage", (this.state.page + 1));
                                            this.setState({page: (this.state.page + 1), loaded: false})
                                        }} size={"sm"} variant={"light"}><i className="icon-arrow-small-right"/></Button></li>
                                </ul>
                            </div>
                        ) : null}
                    </div>
                </div>
            )

        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}